import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import View from 'spider/store/View'
import ProductionLineEdit from '../screen/ProductionLine/Edit'
import Load from './Loadable'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'

const UserOverview = Load(() => import('../screen/User/Overview'))
const UserEdit = Load(() => import('../screen/User/Edit'))
const GlobalValueOverview = Load(() => import('../screen/GlobalValue/Overview'))
const GlobalValueEdit = Load(() => import('../screen/GlobalValue/Edit'))
const EmailLogOverview = Load(() => import('../screen/User/EmailLog'))
const ProjectsOverview = Load(() => import('../screen/Projects/Overview'))
const AccessLogEntryOverview = Load(() => import('../screen/AccessLogEntry/Overview'));

const OperatorOverview = Load(() => import('../screen/Operator/Overview'))
const OperatorEdit = Load(() => import('../screen/Operator/Edit'))
const OperatorHome = Load(() => import('../screen/Operator/Home'))
const OperatorAvailability = Load(() => import('../screen/Operator/Availability'))

const LeaveCalendarOverview = Load(() => import('../screen/LeaveCalendar/Overview'))
const LeaveCalendarEdit = Load(() => import('../screen/LeaveCalendar/Edit'))

const LeaveSlotOverview = Load(() => import('../screen/LeaveSlot/Overview'))

const ArticleTypeOverview = Load(() => import('../screen/ArticleType/Overview'))
const ArticleTypeEdit = Load(() => import('../screen/ArticleType/Edit'))

const BatchTypeOverview = Load(() => import('../screen/BatchType/Overview'))
const BatchTypeEdit = Load(() => import('../screen/BatchType/Edit'))

const TransferOverview = Load(() => import('../screen/BatchType/TransferOverview'))
const TransferEdit = Load(() => import('../screen/BatchType/TransferEdit'))

const DataSourceOverview = Load(() => import('../screen/DataSource/Overview'))
const DataSourceEdit = Load(() => import('../screen/DataSource/Edit'))

const LoadCarrierOverview = Load(() => import('../screen/LoadCarrier/Overview'))
const LoadCarrierEdit = Load(() => import('../screen/LoadCarrier/Edit'))

const ProductionLineOverview = Load(() => import('../screen/ProductionLine/Overview'))

const CapabilityOverview = Load(() => import('../screen/Capability/Overview'))
const CapabilityEdit = Load(() => import('../screen/Capability/Edit'))

const ProductionRequestOverview = Load(() => import('../screen/ProductionRequest/Overview'))
const ProductionRequestEdit = Load(() => import('../screen/ProductionRequest/Edit'))

const BatchOverview = Load(() => import('../screen/Batch/Overview'))
const ProgressOverview = Load(() => import('../screen/Progress/Overview'))

const ProductionRequestProductionOverview = Load(() => import('../screen/ProductionRequest/ProductionOverview'))
const ProductionRequestProductionPerform = Load(() => import('../screen/ProductionRequest/ProductionPerform'))

const ExactOk = Load(() => import('../screen/Exact/Ok'))
const ExactError = Load(() => import('../screen/Exact/Error'))

const Unit4Ok = Load(() => import('../screen/Unit4/Ok'))
const Unit4Error = Load(() => import('../screen/Unit4/Error'))

const LoginForgot = Load(() => import('../screen/LoginForgot'))
const Activate = Load(() => import('../screen/Activate'))
const ResetPassword = Load(() => import('../screen/ResetPassword'))
const Profile = Load(() => import('../screen/Profile'))
const Changelog = Load(() => import('../screen/Changelog'))

const NotFound = Load(() => import('../container/NotFound'))

const PrinterSetup = Load(() => import('../screen/PrinterSetup'))

const StockOverview = Load(() => import('screen/Logistics/Stock'))
const InboundOverview = Load(() => import('screen/Logistics/Inbound'))
const OutboundOverview = Load(() => import('screen/Logistics/Outbound'))
const WarehouseTransferOverview = Load(() => import('screen/Logistics/WarehouseTransfer/Overview'))

const IntegrationOverview = Load(() => import('screen/Integration/Overview'))
const SyncErrorOverview = Load(() => import('screen/Integration/SyncError/Overview'))

const PlantationLineOverview = Load(() => import('screen/PlantationLine/Overview'))
const PlantationLineEdit = Load(() => import('screen/PlantationLine/Edit'))
const PlantationLineDetails = Load(() => import('screen/PlantationLine/Details'))
const PlantationLineFertilizer = Load(() => import('screen/PlantationLine/Fertilizer'))

const ExperimentOverview = Load(() => import('screen/Experiment/Overview'))

const UnitOverview = Load(() => import('screen/Unit/Overview'))
const UnitEdit = Load(() => import('screen/Unit/Edit'))

const SyncRequestOverview = Load(() => import('screen/SyncRequest/Overview'))
const PlcOverview = Load(() => import('screen/Plc/Overview'))
const PlcEdit = Load(() => import('screen/Plc/Edit'))
const PlcControl = Load(() => import('screen/Plc/Control'))
const Flier = Load(() => import('screen/Plc/Flier'))

const ScriptLibraryOverview =  Load(() => import('screen/Library/Chapters/ChapterLibraryOverview.js'))
const ChapterClimateOverview = Load(() => import('screen/Library/Chapters/Climate/Overview'))
const ChapterLightOverview = Load(() => import('screen/Library/Chapters/Light/Overview'))
const ChapterFertilizerOverview = Load(() => import('screen/Library/Chapters/Fertilizer/Overview'))
const ChapterIrrigationOverview = Load(() => import('screen/Library/Chapters/Irrigation/Overview'))
const ChapterNutritionStorageOverview = Load(() => import('screen/Library/Chapters/NutritionStorage/Overview'))
const ChapterEdit = Load(() => import('screen/Library/Chapters/Edit'))
const ChapterView = Load(() => import('screen/Library/Chapters/View')) //Edit screen but every input field disabled

const ScriptRndOverview = Load(() => import('screen/Library/Script/rnd/Overview'))
const ScriptApprovedByTryoutOverview = Load(() => import('screen/Library/Script/ApprovedByTryout/Overview'))
const ScriptTryoutOverview = Load(() => import('screen/Library/Script/tryout/Overview'))
const ScriptDisapprovedByTryoutOverview = Load(() => import('screen/Library/Script/DisapprovedByTryout/Overview'))
const ScriptProductionOverview = Load(() => import('screen/Library/Script/production/Overview'))
const ScriptPackageEdit = Load(() => import('screen/Library/Script/Edit'))

const ClimateOverview = Load(() => import('screen/Library/Recipe/Climate/Overview'))
const ClimateEdit =  Load(() => import('screen/Library/Recipe/Climate/Edit'))
const FertilizerOverview = Load(() => import('screen/Library/Recipe/Fertilizer/Overview'))

const Scenario = Load(() => import('../screen/Scenario'))
const WorkStationSessionOverview = Load(() => import('../screen/WorkStationSession/Overview'))

const FactoryOverview = Load(() => import('../screen/Factory/Overview'))

const WorkStationPlanning = Load(() => import('../screen/WorkStation/Planning'))
const WorkStationDay = Load(() => import('../screen/WorkStation/Day'))

const SalesOrderOverview = Load(() => import('../screen/SalesOrder/Overview'))
const SalesOrderEdit = Load(() => import('../screen/SalesOrder/Edit'))

const NavisionSyncRequestOverview = Load(() => import('../screen/NavisionSyncRequest/Overview'))

const MetafieldOverview = Load(() => import('../screen/Metafield/Overview'))
const MovementOverview = Load(() => import('../screen/Movement/Overview'))

const IssueOverview = Load(() => import('../screen/Issue/Overview'))
const Simulation = Load(() => import('../screen/Issue/Simulation'))
const SimulationStats = Load(() => import('../screen/Issue/SimulationStats'))
const Live = Load(() => import('../screen/Issue/Live'))

const DashboardOverview = Load(() => import('../screen/Dashboard/Overview'))

const NotificationsOverview = Load(() => import('../screen/Notifications/Overview'))
const NotificationContentTemplateOverview = Load(() => import('../screen/Notifications/ContentTemplateOverview'))

const LMSMapOverview = Load(() => import('../screen/LMS/MapOverview'))
const LMSScenarioEdit = Load(() => import('../screen/LMS/ScenarioToolEdit'))
const LMSSensorOverview = Load(() => import('../screen/LMS/SensorOverview'))
const LMSAlarmOverview = Load(() => import('../screen/LMS/AlarmOverview'))
const LMSBuildingAlarmOverview = Load(() => import('../screen/LMS/RegerAlarmOverview.js'))

const SupplyChainManager = Load(() => import('../screen/SupplyChain/Overview'))

const Multilayer = Load(() => import('../screen/PlantationLine/Multilayer'))

const EquipmentOverview = Load(() => import('../screen/Equipment/Overview'))
const EquipmentTypeOverview = Load(() => import('../screen/EquipmentType/Overview'))

export default class Router extends Component {
  static propTypes = {
    store: PropTypes.instanceOf(View).isRequired,
  }

  // react-router is a bit too verbose so I made a shorthand
  route = (Screen) => {
    return (rProps) => <Screen {...rProps} viewStore={this.props.store} />
  }

  redirectFromHome = () => {
    const { store } = this.props
    const { currentUser } = store
    let uri = (
      currentUser.isSuperuser
      ? '/assets/user/overview'
      : '/lms/sensors/overview'
    )

    return <Redirect to={uri} />
  }

  render() {
    const { store } = this.props

    return (
      <Switch>
        <Route path="/" exact render={this.redirectFromHome} />

        <Route path="/operator/availability" render={this.route(OperatorAvailability)} />
        <Route path="/operator" render={this.route(OperatorHome)} />

        <Route path="/assets/user/overview" render={this.route(UserOverview)} />
        <Route path="/assets/user/:id/edit/:tab?" render={this.route(UserEdit)} />
        <Route path="/assets/user/add/:tab?" render={this.route(UserEdit)} />
        <Route path="/assets/user/email-log/overview" render={this.route(EmailLogOverview)} />

        <Route path="/personnel/operator/overview" render={this.route(OperatorOverview)} />
        <Route path="/personnel/operator/:id/edit/:tab?" render={this.route(OperatorEdit)} />
        <Route path="/personnel/operator/add/:tab?" render={this.route(OperatorEdit)} />

        <Route path="/personnel/leave-calendar/overview" render={this.route(LeaveCalendarOverview)} />
        <Route path="/personnel/leave-calendar/:id/edit" render={this.route(LeaveCalendarEdit)} />
        <Route path="/personnel/leave-calendar/add" render={this.route(LeaveCalendarEdit)} />

        <Route path="/personnel/leave-slot/overview" render={this.route(LeaveSlotOverview)} />
        <Route path="/personnel/leave-slot/:id/view" render={this.route(LeaveSlotOverview)} />

        <Route path="/personnel/capability/overview" render={this.route(CapabilityOverview)} />
        <Route path="/personnel/capability/:id/edit" render={this.route(CapabilityEdit)} />
        <Route path="/personnel/capability/add" render={this.route(CapabilityEdit)} />

        <Route path="/assets/article-type/overview" render={this.route(ArticleTypeOverview)} />
        <Route path="/assets/article-type/:id/edit/:view?" render={this.route(ArticleTypeEdit)} />
        <Route
          path="/assets/article-type/:id/batch-type/:batchTypeId/edit/:view?"
          render={this.route(ArticleTypeEdit)}
        />
        <Route
          path="/assets/article-type/:id/batch-type/:batchTypeId/version/:version/edit/:view?"
          render={this.route(ArticleTypeEdit)}
        />
        <Route path="/assets/article-type/add/:view?" render={this.route(ArticleTypeEdit)} />

        <Route path="/assets/process/overview" render={this.route(BatchTypeOverview)} />
        <Route path="/assets/process/add/:view?" render={this.route(BatchTypeEdit)} />
        <Route path="/assets/process/:id/edit/:view?" render={this.route(BatchTypeEdit)} />
        <Route path="/assets/process/:id/version/:version/edit/:view?" render={this.route(BatchTypeEdit)} />

        <Route path="/assets/transfer-process/overview" render={this.route(TransferOverview)} />
        <Route path="/assets/transfer-process/add/:view?" render={this.route(TransferEdit)} />
        <Route path="/assets/transfer-process/:id/edit/:view?" render={this.route(TransferEdit)} />
        <Route path="/assets/transfer-process/:id/version/:version/edit/:view?" render={this.route(TransferEdit)} />

        <Route path="/assets/data-source/overview" render={this.route(DataSourceOverview)} />
        <Route path="/assets/data-source/:id/edit" render={this.route(DataSourceEdit)} />

        <Route path="/assets/production-line/overview" render={this.route(ProductionLineOverview)} />
        <Route
          path="/assets/production-line/:id/version/:version/edit/:view?"
          render={this.route(ProductionLineEdit)}
        />
        <Route path="/assets/production-line/:id/edit/:view?" render={this.route(ProductionLineEdit)} />
        <Route path="/assets/production-line/add/:view?" render={this.route(ProductionLineEdit)} />

        <Route path="/assets/load-carrier/overview" render={this.route(LoadCarrierOverview)} />
        <Route path="/assets/load-carrier/:id/edit" render={this.route(LoadCarrierEdit)} />
        <Route path="/assets/load-carrier/add" render={this.route(LoadCarrierEdit)} />

        <Route path="/planning/production-request/overview" render={this.route(ProductionRequestOverview)} />
        <Route path="/planning/production-request/add" render={this.route(ProductionRequestEdit)} />
        <Route path="/planning/production-request/:id/edit" render={this.route(ProductionRequestEdit)} />

        <Route path="/operations/batch/overview" render={this.route(BatchOverview)} />
        <Route path="/operations/production-request/overview" render={this.route(ProgressOverview)} />
        <Route
          path="/operations/production-request/:id/perform"
          render={this.route(ProductionRequestProductionPerform)}
        />
        <Route path="/operations/work-station-session/overview" render={this.route(WorkStationSessionOverview)} />

        <Route path="/warehouse/stock/articletypewarehouse/:articletypewarehouse" render={this.route(StockOverview)} />
        <Route path="/warehouse/stock" render={this.route(StockOverview)} />

        <Route path="/warehouse/inbound/order/:order/shipment/:shipment" render={this.route(InboundOverview)} />
        <Route path="/warehouse/inbound/order/:order" render={this.route(InboundOverview)} />
        <Route path="/warehouse/inbound" render={this.route(InboundOverview)} />

        <Route path="/warehouse/outbound/order/:order/shipment/:shipment" render={this.route(OutboundOverview)} />
        <Route path="/warehouse/outbound/order/:order" render={this.route(OutboundOverview)} />
        <Route path="/warehouse/outbound" render={this.route(OutboundOverview)} />
        <Route path="/warehouse/warehouse-transfer" render={this.route(WarehouseTransferOverview)} />

        <Route path="/exact/ok" render={this.route(ExactOk)} />
        <Route path="/exact/error/:error" render={this.route(ExactError)} />

        <Route path="/unit4/ok" render={this.route(Unit4Ok)} />
        <Route path="/unit4/error/:error" render={this.route(Unit4Error)} />

        <Route path="/profile/:tab?" render={this.route(Profile)} />
        <Route path="/assets/global-value/overview" render={this.route(GlobalValueOverview)} />
        <Route path="/assets/global-value/:id/edit" render={this.route(GlobalValueEdit)} />

        <Route path="/assets/projects/overview" render={this.route(ProjectsOverview)} />

        <Route path="/changelog" render={this.route(Changelog)} />
        <Route path="/login/forgot" render={this.route(LoginForgot)} />
        <Route path="/user/:id/activate/:token" render={this.route(Activate)} />
        <Route path="/user/:id/reset-password/:token" render={this.route(ResetPassword)} />
        <Route path="/printer-setup" render={this.route(PrinterSetup)} />

        <Route path="/assets/factory/overview" render={this.route(FactoryOverview)} />

        <Route path="/assets/integration/overview" render={this.route(IntegrationOverview)} />
        <Route path="/assets/integration/exact/ok" render={this.route(IntegrationOverview)} />
        <Route path="/assets/integration/exact/error/:error" render={this.route(IntegrationOverview)} />
        <Route path="/assets/integration/unit4/ok" render={this.route(IntegrationOverview)} />
        <Route path="/assets/integration/unit4/error/:error" render={this.route(IntegrationOverview)} />
        <Route path="/assets/integration/:integration/sync-error/overview" render={this.route(SyncErrorOverview)} />

        <Route path="/assets/sync-request/overview" render={this.route(SyncRequestOverview)} />
        <Route path="/demand/sales-order/overview" render={this.route(SalesOrderOverview)} />
        <Route path="/demand/sales-order/:id/edit" render={this.route(SalesOrderEdit)} />
        <Route path="/navisionsyncoverview" render={this.route(NavisionSyncRequestOverview)} />

        <Route path="/assets/access-log/overview" render={this.route(AccessLogEntryOverview)}/>

        <Route path="/assets/metafield/overview" render={this.route(MetafieldOverview)} />


        {isFeatureFlagEnabled('blue_skies') && (
          <>
            <Route path="/plantation/graphs/:type/:subType" render={this.route(DashboardOverview)} />

            <Route path="/lms/sensors/overview" render={this.route(LMSSensorOverview)} />
            <Route path="/lms/scenarios/overview" render={this.route(LMSScenarioEdit)} />
            <Route path="/lms/scenarios/add" render={this.route(LMSScenarioEdit)} />
            <Route path="/lms/scenarios/:id/edit" render={this.route(LMSScenarioEdit)} />
            <Route path="/lms/alarms/overview" render={this.route(LMSAlarmOverview)} />
            <Route path="/lms/building_alarms/overview" render={this.route(LMSBuildingAlarmOverview)} />
            <Route path="/lms/notifications/overview" render={this.route(NotificationsOverview)} />
            <Route path="/lms/notifications/templates" render={this.route(NotificationContentTemplateOverview)} />
            <Route path="/lms/equipment/overview" render={this.route(EquipmentOverview)} />
            <Route path="/lms/equipment-type/overview" render={this.route(EquipmentTypeOverview)} />
          </>
        )}

        <Route render={this.route(NotFound)} />
      </Switch>
    )
  }
}
