export default {
  pageNotFoundMessage: 'Website niet gevonden, is de URL juist?',
  brandingMessage: 'by Code Yellow',
  flowmastersBranding: 'Ondersteund door FlowMasters',
  nav: {
    main: {
      operations: 'Activiteiten',
      production: 'Productie',
      warehouse: 'Magazijn',
      planning: 'Planning',
      personnel: 'Personeel',
      assets: 'Stamgegevens',
      plantation: 'Plantage',
      lms: 'LMS',
      demand: 'Vraag',
      handling: 'Logistiek',
    },
    operations: {
      manager: 'Viewport',
      progress: 'Werk Voortgang',
      batches: 'Batches',
      workStationSessions: 'Werkplek Sessies',
      issues: 'Problemen',
      simulation: 'Simulatie',
    },
    planning: {
      productionLines: 'Productie Omgeving',
      productionRequests: 'Werkdruk',
      workStations: 'Dagplanning',
      day: 'Dagoverzicht',
      scenario: 'Scenario',
    },
    audit: {
            accessLog: 'Toegangslog',
    },
    warehouse: {
      stock: 'Voorraad',
      inbound: 'Ontvangstorders',
      outbound: 'Verzamelorders',
      purchaseOrder: 'Inkooporders',
      warehouseTransfer: 'Voorraadverplaatsingen',
    },
    personnel: {
      users: 'Gebruikers',
      operators: 'Operators',
      leaveCalendars: 'Verlofkalenders',
      leaveSlots: 'Verlofaanvragen',
    },
    assets: {
      processes: 'Processen',
      articleTypes: 'Artikeltypes',
      exactItems: 'Exact Artikelen',
      productionLines: 'Productielijnen',
      capabilities: 'Vaardigheden',
      processPhases: 'Proces Fases',
      dataSources: 'Databronnen',
      globalValues: 'Instellingen',
      globalFiles: 'Bijzondere Bestanden',
      loadCarriers: 'Ladingdragers',
      templates: 'Templates',
      integrations: 'Integraties',
      projects: 'Projecten',
      factories: 'Locaties',
      syncrequest: 'Synchronisatie Verzoeken',
      metafields: 'Metavelden',
    },
    plantation: {
      scripts: 'Scripts',
      plantationLines: 'Plantageomgevingen',
      fertilizer: 'Meststoffen',
      units: 'Units',
      plc: 'Machines',
      movements: 'Bewegingen',
      overview: 'Overzicht',
      graphs: 'Grafieken',
      experiments: 'Experimenten',
    },
    lms: {
      map: 'Map',
      scenarios: 'Scenarios',
      sensors: 'Sensoren',
      status: 'Status',
      buildingAlarms: 'Gebouw Alarmen',
      alarms: 'Alarmen',
      notifications: 'Notificaties',
      templates: 'Notificatie templates',
    },
    account: {
      clockInTime: 'Ingeklokt om {{clockInTime}}',
      notClockedIn: 'Niet ingeklokt',
      clockIn: 'Inklokken',
      clockOut: 'Uitklokken',
      profile: 'Profiel',
      logout: 'Uitloggen',
      changelog: 'Changelog',
      clockOutModal: {
        title: 'Je bent nog ingeklokt.',
        message: 'Wil je naast uitloggen ook uitklokken?',
      },
    },
    sales: {
      salesOrders: 'Verkooporders en Projecten',
    },
  },
  unit4requestoverview: {
    field: {
      id: {label: 'ID'},
      createdAt: {label: 'Aangemaakt Op'},
      message: {label: 'Inhoud'}

    }
  },
  syncrequestoverview: {
    field: {
      id: {label: 'ID'},
      createdAt: {label: 'Aangemaakt Op'},
      message: {label: 'Inhoud'}

    }
  },
  integrations: {
    field: {
      syncRequest: {label: 'Synchronisatie Verzoeken'},
      isOpen: {label: 'Alleen openstaande taken tonen?'},
      unit4: {
        message: {value: 'Verhoog voorraad voor product: {{productID}} met aantal: {{quantity}}'}
      },
      navision: {
        message: {value: 'Purchase order with id {{orderID}} can be marked as completed'}
      }
    },
    overview: {
      empty: 'Er zijn geen synchronisatie verzoeken die voldoen aan de criteria.'
    }

  },
  unit: {
    overview: {
      title: 'Eenheden'
    },
    field: {
      name: {
        label: 'Naam'
      }
    }
  },
  plc: {
    field: {
      name: {label: 'Naam'},
      type: {label: 'Type'},
      ipAddress: {label: 'IP Address'},
      heartbeat: {label: 'Verbonden'}
    },
    overview: {
      title: 'Machine overzicht',
      addButton: 'Machine'
    }
  },
  value: {
    field: {
      name: {label: 'Naam'},
      register: {label: 'Register'},
      multiplicationFactor: {label: 'Verminigvuldigings factor'}
    }
  },
  profile: {
    title: 'Profiel',
    changePassword: {
      label: 'Wachtwoord Veranderen',
      passwordOld: 'Huidig Wachtwoord',
      passwordNew: 'Nieuw Wachtwoord',
    },
  },
  accessLogEntry: {
    overview: {
      title: 'Eventlog',
      json: {
        entries: '… {{count}} veld',
        entries_plural: '… {{count}} velden',
      },
      message: {
        showMore: 'Toon meer',
        showLess: 'Toon minder',
      },
      masqueradingAsUser: 'Voordoend als {{user}}',
      dateRange: {
        label: {
          today: 'Vandaag',
          yesterday: 'Gisteren',
          date: 'Andere Datum',
          daterange: 'Andere Datums',
          alltime: 'Altijd',
        },
        value: {
          alltime: 'Altijd',
          daterange: '{{start}} - {{end}}',
          timerange: '{{date}}, {{start}} - {{end}}',
          today: 'Vandaag',
          yesterday: 'Gisteren',
          date: '{{date}}',
        },
        allDay: 'Gehele Dag',
        action: {
          cancel: 'Annuleren',
          apply: 'Toepassen',
        },
      },
    },
    field: {
      originalUser: {
        label: 'Oorspronkelijke gebruiker',
      },
      masqueradingAsUser: {
        label: 'Gemaskeerd als gebruiker',
      },
      ipAddress: {
        label: 'IP-adres',
      },
      method: {
        label: 'Eventtype',
        value: {
          POST: 'HTTP POST',
          PUT: 'HTTP PUT',
          PATCH: 'HTTP PATCH',
          DELETE: 'HTTP DELETE',
          ALARM: 'ALARM',
          MODBUS: 'MODBUS',
          NOTIFICATION: 'NOTIFICATION',
          NOTIFICATION_BUNDLE: 'NOTIFICATION BUNDLE',
        },
      },
      endpoints: {
        label: 'Naar',
      },
      statusCode: {
        label: 'Event',
      },
      statusString: {
        label: 'Event',
      },
      additionalInfo: {
        label: 'Berichtdetails',
      },
      requestTime: {
        label: 'Tijdstip van Event',
      },
      destination: {
        label: 'Naar',
      },
      location: {
        label: 'Plantage Omgeving',
      },
      origin: {
        label: 'Van',
      },
    },
  },
  user: {
    overview: {
      title: 'Gebruikers',
      empty: 'Er zijn geen gebruikers die voldoen aan uw zoekopdracht.',
      addButton: 'Gebruiker',
      convertToOperator: 'Converteren naar operator',
    },
    create: {
      title: '{{fullName}} Aanmaken',
    },
    edit: {
      title: '{{fullName}} Aanpassen',
      saveBeforeLeaveSlot: 'Je moet de gebruiker eerst opslaan voordat je verlof toe kunt voegen.',
      tab: {
        info: 'Gegevens',
        availability: 'Beschikbaarheid',
      },
    },
    field: {
      leaveBalances: { label: 'Verlofoverzicht' },
      avatar: { label: 'Profielfoto' },
      fullName: { label: 'Naam', unknown: 'Gebruiker' },
      firstName: { label: 'Voornaam' },
      lastName: { label: 'Achternaam' },
      email: { label: 'Email' },
      BSG: { label: 'BSG gebruiker' },
      password: { label: 'Wachtwoord' },
      language: { label: 'Taal' },
      dateJoined: { label: 'Toegevoegd Op' },
      manager: { label: 'Manager' },
      telegramUsername: { label: 'Telegram Gebruikersnaam' },
      badgeId: { label: 'Badge ID' },
      stakeholder: { label: 'Leverancier' },
      groups: {
        label: 'Groepen',
        value: {
          superuser: 'Supergebruiker',
          admin: 'Admin',
          manager: 'Manager',
          grower: 'Grower',
          rnd: 'R&D',
          stakeholder: 'Leverancier',
          planner: 'Planner',
          'bsd-general': 'BSD Standaard',
          'shift-lead': 'Ploegen Leider',
          'td': 'Technische Dienst',
          'supplier': 'Leverancier',
          'bsd-script-mng': 'BSD Script Manager',
          'lms-admin': 'LMS Admin',
          'lms-bso-user': 'LMS BSO Gebruiker',
          'lms-bsd-user': 'LMS BSD Gebruiker',
          'lms-bso-mngr': 'LMS BSD Manager',
          'lms-bsd-mngr': 'LMS BSD Manager',
        },
        empty: 'Geen groepen toegekend.',
      },
      availability: { label: 'Beschikbaarheid' },
      workSchedules: { label: 'Roosters' },
      leaveCalendars: { label: 'Verlofkalenders' },
      extraLeaveBalances: {
        label: 'Resterend saldo',
        empty: 'Er is geen resterend saldo ingevoerd.',
      },
      isOperator: { label: 'Is Operator' },
      editOpenProductionOrder: {
        label: 'Kan Open Productieorder Aanpassen',
        value: {
          all: 'Allemaal',
          some: 'Artikeltype Specifiek',
          none: 'Geen',
        },
      },
      releaseProductionOrder: {
        label: 'Kan Open Productieorder Vrijgeven',
        value: {
          all: 'Allemaal',
          some: 'Artikeltype Specifiek',
          none: 'Geen',
        },
      },
      editReleasedProductionOrder: {
        label: 'Kan Vrijgegeven Productieorder Aanpassen',
        value: {
          all: 'Allemaal',
          some: 'Artikeltype Specifiek',
          none: 'Geen',
        },
      },
    },
    login: {
      title: 'Inloggen',
      reloginMessage: 'U bent uitgelogd. Log opnieuw in.',
      forgotPasswordLink: 'Wachtwoord vergeten?',
      errors: {
        invalidCredentials: 'Email of wachtwoord is niet correct',
        unknown: 'Onbekende fout, status code: {{status}}.',
      },
      loginButton: 'Inloggen',
      workStationStartButton: 'Start Werkplek',
      operatorStartButton: 'Inloggen als Operator',
      or: 'Of',
      backButton: 'Ga Terug',
    },
    passwordForgot: {
      title: 'Wachtwoord Vergeten',
      requestButton: 'Verstuur Email',
      requestedEmailText: 'Er is een email met een wachtwoord reset code verzonden naar uw email adres.',
    },
    resetPassword: {
      title: 'Wachtwoord Reset',
      resetButton: 'Reset',
      saveSuccess: 'Uw wachtwoord is succesvol gereset.',
    },
    activate: {
      title: 'Account Activeren',
      intro: 'Welkom bij Tracy! U hoeft alleen nog een wachtwoord in te stellen om uw account te activeren.',
      activateButton: 'Activeer Account',
      saveSuccess: 'Uw account is succesvol geactiveerd.',
    },
    changePassword: {
      title: 'Wachtwoord Wijzigen',
      field: {
        passwordOld: {
          label: 'Huidig Wachtwoord',
        },
        passwordNew: {
          label: 'Nieuw Wachtwoord',
        },
      },
      saveButton: 'Opslaan',
    },
    account: {
      title: 'Profiel',
      logoutButton: 'Uitloggen',
      info: {
        title: 'Gegevens',
      },
      password: {
        title: 'Wachtwoord Veranderen',
      },
      logout: {
        title: 'Uitloggen',
        confirm: 'Weet u zeker dat u wilt uitloggen?',
      },
    },
  },
  exactItem: {
    overview: {
      title: 'Artikelen',
      empty: 'Er zijn geen artikelen die voldoen aan uw zoekopdracht.',
      viewLink: '(bekijken)',
      actions: {
        label: 'Koppelingen Aanpassen ({{count}})',
        action: {
          linkArticleTypes: 'Koppel Artikeltype ({{count}})',
          linkArticleTypes_plural: 'Koppel Artikeltypes ({{count}})',
          unlinkArticleTypes: 'Ontkoppel Artikeltype ({{count}})',
          unlinkArticleTypes_plural: 'Ontkoppel Artikeltypes ({{count}})',
        },
      },
    },
    edit: {
      title: 'Artikel Aanpassen',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      classification: { label: 'Artikel groep' },
      isMakeItem: { label: 'Maak' },
      isBuyItem: { label: 'Koop' },
      name: { label: 'Naam' },
      articleType: { label: 'Artikeltype' },
      componentType: { label: 'Componenttype' },
    },
  },
  operator: {
    home: {
      welcome: 'Welkom, {{firstName}}!',
      clockInTime: 'Je bent ingeklokt om {{clockInTime}}.',
      notClockedIn: 'Je bent op het moment niet ingeklokt.',
      availability: 'Beschikbaarheid',
      scheduled: {
        notScheduled: 'Je bent vandaag niet ingepland op een werkstation.',
        title: 'Vandaag ben je ingepland op de volgende werkstations:',
        times: '{{startAt}} - {{endAt}}',
      },
    },
    availability: {
      title: 'Beschikbaarheid',
    },
    logoutModal: {
      title: 'Uitloggen',
      content: 'Weet je zeker dat je wilt uitloggen?',
      logoutButton: 'Uitloggen',
    },
    start: {
      title: 'Inloggen als Operator',
      startButton: 'Inloggen',
    },
    overview: {
      title: 'Operators',
      empty: 'Er zijn geen operators die voldoen aan uw zoekopdracht.',
      addButton: 'Operator',
    },
    create: {
      title: '{{fullName}} Aanmaken',
    },
    edit: {
      title: '{{fullName}} Aanpassen',
      saveBeforeLeaveSlot: 'Je moet de operator eerst opslaan voordat je verlof toe kan voegen.',
      tab: {
        info: 'Gegevens',
        capabilities: 'Vaardigheden',
        availability: 'Beschikbaarheid',
      },
      workScheduleDescription: `Rooster vanaf {{startDate}}`,
    },
    field: {
      leaveBalances: { label: 'Verlofoverzicht' },
      avatar: { label: 'Profielfoto' },
      fullName: { label: 'Naam', unknown: 'Operator' },
      firstName: { label: 'Voornaam' },
      lastName: { label: 'Achternaam' },
      operatorCapabilities: {
        label: 'Vaardigheden',
        empty: 'Geen vaardigheden toegekend',
        none: 'Er zijn nog geen vaardigheden geconfigureerd in het systeem.',
      },
      createdAt: { label: 'Toegevoegd Op' },
      badgeId: { label: 'Badge ID' },
      workSchedules: {
        label: 'Roosters',
        empty: 'Er zijn nog geen roosters toegevoegd.',
      },
      availability: { label: 'Beschikbaarheid' },
      leaveCalendars: { label: 'Verlofkalenders' },
      manager: { label: 'Manager' },
      hasPendingLeave: { label: 'Openstaande Verlofaanvragen' },
      extraLeaveBalances: {
        label: 'Resterend saldo',
        empty: 'Er is geen resterend saldo toegevoegd.',
      },
      deleted: { label: 'Verwijderd' },
    },
  },
  articleTypeWarehouse: {
    overview: {
      empty: 'Geen voorraad gevonden.',
    },
    field: {
      stock: { label: 'Voorraad', },
    },
    articleType: {
      id: 'ID',
      code: 'Code',
      classification: 'Groep',
      name: 'Naam',
    },
    warehouse: {
      name: 'Magazijn',
    },
    storageLocations: {
      field: {
        lines: { label: 'Opslaglocaties' },
        code: { label: 'Code' },
        default: { label: 'Standaard' },
        name: { label: 'Naam' },
        stock: { label: 'Voorraad' },
      },
      noStorageLocation: 'Geen opslaglocatie gespecificeerd.',
    },
  },
  salesOrder: {
    basicInfo: 'Details',
    overview: {
      empty: 'Er zijn geen Verkooporders die voldoen aan uw zoekopdracht.',
      title: 'Verkooporders',
      responsibilityModal: {
        title: {
          edit: 'Verantwoordelijkheid Aanpassen',
          add: 'Verantwoordelijkheid Aanmaken',
        },
      },
      allocated: {
        label: 'Gealloceerd',
        value: 'Gealloceerd: {{count}}/{{total}}',
      },
      ready: {
        label: 'Gealloceerd',
        value: 'Gealloceerd Order(s): {{count}}/{{total}}',
      },
      inStock: {
        label: 'Op Voorraad',
        value: 'Op Voorraad: {{count}}/{{total}}',
      },
      planned: {
        label: 'Gepland',
        value: 'Gepland: {{count}}/{{total}}',
      },
      picked: {
        label: 'Verzameld',
        value: 'Verzameld: {{count}}/{{total}}',
      },
      shipped: {
        label: 'Verzonden',
        value: 'Verzonden: {{count}}/{{total}}',
      },
      delivered: {
        label: 'Afgeleverd',
        value: 'Afgeleverd: {{status}}',
      },
      invoiced: {
        label: 'Gefactureerd',
        value: 'Gefactureerd: {{status}}',
      },
      exactReturned: {
        label: 'Teruggestuurd',
        value: 'Teruggestuurd: {{count}}/{{total}}',
      },
      showMore: 'Laat meer zien ({{count}} regel)',
      showMore_plural: 'Laat meer zien ({{count}} regels)',
      showLess: 'Laat minder zien',
      quantities: {
        required: '# Nodig',
        allocated: '# Gealloceeed',
        available: '# Beschikbaar',
        warehouse: '# magazijn',
        projected: '# geprojecteerd',
        planned: '# gepland',
        expected: '# verwacht',
      },
    },
    edit: {
      title: 'Verkooporder Aanpassen',
      allocateButton: 'Alloceren',
      stage: {
        progress: { label: 'Progressie' },
        general: { label: 'Algemeen' },
        customer: { label: 'Klant' },
        dates: { label: 'Data' },
        salesOrderLines: { label: 'Verkooporderregels' },
        pickOrders: { label: 'Verzendorders' },
        deliveryAddress: { label: 'Afleveradres' },
        invoiceAddress: { label: 'Factuuradres' },
        remarks: { label: 'Interne Notities' },
        responsibilities: { label: 'Verantwoordelijkheden' },
      },
      allocateModal: {
        title: 'Alloceer {{articleType}} ({{allocated}}/{{required}})',
        allocate: 'Alloceer',
        source: {
          quantity: 'Aantal',
          unallocated: 'Ongealloceerd',
          allocated: 'Al Gealloceerd',
          date: 'Verwachtingsdatum',
          quantityStatus: {
            label: 'Status',
            value: {
              open: 'Open',
              in_progress: 'In Behandeling',
              done: 'Klaar',
            },
          },
          hasAvailable: { label: 'Beschikbaar' },
          hasAllocated: { label: 'Gealloceerd' },
        },
      },
    },
    confirmDelete: 'Weet u zeker dat u deze Verkooporder wil verwijderen?',
    field: {
      id: { label: 'ID' },
      erpId: { label: 'ERP ID' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          partial: 'Gedeeltelijk',
          complete: 'Gereed',
          canceled: 'Geannuleerd',
        },
      },
      information: { label: 'Informatie' },
      customer: { label: 'Klant' },
      reference: { label: 'Referentie' },
      orderNumberErp: { label: 'Nummer' },
      orderDate: { label: 'Orderdatum' },
      deliveryDate: { label: 'Leverdatum' },
      deliveryAddress: { label: 'Leveradres' },
      remarks: { label: 'Opmerkingen' },
      description: { label: 'Omschrijving' },
      outShipments: {
        label: 'Verzamelorder',
        empty: 'Nog geen Verzamelorders toegevoegd.',
        value: 'Verzamelorder {{id}}',
      },
      lines: { label: 'Verkooporderregels' },
      quantity: { label: 'Aantal' },
      exactSalesOrder: { label: 'Verkooporder' },
      warehouse: { label: 'Magazijn' },
      allocationStatus: {
        label: 'Status',
        value: {
          unallocated: 'Ongealloceerd (volledig of deels)',
          not_ready: 'Volledig gealloceerd maar nog niet klaar',
          not_shipped: 'Volledig klaar maar nog niet verzonden',
          all_shipped: 'Volledig verzonden',
        },
      },
      stockStatus: {
        label: 'Voorraadstatus',
        value: {
          no_stock: 'Geen voorraad aanwezig',
          partial: 'Gedeeltelijke voorraad aanwezig',
          complete: 'Volledige voorraad aanwezig',
        },
      },
      priority: {
        label: 'Prioriteit',
        value: {
          high: '1 - Hoog',
          medium: '2 - Gemiddeld',
          low: '3 - Laag',
        },
      },
      responsibilities: { empty: 'Er zijn nog geen verantwoordelijkheden toegevoegd.' },
      shippingMethod: { label: 'Verzendmethode' },
    },
    filters: {
      todo: 'Te doen',
      inProgress: 'Bezig',
      complete: 'Klaar',
      overdueReceipts: 'Te laat (zonder werkorders)',
      overdueRequests: 'Te laat (met werkorders)',
      receiptsThisWeek: 'Gepland deze week',
      futureReceipts: 'Gepland toekomst',
      deleted: 'Verwijderd',
    },
  },
  salesOrderLine: {
    addToOutShipmentButton: 'Toevoegen',
    consumedLine: 'Geen beschikbare hoeveelheid meer voor de geselecteerde verkooporderregel(s).',
    field: {
      id: { label: 'ID' },
      project: { label: 'Project' },
      productionDueDate: { label: 'Productie Einddatum' },
      deliveryDate: { label: 'Leverdatum' },
      quantity: { label: 'Aantal' },
      _toReceive: { label: 'Te ontvangen' },
      unitPrice: { label: 'Stuk Prijs' },
      netPrice: { label: 'Netto Prijs' },
      code: { label: 'Artikel Code' },
      salesOrder: { label: 'Verkooporder' },
      erpLines: { label: 'ERP' },
      decimalQuantity: { label: 'Actual ERP Quantity'},
      description: { label: 'Beschrijving' },
    },
  },
  exactPurchaseOrder: {
    field: {
      id: { label: 'ID' },
      number: { label: 'Nummer' },
      status: {
        label: 'Status',
        value: {
          10: 'Open',
          20: 'Gedeeltelijk',
          30: 'Gereed',
          40: 'Afgebroken',
        },
      },
    },
  },
  purchaseOrder: {
    overview: {
      empty: 'Er zijn geen Inkooporders die voldoen aan uw zoekopdracht.',
    },
    filters: {
      status: 'Status',
      requestStatus: 'Werkorder Status',
      ordersDueAndExpected: 'Orders te laat & verwacht',
      number: 'Nummer',
      todo: 'Te doen',
      inProgress: 'Bezig',
      complete: 'Klaar',
      overdueReceipts: 'Te laat (zonder werkorders)',
      overdueRequests: 'Te laat (met werkorders)',
      receiptsThisWeek: 'Gepland deze week',
      futureReceipts: 'Gepland toekomst',
      deleted: 'Verwijderd',
    },
    confirmDelete: 'Weet u zeker dat u deze Inkooporder wil verwijderen?',
    field: {
      id: { label: 'ID' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          partial: 'Gedeeltelijk',
          complete: 'Gereed',
          canceled: 'Geannuleerd',
        },
      },
      supplier: { label: 'Leverancier' },
      quantity: { label: 'Aantal' },
      reference: { label: 'Referentie' },
      orderNumberErp: { label: 'Nummer' },
      orderDate: { label: 'Orderdatum' },
      receiptDate: { label: 'Ontvangstdatum', error: 'Ontvangstdatum mag niet leeg zijn.' },
      trackingCode: { label: 'Tracking Code' },
      licensePlate: { label: 'Kenteken' },
      lines: { label: 'Inkooporderregels' },
      inShipments: {
        label: 'Ontvangstorder',
        empty: 'Nog geen Ontvangstorders toegevoegd.',
        value: 'Ontvangstorder {{id}}',
      },
      exactPurchaseOrder: { label: 'Inkooporder' },
      warehouse: { label: 'Magazijn' },
      linkedSalesOrder: { label: 'Gekoppelde Verkooporder ERP' },
      shippingMethod: { label: 'Verzendmethode' },
    },
  },
  purchaseOrderLine: {
    noBatchTypeBuy: 'Geen proces gedefinieerd',
    addToInShipmentButton: 'Toevoegen',
    consumedLine: 'Geen beschikbare hoeveelheid meer voor de geselecteerde inkooporderregel(s).',
    field: {
      id: { label: 'ID' },
      productionRequest: { label: 'Werkorder' },
      project: { label: 'Project' },
      receiptDate: { label: 'Ontvangstdatum' },
      quantity: { label: 'Aantal' },
      code: { label: 'Artikel Code' },
      _toReceive: { label: 'Te ontvangen' },
      unitPrice: { label: 'Stuk Prijs' },
      netPrice: { label: 'Netto Prijs' },
      purchaseOrder: { label: 'Inkooporder' },
      purchaseUnit: { label: 'Bestelgrootte' },
      erpLines: { label: 'ERP' },
      decimalQuantity: { label: 'Actual ERP Quantity'},
      description: { label: 'Description' },
    },
  },
  inShipment: {
    disabledDelete: 'Kan Ontvangstorder niet verwijderen omdat er actieve werkorders zijn',
    field: {
      id: { label: 'ID' },
      receivedAt: { label: 'Ontvangstdatum' },
      trackingCode: { label: 'Tracking Code' },
      licensePlate: { label: 'Kenteken' },
      shipperName: { label: 'Chauffeur' },
    },
    edit: {
      title: 'Ontvangstorder Bewerken',
    },
    missingReceiveProcessCreate: 'Missend Ontvangstproces aanmaken',
  },
  inShipmentLine: {
    disabledDelete: 'Kan regel niet verwijderen omdat werkorder actief of afgerond is',
    field: {
      id: { label: 'ID' },
      inShipment: { label: 'Ontvangst ID' },
      autoPerformedProductionRequest: { info: 'Deze regel is automatisch toegevoegd omdat er meer artikelen ontvangen zijn dan aangegeven op de Inkooporder.' },
    },
    create: {
      title: 'Ontvangst Orderregel Toevoegen',
    },
    edit: {
      title: 'Ontvangst Orderregel Bewerken',
    },
    missingBuyProcessWarning: 'Geen Ontvangstproces gevonden voor dit artikeltype.',
    missingBuyProcessCreate: 'Missend Ontvangstproces aanmaken',
  },
  outShipment: {
    disabledDelete: 'Kan Verzamelorder niet verwijderen omdat er actieve werkorders zijn',
    field: {
      id: { label: 'ID' },
      shipperPickupDate: { label: 'Ophaaldatum' },
      shipperName: { label: 'Chauffeur' },
      shipperLicensePlate: { label: 'Kenteken' },
      freightType: { label: 'Verzendsoort' },
      freightCompany: { label: 'Transporteur' },
      freightInfo: { label: 'Lading Info' },
      outShipmentTemplate: { label: 'Verzend Template' },
    },
    missingPickProcessCreate: 'Missend Verzamelproces aanmaken',
  },
  outShipmentLine: {
    disabledDelete: 'Kan regel niet verwijderen omdat werkorder actief of afgerond is',
    field: {
      id: { label: 'ID' },
      outShipment: { label: 'Verzamel ID' },
    },
    create: {
      title: 'Verzamelorder Toevoegen',
    },
    edit: {
      title: 'Verzamelorder Bewerken',
    },
    missingSellProcessWarning: 'Geen Verzendproces gevonden voor dit artikeltype.',
    missingSellProcessCreate: 'Missend Verzendproces aanmaken',
  },
  workSchedule: {
    field: {
      employer: { label: 'Werkgever', placeholder: 'Begin met typen om werkgevers te zoeken of toe te voegen' },
      startDate: { label: 'Ingangsdatum' },
      workSlots: {
        label: 'Werktijden',
        empty: 'Er zijn nog geen werktijden toegevoegd.',
      },
      allowedLegalLeaveMinutes: { label: 'Wettelijke Vakantie' },
      allowedExtraLeaveMinutes: { label: 'Bovenwettelijke Vakantie' },
      allowedRwhMinutes: { label: 'ADV' },
    },
  },
  capability: {
    overview: {
      title: 'Vaardigheden',
      empty: 'Er zijn geen vaardigheden die voldoen aan uw zoekopdracht.',
      addButton: 'Vaardigheid',
    },
    create: {
      title: 'Vaardigheid Aanmaken',
    },
    edit: {
      title: 'Vaardigheid Aanpassen',
    },
    field: {
      name: { label: 'Naam' },
      operatorCapabilities: {
        label: 'Operators',
        none: 'Er zijn nog geen operators geconfigureerd in het systeem.',
      },
    },
  },
  processPhase: {
    overview: {
      title: 'Proces Fases',
      empty: 'Er zijn geen Proces Fases die voldoen aan uw zoekopdracht.',
      addButton: 'Proces Fase',
    },
    create: {
      title: 'Proces Fase Aanmaken',
    },
    edit: {
      title: 'Proces Fase Aanpassen',
    },
    field: {
      icon: { label: 'Icoon' },
      name: { label: 'Naam' },
    },
  },
  batchType: {
    overview: {
      title: 'Processen',
      empty: 'Geen processen gevonden.',
    },
    create: {
      title: 'Proces Aanmaken',
    },
    onTheFly: {
      title: 'Flexibele Registratie Instellingen',
      description: "Door het instellen van 'Flexibele Registratie' kan Tracy automatisch een batch aanmaken op het moment dat een serienummer gescand wordt dat nog niet bekend is. Hierbij wordt wel gecontroleerd of het ingevoerde serienummer aan het format voldoet.",
    },
    edit: {
      title: 'Proces Aanpassen',
      bulkTemplateButton: 'Template Bulk Toevoegen',
      bulkTemplateModal: {
        title: 'Template aan artikelen toevoegen',
        selectButton: 'Voeg aan {{count}} artikel toe',
        selectButton_plural: 'Voeg aan {{count}} artikelen toe',
        selectAllButton: 'Voeg aan alle {{count}} artikelen toe',
      },
      bulkTemplateProgressModal: {
        title: 'Template Bulk Toevoegen Voortgang',
        progress: '{{count}} van {{total}} toegevoegd.',
        timeRemaining: '{{time}} te gaan.',
      },
      finalizeProgressModal: {
        title: 'Voortgang processjabloon afronden',
      },
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Omschrijving' },
      quantity: { label: 'Batchgrootte' },
      name: { label: 'Batchgrootte' },
      articleType: { label: 'Artikeltype' },
      onTheFlySerialNumberFormat: {label: 'Flexibele Registratie Serienummer Format'},
      type: {
        label: 'Type',
        value: {
          make: 'Maak',
          buy: 'Ontvangen',
          sell: 'Verzenden',
          transfer_line: 'Voorraadverplaatsingsregel',
          component: 'Component',
          subassembly: 'Subassemblage',
          stock_count: 'Voorraadtelling',
          stock_count_template: 'Template Voorraadtelling',
          transfer: 'Voorraadverplaatsing',
          on_the_fly: 'Flexibele Registratie',
          pick_order: 'Verzamelorder',
          receive_order: 'Ontvangstorder',
          make_template: 'Template Artikel Maken',
          buy_template: 'Template Artikel Ontvangen',
          sell_template: 'Template Artikel Verzenden',
          transfer_line_template: 'Template Voorraadverplaatsingsregel',
        },
      },
      serialNumberFormat: { label: 'Serienummer Format' },
      default: { label: 'Standaard' },
      source: { label: 'Template' },
      targets: {
        label: 'Artikeltypes',
        value: 'Gebruikt in {{count}} artikeltype',
        value_plural: 'Gebruikt in {{count}} artikeltypes'
      },
      onTheFlyType: {
        label: 'Flexibele Registratie Batch Grootte',
        info: 'Deze instelling bepaalt welke grootte een batch krijgt als deze tussentijds wordt aangemaakt',
        value: {
          none: 'Geen',
          fixed: 'Vast',
          flexible: 'Flexibel',
        },
        tooltip: {
          none: 'Als je deze optie selecteert, negeren we de echte grootte van de aangemaakte batch en kan deze oneindig gebruikt worden.',
          fixed: 'Als je deze optie selecteert, moet je een vaste grootte voor de aangemaakte batches invullen.',
          flexible: 'Als je deze optie selecteert, moet de operator de batch grootte invullen zodra een nieuwe batch wordt aangemaakt.',
        },
      },
      onTheFlySource: {
        label: 'Flexibele Registratie Bron',
        info: 'Deze instelling bepaalt waar vanuit de nieuwe batch aangemaakt wordt.',
        value: {
          new: 'Nieuw',
          existing: 'Bestaand',
        },
        tooltip: {
          new: 'Er wordt een nieuwe batch gemaakt.',
          existing: 'Het systeem zal vragen om een bestaande batch te scannen waar de nieuwe batch uitgehaald zal worden als een subbatch.',
        },
      },
      autoAddProcessToNewArticles: { label: 'Proces automatisch toevoegen aan nieuwe gesynchroniseerde artikeltypen' },
    },
  },
  articleType: {
    overview: {
      title: 'Artikeltypes',
      empty: 'Er zijn geen Artikeltypes die voldoen aan uw zoekopdracht.',
      addButton: 'Artikeltype',
      viewBOMButton: 'Bekijk Stuklijst',
      copyBatchTypeButton: 'Kopieer Batch Types',
    },
    printShelfLabelButton: 'Magazijnlocatie Label',
    selectModal: {
      title: 'Kies een Artikeltype',
    },
    create: {
      title: 'Artikeltype Aanmaken',
    },
    edit: {
      baseFields: {
        label: 'Basis Velden',
        info: 'Deze velden behoren niet tot een procestype maar zijn toepasselijk op het gehele artikeltype.',
      },
      title: 'Artikeltype Aanpassen',
      version: 'Versie {{version}}',
      draft: 'Concept',
      finalizeButton: 'Afronden',
      nextVersionButton: 'Volgende Versie',
      noStepSelected: 'Geen stap geselecteerd',
      workStationsModal: {
        title: 'Selecteer Werkplekken',
      },
      copyPaste: {
        copyButton: 'Kopieer',
        pasteButton: 'Plak',
        copied: 'Succesvol gekopieerd',
        pasted: 'Succesvol geplakt',
        pasteError: 'Er kan niks geplakt worden',
      },
      bomFieldsModal: {
        title: 'Stuklijst Velden',
        content: 'Dit proces bevat velden van het type \'Stuklijst\'. Wisselen naar een ander batch type dan \'maak\' zal er voor zorgen dat deze velden worden verwijderd.',
      },
      endWithVariableQuantity: 'Dit proces kan niet eindigen met een batch die variabel is. Voeg een veld "grootte" toe om de daadwerkelijke grootte van de batch te bepalen.',
      splitWithVariableQuantity: 'Een batch stap kan niet gebruikt worden op een variabele batch.',
      quantityFieldWithoutVariableQuantity: 'Een grootte veld kan niet worden gebruikt zonder een variabele batch.',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      name: { label: 'Naam' },
      line: { label: 'Productieomgeving' },
      versions: { label: 'Versies' },
      batchTypes: { label: 'Processen' },
      articleType: { label: 'Artikeltype' },
      componentType: { label: 'Componenttype' },
      classification: { label: 'Groep' },
      startDate: { label: 'Startdatum' },
      endDate: { label: 'Einddatum' },
      isMake: { label: 'Maak' },
      isBuy: { label: 'Koop' },
      isSerial: { label: 'Serieel' },
      isMakeOrBuy: { label: 'Maak/Koop' },
      storageLocations: { short: 'Opslaglocatie(s)', long: 'Opslaglocatie(s) - Hoeveelheid' },
      availableBatches: {
        label: 'Beschikbare Batch(es)',
        value: '{{count}} beschikbare batch',
        value_plural: '{{count}} beschikbare batches',
        noBatch: 'Geen beschikbare batch',
      },
      totalStock: { label: 'Totale Voorraad' },
      storageLocationStock: { label: 'Opslaglocatie Voorraad' },
      deleted: { label: 'Verwijderd' },
      endDateBeforeCurrDate: {label: 'Einddatum vóór vandaag weergeven'},
      trackBatchUsage: { label: 'Batchgebruik bijhouden'},
    },
  },
  articleTypeVersion: {
    field: {
      articleType: { label: 'Artikeltype' },
      productionLineVersion: { label: 'Productieomgeving Versie' },
    },
  },
  productionLine: {
    overview: {
      title: 'Productieomgevingen',
      empty: 'Er zijn geen productieomgevingen die voldoen aan uw zoekopdracht.',
      addButton: 'Productieomgeving',
    },
    planning: {
      title: 'Productie omgevingen',
      empty: 'Geen productieomgevingen gevonden',
      productionLine: 'Productieomgeving',
      week: 'Week',
      days: {
        monday: 'Maandag',
        tuesday: 'Dinsdag',
        wednesday: 'Woensdag',
        thursday: 'Donderdag',
        friday: 'Vrijdag',
        saturday: 'Zaterdag',
        sunday: 'Zondag',
      },
      parts: {
        night: 'Nacht (00:00 - 06:00)',
        morning: 'Ochtend (06:00 - 12:00)',
        afternoon: 'Middag (12:00 - 18:00)',
        evening: 'Avond (18:00 - 00:00)',
      },
    },
    create: {
      title: 'Productieomgeving Aanmaken',
    },
    edit: {
      baseFields: {
        label: 'Globale instellingen',
        info: 'Deze velden behoren niet tot een versie maar zijn toepasselijk op elke versie.',
      },
      title: 'Productieomgeving Aanpassen',
      version: 'Versie {{version}}',
      draft: 'Concept',
      finalizeButton: 'Afronden',
      nextVersionButton: 'Volgende Versie',
      tabs: {
        map: 'Kaart',
        codes: 'Codes',
        availability: 'Beschikbaarheid',
      },
    },
    selectModal: {
      title: 'Kies een Productie',
    },
    codesModal: {
      title: 'Codes Overzicht',
      noPrintersAvailable: 'Er zijn geen printers beschikbaar.',
    },
    field: {
      name: { label: 'Naam' },
      fullName: { label: 'Naam' },
      versions: { label: 'Versies' },
      timezone: { label: 'Tijdzone' },
      cultivationArea: { label: 'Groei Gebied (m2)' },
      color: {
        label: 'Kleur',
        value: {
          red: 'Rood',
          orange: 'Oranje',
          yellow: 'Geel',
          olive: 'Olijfgroen',
          green: 'Groen',
          teal: 'Turquoise',
          blue: 'Blauw',
          violet: 'Violet',
          purple: 'Paars',
          pink: 'Roze',
          brown: 'Bruin',
          grey: 'Grijs',
          black: 'Zwart',
        },
      },
      workSchedules: {
        label: 'Roosters',
        errors: {
          missing: 'Productielijn moet een geldig rooster hebben.',
        }
      },
      leaveCalendars: { label: 'Verlofkalendars' },
      factory: { label: 'Locatie' },
      type: {
        label: 'Type',
        value: {
          production: 'Productie',
          multilayer: 'Multilayer',
          highwire: 'Highwire',
          'rnd multilayer': 'R&D Multilayer',
          'rnd highwire': 'R&D Highwire',
        },
      },
    },
  },
  productionLineVersion: {
    field: {
      version: { label: 'Versie' },
      draft: { label: 'Concept' },
      floorPlan: { label: 'Plattegrond' },
      workStations: {
        label: 'Werkplekken',
        addButton: 'Werkplek',
        error: {
          missing: 'Ten minste een werkplek is vereist.',
        }
      },
      productionLine: { label: 'Productieomgeving' },
    },
  },
  workStation: {
    field: {
      name: { label: 'Naam' },
      code: { label: 'Code' },
      dataSources: { label: 'Databronnen' },
      defaultFte: { label: 'Standaard FTE' },
      singleUser: {
        label: 'Aantal Logins',
        value: {
          true: 'Enkele Login',
          false: 'Multi Login',
        },
        tooltip: {
          true:
            "Wanneer ingesteld als 'Enkele Login' zal het werkstation eenmalig de badge scan vragen aan de operator. Na standaard 4u zal er automatisch worden uitgelogd. Deze tijd kun je aanpassen via de variabele single_user_work_station_timeout_minutes in de algemene instellingen.",
          false:
            "Wanneer ingesteld als 'Multi Login' zal het werkstation bij elke nieuwe stap vragen om de badge scan.",
        },
      },
      productionStrategy: {
        label: 'Productiestrategie',
        value: {
          together: 'Samen',
          seperate_spread: 'Gescheiden (Verdeeld)',
          seperate_planned: 'Gescheiden (Gepland)',
        },
        tooltip: {
          together: "Wanneer ingesteld als 'Samen' zullen de verschillende locaties samen werken als één locatie.",
          seperate_spread:
            "Wanneer ingesteld als 'Gescheiden (Verdeeld)' zullen de verschillende locaties gescheiden werken aan dezelfde productie opdrachten.",
          seperate_planned:
            "Wanneer ingesteld als 'Gescheiden (Gepland)' zullen de verschillende locaties gescheiden werken aan productie opdrachten die per locatie worden ingepland.",
        },
      },
      maintenanceStrategy: {
        label: 'Onderhoudsstrategie',
        value: {
          together: 'Samen',
          seperate: 'Gescheiden',
        },
        tooltip: {
          together:
            "Wanneer ingesteld als 'Samen' zal onderhoud aan de verschillende locaties samen uitgevoerd en gepland worden voor alle locaties.",
          seperate:
            "Wanneer ingesteld als 'Gescheiden' zal onderhoud aan de verschillende locaties gescheiden uitgevoerd en gepland worden per locatie.",
        },
      },
      steps: { label: 'Stappen' },
      workAssigned: { label: 'Gepland' },
      workUnassigned: { label: 'Ongepland' },
      work: { label: 'Werk' },
      productionRequests: { label: 'Werkorders' },
      automationDate: { label: 'Automatiseringsdatum' },
    },
    planning: {
      title: 'Dagplanning',
      empty: 'Er is niks om in te plannen voor deze dag.',
      assignmentsModal: {
        title: 'Dagplanning: {{name}}',
      },
      assignmentTimeModal: {
        title: 'Tijden Aanpassen',
        overlap: {
          label: 'Deze tijden overlappen met al geplande tijdsvakken voor deze operator.',
          assignment: '{{workStation}} van {{start}} tot {{end}}.',
        },
      },
      day: {
        today: 'Vandaag',
        tomorrow: 'Morgen',
        other: 'Anders',
      },
      stats: {
        work: 'Uren werk',
        workAssigned: 'Geplande uren',
        workUnassigned: 'Ongeplande uren',
        month: {
          january: 'JANUARI',
          february: 'FEBRUARI',
          march: 'MAART',
          april: 'APRIL',
          may: 'MEI',
          june: 'JUNI',
          july: 'JULI',
          august: 'AUGUSTUS',
          september: 'SEPTEMBER',
          october: 'OKTOBER',
          november: 'NOVEMBER',
          december: 'DECEMBER',
        },
      },
    },
    start: {
      title: 'Werkplek Starten',
      startButton: 'Starten',
    },
    production: {
      title: 'Productie',
      empty: 'Er zijn geen productieopdrachten die voldoen aan uw zoekopdracht.',
      stopButton: 'Stoppen',
      selectButton: 'Produceren',
      version: 'Versie {{version}}',
      operatorModal: {
        title: 'Scan Badge ID',
        confirmButton: 'Bevestigen',
        error: {
          noResults: 'Geen operators gevonden met het gegeven Badge ID.',
          emptyField: 'Geef de Badge ID van de operator in.',
          multipleResults: 'Meerdere operators gevonden met het gegeven Badge ID.',
          unexpected: 'Er is een onverwachte fout opgetreden.',
          missingCapabilities: 'De gevonden operator mist een benodigde vaardigheid: {{capabilities}}.',
          missingCapabilities_plural: 'De gevonden operator mist enkele benodigde vaardigheden: {{capabilities}}.',
        },
      },
      batchModal: {
        title: 'Scan Product',
        confirmButton: 'Bevestigen',
        error: {
          notFound: 'Geen product gevonden met het gegeven serienummer.',
        },
      },
      performModal: {
        generalErrors: 'Er zijn wat fouten voorgekomen',
        title: 'Voer Stap Uit',
        confirmButton: 'Bevestigen',
        formStep: {
          label: 'Serienummer',
          subject: {
            article_type: 'Artikel',
          },
        },
        printStep: {
          selectPrinter: 'Printer Selecteren',
          noPrintersAvailable: 'Geen Printers Beschikbaar',
          printButton: 'Printen',
          printedSuccesfully: 'Succesvol geprint',
        },
        splitStep: {
          printButton: 'Printen',
          printAllButton: 'Alles Printen ({{count}})',
          reprintButton: 'Opnieuw Printen',
          reprintButton_plural: 'Opnieuw Printen ({{count}})',
          bulkEntrySerialNumbersDetail: 'U kunt meerdere serienummers tegelijk scannen.',
          wrongFormat: 'Het serienummer {{serialNumber}} komt niet overeen met het vooraf bepaalde formaat.',
          expectedQuantity: 'U heeft het verwachte aantal serienummers al gescand.',
          integrationNotActive: 'Exact integratie is niet geactiveerd of bestaat niet.',
          alreadySyncedToExact: 'Het serienummer {{serialNumber}} is al gescand.',
        },
        quantityType: {
          quantity: 'Grootte',
          weight: 'Gewicht',
          error: {
            tooMuch: 'De ingevulde hoeveelheid is groter dan de hoeveelheid die voor deze order nog over is.',
          }
        },
        scan: {
          serialNumber: 'Serienummer',
          quantity: 'Aantal',
          error: {
            notFound: 'Er is geen batch gevonden met het opgegeven serienummer.',
            wrongArticleType: 'De batch, gevonden met het opgegeven serienummer, heeft niet het verwachtte artikeltype.',
            wrongBatchType: 'De batch, gevonden met het opgegeven serienummer, is niet van een batchtype dat gescand kan worden.',
            fullyUsed: 'De batch, gevonden met het opgegeven serienummber, heeft niet genoeg beschikbare voorraad.',
          },
        },
      },
      quantityModal: {
        title: 'Vul Batchgrootte In',
        quantity: 'Grootte',
        confirmButton: 'Bevestigen',
      },
      infoModal: {
        title: 'Info',
      },
      operator: {
        expiresAt: 'Verloopt om {{at}}',
      },
      onTheFlyQuantityModal: {
        title: 'Flexibele Registratie aantallen',
        description: 'Een aantal van de gescande batches bestaat nog niet in het systeem. Om deze middels Flexibele Registratie nu tussentijds aan te maken moet het systeem weten hoe groot deze batches zijn.',
        placeholder: 'Aantal',
      },
      onTheFlySourceModal: {
        title: 'Flexibele Registratie Bron',
        description: 'De gescande batch bestaat nog niet in het systeem. Om deze batch aan te maken moet het systeem weten waar deze batch uitgehaald is.',
        placeholder: 'Serienummer',
        error: {
          notFound: 'Er is geen batch gevonden met het opgegeven serienummer.',
          wrongArticleType: 'De batch, gevonden met het opgegeven serienummber, heeft niet het verwachtte artikeltype.',
          notEnough: 'De batch, gevonden met het opgegeven serienummber, heeft niet genoeg beschikbare voorraad.',
        },
      },
      fastForwardButton: 'Vooruitspoelen',
      fastForwardModal: {
        title: 'Vooruitspoelen',
        days: 'Dagen',
        confirmButton: 'Bevestigen',
      },
    },
    editModal: {
      editTitle: 'Werkplek Configureren',
      viewTitle: 'Werkplek Configuratie',
    },
    stopModal: {
      title: 'Werkplek Stoppen',
      content: 'Weet u zeker dat u wilt stoppen op deze werkplek?',
      stopButton: 'Stoppen',
    },
    day: {
      today: 'Vandaag',
      tomorrow: 'Morgen',
    },
  },
  productionRequest: {
    registerExistingButton: 'Voorraadtelling',
    overview: {
      title: 'Werk Orders',
      empty: 'Er zijn geen Productieorders die voldoen aan uw zoekopdracht.',
      addButton: 'Productieorder',
      productionRequestID: 'Werkorder ID',
      inShipmentId: 'Ontvangst ID',
      outShipmentId: 'Verzamel ID',
      exactSalesOrder: 'Open Verkooporder {{order}} in Exact',
      exactPurchaseOrder: 'Open Inkooporder {{order}} in Exact',
      exactShopOrder: 'Open Productieorder {{order}} in Exact',
      purchaseOrderNumber: 'Inkooporder ID',
      stockCountNumber: 'Voorraadtelling ID',
      salesOrderNumber: 'Verkooporder ID',
      linkedSalesOrder: 'Gekoppelde Verkooporder ERP',
      warehouseTransferID: 'Magazijnoverdracht ID',
      warehouseTransferLineID: 'Magazijnoverdrachtregel ID',
      productionOrderNumber: 'Productieorder ID',
      orders: "Orders",
      done: '{{count}} product klaar',
      done_plural: '{{count}} producten klaar',
      progress: '{{percentage}} v.h. werk klaar',
      extra: '+ {{count}} extra',
      extra_plural: '+ {{count}} extra',
      diff: {
        planned: 'Gepland',
        actual: 'Actueel',
        advance: 'Voortgang',
        delay: 'Vertraging',
      },
      information: 'Informatie',
      order: 'Order',
      processSteps: 'Proces stappen',
    },
    edit: {
      title: 'Werk Order Aanpassen',
      bom: 'Stuklijst',
      storageLocations: 'OPSLAGLOCATIES',
      releaseButton: 'Vrijgeven',
      stage: {
        articleType: {
          label: 'Artikeltype Info',
          bom: {
            notEnoughStock: "Niet genoeg voorraad beschikbaar om dit aantal te produceren",
          },
          showBomBtn: "Laat stuklijst zien",
        },
        warehouse: {
          label: 'Magazijn Info',
        },
        planning: {
          label: 'Planning',
          planned: 'Gepland',
          actual: 'Gerealiseerd',
          duration: 'Looptijd',
          start: 'Start',
          end: 'Eind',
        },
        bom: {
          label: 'BOM Lijst',
          articleCode: 'Artikeltype Code',
          articleName: 'Artikeltype Naam',
        },
        productionLines: {
          label: 'Productieomgevingen',
        },
      },
      couldNotAllocate: {
        header: 'Kon een {{content}} container niet alloceren op {{date}} omdat:',
        row: 'R{{rack}}L{{layer}}',
        content: {
          seeded: 'gezaaide',
          transplanted: 'getransplante',
          spaced_1: 'gespacete (1)',
          spaced_2: 'gespacete (2)',
        },
        reason: {
          no_space: 'Geen plek vrij voor container.',
          not_fifo: 'Container moet uit de rij op {{allocation_date}}, maar zou achter een container staan die er uit moet op {{row_date}}.',
          wrong_nutrition_script: 'Container heeft voedingscript {{allocation_script}} nodig, maar {{row_script}} draait in de rij.',
          wrong_irrigation_script: 'Container heeft irrigatiescript {{allocation_script}} nodig, maar {{row_script}} draait in de rij.',
          wrong_light_script: 'Container heeft lichtscript {{allocation_script}} nodig, maar {{row_script}} draait in de rij.',
        },
      },
    },
    field: {
      id: { label: 'Werkorder ID' },
      articleType: { label: 'Artikeltype' },
      processVersion: { label: 'Versie' },
      week: { label: 'Week' },
      quantity: { label: 'Hoeveelheid' },
      progress: { label: 'Voortgang' },
      startAt: { label: 'Verwacht Begin' },
      endAt: { label: 'Verwacht Eind' },
      duration: { label: 'Verwachte Looptijd' },
      project: { label: 'Project' },
      finished: { label: 'Afgerond' },
      productionOrder: { label: 'Productieorder' },
    },
    filter: {
      itemGroup: 'Artikel Groep',
      requestStatus: 'Werkorder Status',
      inShipmentId: 'Ontvangst ID',
      outShipmentId: 'Verzamel ID',
      warehouseTransferID: 'Overdracht ID',
      projectCode: 'Project Code',
      purchaseOrderNumberERP: 'Inkooporder ERP',
      salesOrderNumberERP: 'Verkooporder ERP',
      shippingMethod: 'Verzendmethode',
    },
    addModal: {
      title: 'Productieopdrachten Toevoegen',
      step: {
        pickWeek: 'Kies de week',
        pickArticleTypes: 'Kies de artikeltypes',
        setQuantities: 'Kies de hoeveelheden',
      },
      quantityWarning:
        'Een productieopdracht opslaan met hoeveelheid 0 staat gelijk aan deze productieopdracht verwijderen.',
      noArticleTypes: 'U hebt nog geen artikeltypes geselecteerd.',
      notFullyDeleted: 'Productieopdracht kon niet volledig verwijderd worden omdat de opdracht al gestart is.',
    },
    editModal: {
      title: 'Productieopdracht Aanpassen',
      title_plural: 'Productieopdrachten Aanpassen',
    },
  },
  globalValue: {
    edit: {
      unsetButton: 'Leegmaken',
      title: '{{key}} Aanpassen',
      operator_badge_print: {
        key: {
          operator_name: 'Naam van de operator.',
          badge_id: 'Badge ID van de operator.',
        },
      },
      workstation_code_print: {
        key: {
          workstation_name: 'Naam van het werkstation.',
          workstation_code: 'Code van het werkstation.',
        },
      },
      stock_location_print: {
        key: {
          article_type_code: 'Code van het artikeltype.',
          article_type_name: 'Naam van het artikeltype.',
          warehouse_code: 'Naam van het magazijn.',
          warehouse_locations: 'Magazijnlocaties.',
        },
      },
      loading_carrier_print: {
        key: {
          loading_carrier_serial: 'Serienummer van de ladingdrager',
          loading_carrier_name: 'Naam van de ladingdrager',
        },
      },
      login_url_print: {
        key: {
          slug: 'De slug van je tracy instantie. ({{value}})',
          app_url: 'De basis url van tracy. ({{value}})',
        },
      },
      progress_scope: {
        week: 'Week',
        month: 'Maand',
        day: 'Dag',
      },
    },
    overview: {
      title: 'Algemene instellingen',
      template: {
        empty: 'Template is leeg.',
        moreLines: '{{count}} regel niet getoond, hover om te tonen.',
        moreLines_plural: '{{count}} regels niet getoond, hover om te tonen.',
      },
    },
    field: {
      key: {
        label: 'Naam Instelling',
      },
      value: {
        label: 'Waarde',
      },
      writable: {
        label: 'Schrijfbaar?',
      },
      readable: {
        label: 'Leesbaar?',
      },
    },
  },
  template: {
    field: {
      name: { label: 'Naam' },
      file: { label: 'PDF' },
      type: {
        label: 'Type',
        value: {
          out_shipment: 'Pakbon',
        },
      },
      isDefault: { label: 'Standaard' },
    },
    overview: {
      title: 'Templates',
      empty: 'Er zijn nog geen templates toegevoegd.',
      addButton: 'Template',
    },
    create: {
      title: 'Template Aanmaken',
    },
    edit: {
      title: 'Template Aanpassen',
    },
    info:
      'In een Template kun je waardes die betrekking hebben tot het product terug laten komen. Je kunt aangeven waar een waarde moet komen door {{ te typen op de juiste plek in de template.',
  },
  globalFile: {
    edit: {
      title: 'Bijzonder Bestand Bewerken',
    },
    overview: {
      title: 'Bijzondere Bestanden',
    },
    field: {
      key: {
        label: 'Naam Voor Bestand',
      },
      value: {
        label: 'Bestand',
      },
      writable: {
        label: 'Schrijfbaar?',
      },
      readable: {
        label: 'Leesbaar?',
      },
    },
  },
  myFilter: {
    custom: 'Nieuw',
    action: {
      unsetDefault: 'Gebruik niet als standaard',
      setDefault: 'Gebruik als standaard',
      setCurrent: 'Sla huidige filters op',
      delete: 'Verwijderen',
    },
  },
  tooltips: {
    masquerade: 'Voordoen als',
    edit: 'Aanpassen',
    delete: 'Verwijderen',
    restore: 'Terugzetten',
    view: 'Bekijken',
    select: 'Selecteren',
    close: 'Sluiten',
  },
  form: {
    // Model
    edit: {
      addFieldButton: 'Veld Toevoegen',
    },
    field: {
      fields: {
        label: 'Velden',
        empty: 'Dit formulier is nog leeg.',
      },
    },
    // Form stuff
    yes: 'Ja',
    no: 'Nee',
    either: 'Beide',
    draftJs: {
      style: {
        'header-one': { label: 'H1' },
        'header-two': { label: 'H2' },
        'header-three': { label: 'H3' },
        'header-four': { label: 'H4' },
        'header-five': { label: 'H5' },
        'header-six': { label: 'H6' },
        blockquote: { label: 'Blockquote' },
        'unordered-list-item': { label: 'UL' },
        'ordered-list-item': { label: 'OL' },
        'code-block': { label: 'Code Block' },
        BOLD: { label: 'Bold' },
        ITALIC: { label: 'Italic' },
        UNDERLINE: { label: 'Underline' },
        CODE: { label: 'Monospace' },
      },
    },
    iconInput: {
      noneSelected: 'Geen icoon geselecteerd',
    },
    cancelButton: 'Annuleren',
    confirmButton: 'Bevestigen',
    continueButton: 'Doorgaan',
    deleteButton: 'Verwijderen',
    editButton: 'Bewerken',
    restoreButton: 'Herstellen',
    backButton: 'Terug',
    nextButton: 'Volgende',
    submitButton: 'Opslaan',
    saveButton: 'Opslaan',
    saveAndBackButton: 'Opslaan en Terug',
    addNote : 'Laat een notitie achter',
    addANoteNotification: 'U heeft een of meer instelpunten gewijzigd. Voeg een opmerking toe voordat u opslaat.',
    copyButton: 'Kopiëren',
    addButton: 'Toevoegen',
    printButton: 'Printen',
    clearButton: 'Wissen',
    exportButton: 'Export',
    downloadButton: 'Downloaden',
    applyButton: 'Toepassen',
    viewButton: 'Bekijken',
    bulkActionButton: 'Actie ({{count}})',
    confirmQuestion: 'Weet u zeker dat u door wilt gaan?',
    fileType: {
      pdf: 'PDF',
      image: 'IMG',
      xls: 'XLS',
      any: 'Anders',
      none: 'Nog geen bestand geüpload.',
    },
    multiPick: {
      searchPlaceholder: 'Selecteer…',
      selectedText: '$1 uit $2 geselecteerd',
      noneSelectedText: 'Geen geselecteerd',
      selectAllButton: 'Alle',
      selectNoneButton: 'Geen',
    },
    notifications: {
      saveSuccess: 'Succesvol opgeslagen',
      deleteSuccess: 'Succesvol verwijderd',
      saveError: 'Fout met opslaan ({{status}})',
      saveValError: 'Niet alle informatie is correct ingevuld',
      saveAuthError: 'Niet geauthorizeerd',
      newAppVersion: 'Nieuwe versie beschikbaar, klik om de pagina te verversen',
    },
    startDate: 'Startdatum',
    endDate: 'Einddatum',
    deleteConfirmation: 'Weet u zeker dat u {{name}} wilt verwijderen?',
    repeat: 'Herhaal',
    greaterThan: 'Hoger dan',
    lowerThan: 'Lager dan',
    greaterThanOrEqual: '>=',
    lowerThanOrEqual: '<=',
    duration: {
      hours: 'Uren',
      minutes: 'Minuten',
      seconds: 'Seconden',
    },
    cap: {
      remaining: '{{count}} over',
      tooMany: '{{count}} te veel',
    },
  },
  crash: {
    heading: 'Applicatie gecrasht',
    subHeading1: 'Onze excuses, maar de applicatie is gecrasht.',
    subHeading2: 'Ons team is op de hoogte gesteld.',
    reload: 'Herlaad pagina',
  },
  permissions: {
    heading: 'Geen gebruikers groepen',
    subHeading1: 'Uw account maakt geen deel uit van een groep.',
    subHeading2: 'Vraag uw beheerder u de juiste rechten te geven.',
  },
  daycy: {
    week: {
      label: 'Wk',
      number: '{{week}}',
      value: 'W{{week}} - {{year}}',
    },
    weekDay: {
      monday: 'Ma',
      tuesday: 'Di',
      wednesday: 'Wo',
      thursday: 'Do',
      friday: 'Vr',
      saturday: 'Za',
      sunday: 'Zo',
    },
    month: {
      january: 'Januari',
      february: 'Februari',
      march: 'Maart',
      april: 'April',
      may: 'Mei',
      june: 'Juni',
      july: 'Juli',
      august: 'Augustus',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'December',
    },
    period: {
      am: 'AM',
      pm: 'PM',
    },
  },
  iconModal: {
    header: 'Icoon Uitkiezen',
    filterPlaceholder: 'Zoeken...',
    category: {
      favorites: 'Favorieten',
      accessibility: 'Toegangelijkheid',
      arrows: 'Pijlen',
      audioVideo: 'Audio/Video',
      business: 'Business',
      chess: 'Schaken',
      code: 'Code',
      communication: 'Communicatie',
      computers: 'Computers',
      currency: 'Valuta',
      dateTime: 'Datum/Tijd',
      design: 'Design',
      editors: 'Tekstverwerking',
      files: 'Bestanden',
      genders: 'Geslachten',
      handsGestures: 'Handgebaren',
      health: 'Gezondheid',
      images: 'Afbeeldingen',
      interfaces: 'Interfaces',
      logistics: 'Logistiek',
      maps: 'Kaarten',
      medical: 'Medisch',
      objects: 'Objecten',
      paymentsShopping: 'Betalingen/Shoppen',
      shapes: 'Vormen',
      sports: 'Sport',
      status: 'Status',
      usersPeople: 'Mensen',
      vehicles: 'Voertuigen',
      writing: 'Schrijven',
    },
  },
  exact: {
    ok: {
      title: 'Exact Koppeling Succesvol',
      body: 'Exact Online is nu succesvol gekoppeld met het systeem.',
    },
    error: {
      title: 'Exact Koppeling Onsuccesvol',
      body: {
        invalid_request: 'Onjuiste request verstuurd naar Exact Online.',
        invalid_client: 'Onjuiste client verstuurd naar Exact Online.',
        invalid_grant: 'Onjuiste grant verstuurd naar Exact Online.',
        invalid_scope: 'Onjuiste scope verstuurd naar Exact Online. ',
        unauthorized_client: 'Ongeauthorizeerde client verstuurd naar Exact Online.',
        unsupported_grant_type: 'Niet ondersteund grant type verstuurd naar Exact Online.',
      },
    },
  },
  unit4: {
    ok: {
      title: 'Unit 4 Koppeling Succesvol',
      body: 'Unit 4 is nu succesvol gekoppeld met het systeem.',
    },
    error: {
      title: 'Unit 4 Koppeling Onsuccesvol',
      body: {
        invalid_request: 'Onjuiste request verstuurd naar Unit 4.',
        invalid_client: 'Onjuiste client verstuurd naar Unit 4.',
        invalid_grant: 'Onjuiste grant verstuurd naar Unit 4.',
        invalid_scope: 'Onjuiste scope verstuurd naar Unit 4. ',
        unauthorized_client: 'Ongeauthorizeerde client verstuurd naar Unit 4.',
        unsupported_grant_type: 'Niet ondersteund grant type verstuurd naar Unit 4.',
      },
    },
  },
  warehouse: {
    stock: 'Voorraad',
    inbound: 'Inbound',
    outbound: 'Outbound',
    field: {
      code: { label: 'Code' },
      name: { label: 'Naam' },
    },
  },
  step: {
    edit: {
      step: 'Stap {{index}}',
      start: 'Begin',
      end: 'Eind',
      addSectionButton: 'Onderdeel Toevoegen',
    },
    field: {
      newBatchSerialNumberFormat: { label: 'Format nieuw serienummer' },
      label: { label: 'Label' },
      type: {
        value: {
          print: 'Print Stap',
          form: 'Formulier Stap',
          split: 'Batch Stap',
          multiplier: 'Meerdere Keren Stap ',
          grow: 'Groei Stap',
          subprocesses: 'Subprocessen',
        },
        valueStep: {
          print: 'Print Stap',
          form: 'Formulier Stap',
          split: 'Batch Stap',
          multiplier: 'Meerdere Keren Stap',
          grow: 'Groei Stap',
          subprocesses: 'Subprocessen',
        },
      },
      sections: {
        label: 'Beschrijving',
        empty: 'Deze beschrijving is nog leeg.',
      },
      workStation: { label: 'Werkplek' },
      workMinutes: { label: 'Geschatte Werktijd In Minuten' },
      capabilities: { label: 'Benodigde Vaardigheden' },
      workTempoManual: {
        label: 'Werktempo (Handmatig)',
        unit: 'in planten / uur',
      },
      workTempoAutomatic: {
        label: 'Werktempo (Automatisch)',
        unit: 'in planten / uur',
      },
      operatorSupervision: {
        label: 'Operator Supervisie Vereist',
      },
    },
  },
  printStep: {
    field: {
      type: {
        label: 'Printtype',
        value: {
          label: 'Label',
          document: 'Document',
        },
      },
      copies: { label: 'Standaard Aantal Kopieën' },
      labelPrinter: {
        label: 'Printer',
        value: {
          altec: 'Altec',
          zebra: 'Zebra/Toshiba',
        },
      },
      labelTemplate: {
        label: 'Template',
        keys: {
          article_type_name: 'De naam van het artikel.',
          article_type_code: 'De code van het artikel.',
          warehouse_name: 'De naam van het magazijn.',
          warehouse_code: 'De code van het magazijn.',
          article_type_gross_weight: 'Het bruto gewicht van het artikel.',
          warehouse_locations: 'De locaties van het magazijn.',
          serial_number: 'Het serienummer van het product.',
          batch_size: 'Batch grootte van het product.',
          date: 'De datum op het moment van printen.',
          time: 'De tijd op het moment van printen.',
          shipment_lines: 'Overzicht van pakbon.',
          operator_badge_id: 'Operator badge ID.',
          operator_name: 'Operator naam.',
          order_id: 'Order ID.',
          erp_order_id: 'ERP order ID.',
          customer_name: 'Order klant naam.',
          supplier_name: 'Order leverancier naam.',
          reference: 'Order referentie.',
          remarks: 'Order opmerkingen',
          delivery_address: 'Order verzendadres.',
          delivery_date: 'Order verzenddatum.',
          receipt_date: 'Order ontvangstdatum',
          quantity: 'Order hoeveelheid',
          sales_order_line_description: 'Verkooporderlijn beschrijving',
          purchase_order_line_description: 'Inkooporderlijn beschrijving',
          amount_ex_vat: 'Bedrag Exclusief btw',
          shipping_method_description: 'Verzendmethode Beschrijving',
        },
      },
      documentBackground: { label: 'Achtergrond' },
      documentOverlays: {
        label: 'Template',
        template: 'Template',
        addPage: 'Pagina',
      },
      defaultPrinter: { label: 'Standaard Printer' },
    },
  },
  multiplierStep: {
    field: {
      multiplier: { label: 'Aantal Keer' },
    },
  },
  formStep: {
    field: {
      form: { label: 'Formulier' },
      icon: { label: 'Icoon' },
      optional: { label: ' (Optioneel)' },
    },
    perform: {
      between: 'Tussen {{min}} en {{max}}',
    },
  },
  splitStep: {
    field: {
      form: { label: 'Formulier' },
      icon: { label: 'Icoon' },
      newBatchQuantity: {
        label: 'Nieuwe Batchgrootte',
        info: 'Een container bevat een batch met X aantal planten. X is in dit geval de batchgrootte.',
      },
      newBatchVariableQuantity: {
        label: 'Variabel',
        info: 'Grootte is een verwachte grootte, de daadwerkelijke grootte kan variëren.',
      },
      newBatchVariableQuantityPredetermined: {
        label: 'Variabel Voorbepaald',
        info: 'De variable grootte is voorbepaald, dit maakt het mogelijk voor de grootte om de stap zelf te beinvloeden, bijvoorbeeld voor sommige formulier velden of hoeveel je moet scannen.',
      },
      newBatchVariableUseOrderSize: {
        label: 'Bestelgrootte gebruiken?',
        info: 'De verwachte hoeveelheid is variabel, hierdoor kan het hoeveelheidveld een standaardwaarde van de ordergrootte hebben.',
      },
      printer: { label: 'Printer' },
      template: { label: 'Template' },
      copies: { label: 'Standaard Aantal Kopieën' },
      type: {
        label: 'Identificatietype',
        value: {
          scan: 'Scan',
          print: 'Print',
          article: 'Artikel',
          provided: 'Opgegeven serienummer',
        },
        tooltip: {
          scan:
            "Bij het identificatietype 'Scan' wordt de batch in een ladingdrager geladen die vervolgens gescand wordt door de operator.",
          print: "Bij het identificatietype 'Print' wordt er een label geprint met het serienummer van de batch. Wanneer je geen formuliervelden toevoegt aan deze batch stap, kun je via de printall functionaliteit printen.",
          article:
            "Bij het identificatietype 'Artikel' moet er tenminste één artikel gekoppeld worden waarvan het serienummer gebruikt kan worden.",
          provided: "Bij identificatie door middel van 'Opgegeven serienummer' wordt door de operator het serienummer verstrekt waarmee de partij dient te worden geïdentificeerd. Dit moet nog steeds overeenkomen met het nieuwe serienummerformaat dat hierboven is gedefinieerd.",
        },
      },
      defaultPrinter: { label: 'Standaard Printer' },
    },
  },
  formStepField: {
    field: {
      label: { label: 'Label' },
      type: {
        label: 'Type',
        value: {
          text: 'Text',
          check: 'Check',
          choice: 'Keuze',
          article_type: 'Artikeltype',
          measure: 'Meting',
          format: 'Format',
          variable: 'Variabele',
          image: 'Afbeelding',
          bom: 'Stuklijst',
          quantity: 'Grootte',
          storage_location: 'Opslaglocatie'
        },
      },
      target: { label: 'Details' },
      articleType: { label: 'Artikeltype' },
      slug: {
        label: 'Print Referentie',
        info:
          'Deze waarde wordt gebruikt om te refereren naar dit veld vanuit print templates. Kan enkel de tekens A-Z, a-z, 0-9 en _ bevatten en mag niet beginnen met een cijfer.',
      },
      storageLocations: { label: 'Opslaglocatie(s) (Hoeveelheid)' },
    },
  },
  batch: {
    batchInfoModal: {
      trigger: 'Batch Info',
    },
    overview: {
      title: 'Producten',
      empty: 'Er zijn geen producten die voldoen aan uw zoekopdracht.',
    },
    field: {
      batchType: { label: 'Type' },
      productionRequest: { label: 'Werkorder ID' },
      inShipment: { label: 'Ontvangstorder ID' },
      outShipment: { label: 'Verzamelorder ID' },
      warehouseTransfer: { label: 'Voorraadverplaatsing ID' },
      purchaseOrderNumberERP: { label: 'Inkooporder ERP' },
      salesOrderNumberERP: { label: 'Verkooporder ERP' },
      productionOrderNumberERP: { label: 'Productieorder ERP' },
      orderNumberERP: { label: 'ERP Order' },
      batchUseds: {
        label: 'Hoofdproduct',
        empty: 'Dit product heeft geen verdere Hoofdproducten.',
      },
      batchUsings: {
        label: 'Subassemblages',
        empty: 'Dit product heeft geen subassemblages.',
      },
      details: {
        label: 'Details',
        empty: 'Dit product heeft geen details.',
      },
      performances: {
        label: 'Uitgevoerde Stappen',
        empty: 'Dit product heeft geen uitgevoerde stappen.',
        description: '{{step}} uitgevoerd door {{operator}} op {{createdAt}}',
        printedDocument: 'Gedrukt document',
      },
      serialNumber: { label: 'Serienummer' },
      finished: { label: 'Klaar' },
      scrapped: { label: 'Afgekeurd' },
      loadCarrier: { label: 'Ladingdrager' },
      quantityRemaining: { label: 'Aantal Over' },
      storageLocation: { label: 'Opslaglocatie' },
    },
  },
  subassembly: {
    overview: {
      title: 'Subassemblages',
      empty: 'Er zijn geen subassemblages die voldoen aan uw zoekopdracht.',
    },
    field: {
      parentBatch: { label: 'Hoofdproduct' },
      parentSubassembly: { label: 'Hoofdsubassemblage' },
      subassemblies: {
        label: 'Subassemblages',
        empty: 'Deze subassemblage heeft geen subassemblages.',
      },
      details: {
        label: 'Details',
        empty: 'Deze subassemblage heeft geen details.',
      },
      performances: {
        label: 'Uitgevoerde Stappen',
        empty: 'Deze subassemblage heeft geen uitgevoerde stappen.',
        description: '{{step}} uitgevoerd door {{operator}} op {{createdAt}}',
      },
      serialNumber: { label: 'Serienummer' },
      finished: { label: 'Klaar' },
    },
  },
  progress: {
    title: 'Voortgangsoverzicht',
    empty: 'Er zijn nog geen productieopdrachten voor deze week.',
    processType: {
      label: 'Process Type',
      value: {
        make: 'Hoofdproces Maak',
        buy: 'Subproces Ontvangst',
        sell: 'Subproces Verzamel',
        transfer_line: 'Subproces Verplaatsingsregel',
        stock_count: 'Hoofdproces Voorraadtelling',
        transfer: 'Hoofdproces Verplaatsing',
        pick_order: 'Hoofdproces Verzamel',
        receive_order: 'Hoofdproces Ontvangst',
      },
    },
    productionLine: {
      stat: {
        todo: 'Te Doen',
        inProgress: 'Bezig',
        done: 'Klaar',
        operators: 'Operator',
        operators_plural: 'Operators',
      },
    },
    weekPicker: 'Week',
    monthPicker: 'Maand',
    dayPicker: 'Dag',
    processVersion: 'v{{version}}',
    groupBy: {
      label: 'Groeperen Op',
      value: {
        workStation: 'Werkplek',
        step: 'Stap',
      },
    },
  },
  detail: {
    field: {
      value: { label: 'Waarde' },
      reason: { label: 'Reden' },
    },
  },
  targetInfoModal: {
    scrapped: 'Afgekeurd: {{reason}}',
    variableQuantity: 'Variabele Grootte',
    reprint: {
      title: 'Herprint',
      quantity: 'Aantal Kopieën',
      printButton: 'Print',
    },
  },
  serialNumberFormat: {
    part: {
      text: {
        label: 'Tekst',
        content: 'Inhoud',
      },
      date: {
        label: 'Datum',
        part: {
          label: 'Onderdeel',
          year: { label: 'Jaar' },
          month: {
            label: 'Maand',
            text: 'Tekst',
            names: {
              january: {
                label: 'Januari',
                value: 'JAN',
              },
              february: {
                label: 'Februari',
                value: 'FEB',
              },
              march: {
                label: 'Maart',
                value: 'MAA',
              },
              april: {
                label: 'April',
                value: 'APR',
              },
              may: {
                label: 'Mei',
                value: 'MEI',
              },
              june: {
                label: 'Juni',
                value: 'JUN',
              },
              july: {
                label: 'Juli',
                value: 'JUL',
              },
              august: {
                label: 'August',
                value: 'AUG',
              },
              september: {
                label: 'September',
                value: 'SEP',
              },
              october: {
                label: 'Oktober',
                value: 'OKT',
              },
              november: {
                label: 'November',
                value: 'NOV',
              },
              december: {
                label: 'December',
                value: 'DEC',
              },
            },
          },
          day: { label: 'Dag' },
          isoyear: { label: 'ISO Jaar' },
          isoweek: { label: 'ISO Week' },
          isoweekday: {
            label: 'ISO Weekdag',
            text: 'Tekst',
            names: {
              monday: {
                label: 'Maandag',
                value: 'MA',
              },
              tuesday: {
                label: 'Dinsdag',
                value: 'DI',
              },
              wednesday: {
                label: 'Woensdag',
                value: 'WO',
              },
              thursday: {
                label: 'Donderdag',
                value: 'DO',
              },
              friday: {
                label: 'Vrijdag',
                value: 'VR',
              },
              saturday: {
                label: 'Zaterdag',
                value: 'ZA',
              },
              sunday: {
                label: 'Zondag',
                value: 'ZO',
              },
            },
          },
        },
        format: 'Format',
      },
      code: {
        label: 'Code',
        alphabet: 'Alfabet',
        digits: 'Aantal',
        expand: 'Uitbreiden',
        defaultAlphabets: {
          decimal: 'Dec',
          binary: 'Bin',
          hexadecimalLower: 'Hex (a)',
          hexadecimalUpper: 'Hex (A)',
          octal: 'Oct',
          alphanumericLower: 'Alfanum (a)',
          alphanumericUpper: 'Alfanum (A)',
          alphabeticLower: 'Alfa (a)',
          alphabeticUpper: 'Alfa (A)',
          custom: 'Anders, namelijk:',
        },
      },
      anything: {
        label: 'Alles',
      },
    },
  },
  performanceResetModal: {
    title: 'Product Terugzetten',
    reason: 'Reden',
    resetButton: 'Terugzetten',
  },
  textPart: {
    field: {
      text: {
        placeholder: 'Geen tekst ingevuld.',
      },
    },
  },
  imagePart: {
    field: {
      subtitle: {
        placeholder: 'Geen ondertitel ingevuld.',
      },
    },
  },
  printerSetup: {
    connectionError: 'Error tijdens het verbinden met de printer, ',
    connectionNameError: 'Error tijdens het verbinden met de printer, deze printer naam is niet bekend',
    connectionPdfError: 'Error tijdens het ophalen van het verstuur label',
    title: 'Printer Setup',
    installQzTray: {
      beforeLink: 'Installeer ',
      link: 'QZ Tray',
      afterLink: '.',
    },
    downloadCertificate: {
      beforeLink: 'Download ',
      link: 'dit bestand',
      beforeLocation: ' en sla het op als ',
      afterLocation: '.',
    },
    editProperties: {
      beforeLocation: 'Voeg de volgende regel toe aan eind van het bestand ',
      afterLocation: ':',
    },
    testPrinter: {
      beforeButtons: 'Test of de printer werkt:',
      printer: {
        altec: 'Altec',
        zebra: 'Zebra',
      },
    },
    printerModal: {
      title: 'Test Print',
      selectPrinter: 'Selecteer Printer',
      noPrintersAvailable: 'Geen Printers Beschikbaar',
      printButton: 'Print',
    },
  },
  workSlot: {
    field: {
      monday: { label: 'Ma' },
      tuesday: { label: 'Di' },
      wednesday: { label: 'Wo' },
      thursday: { label: 'Do' },
      friday: { label: 'Vr' },
      saturday: { label: 'Za' },
      sunday: { label: 'Zo' },
    },
  },
  workCalendar: {
    scope: {
      type: {
        week: { label: 'Week' },
        month: { label: 'Maand' },
      },
    },
    slot: {
      planned: 'Ingepland',
      overtime: 'Overgewerkt',
      absent: 'Afwezig',
    },
    leaveSlotModal: {
      title: {
        add: 'Verlof Toevoegen',
        edit: 'Verlof Aanpassen',
      },
    },
    workTimesModal: {
      title: 'Werktijden {{scope}}',
    },
    total: {
      planned: '{{time}} gepland',
      actual: '{{time}} gewerkt',
      overtime: '{{time}} overgewerkt',
      absence: '{{time}} afwezig',
    },
  },
  leaveSlot: {
    field: {
      window: { label: 'Periode' },
      name: { label: 'Reden' },
      fullDay: { label: 'Volledige Dag' },
      type: {
        label: 'Type',
        value: {
          vacation: 'Vakantie',
          rwh: 'ADV',
          special: 'Bijzonder',
        },
        valueShort: {
          vacation: 'VAK',
          rwh: 'ADV',
          special: 'BIJ',
        },
        valueMinutes: {
          vacation: 'Vakantie',
          rwh: 'ADV',
          special: 'Bijzondere Verlof',
        },
      },
      status: {
        label: 'Status',
        value: {
          approved: 'Goedgekeurd',
          pending: 'In afwachting',
          rejected: 'Afgekeurd',
        },
      },
      startDate: { label: 'Startdatum' },
      endDate: { label: 'Einddatum' },
    },
    overview: {
      title: 'Verlofaanvragen',
      noneFound: 'Er zijn geen verlofaanvragen gevonden.',
      noneSelected: 'Er is nog geen verlofaanvraag geselecteerd.',
      hasComments: 'Heeft opmerkingen',
      availabilityHeader: 'Beschikbare Operator-uren',
      statusAction: {
        approved: 'Goedkeuren',
        pending: 'In behandeling nemen',
        rejected: 'Afkeuren',
      },
    },
    edit: {
      comments: {
        empty: 'Er zijn nog geen opmerkingen geplaatst.',
      },
      stats: {
        allowed: 'Totaal',
        used: 'Gebruikt',
        available: 'Beschikbaar',
        requested: 'Aangevraagd',
      },
      days: {
        legend: {
          full: 'Volledige Beschikbaarheid',
          approved: 'Indien Goedgekeurd',
          rejected: 'Indien Afgekeurd',
        },
      },
      legal: 'Wettelijk',
      extra: 'Bovenwettelijk',
    },
  },
  leaveCalendar: {
    overview: {
      title: 'Verlofkalenders',
      empty: 'Er zijn geen verlofkalenders die voldoen aan uw zoekopdracht.',
      addButton: 'Verlofkalender',
    },
    create: {
      title: 'Verlofkalender Aanmaken',
    },
    edit: {
      title: 'Verlofkalender Aanpassen',
      saveBeforeLeaveSlot: 'Je moet de verlofkalender eerst opslaan voordat je verlof toe kan voegen.',
    },
    field: {
      name: { label: 'Naam' },
      users: { label: 'Users' },
      operators: { label: 'Operators' },
      leaveSlots: { label: 'Verlof' },
    },
  },
  operatorCapability: {
    field: {
      level: {
        label: 'Level',
        value: {
          1: 'Niet vaardig',
          2: 'Lerend',
          3: 'Vaardig',
          4: 'Expert',
        },
      },
    },
  },
  process: {
    overview: {
      title: 'Processen',
      addButton: 'Proces',
      empty: 'Er zijn geen processen die voldoen aan uw zoekopdracht.',
    },
    edit: {
      newBatch: {
        label: 'Nieuwe Batch',
        info: 'Deze stap uitvoeren zal een nieuwe batch aanmaken, deze velden geven enkele details over deze batch.',
      },
      versioned: {
        label: '(versies)',
        info: 'Deze waarde is alleen van toepassing op de huidig geselecteerde versie.',
      },
      baseFields: {
        label: 'Basis Velden',
        info: 'Deze velden behoren niet tot een versie maar zijn toepasselijk op elke versie.',
      },
      batchSize: {
        label: 'Batch Grootte',
        value: {
          productionRequest: 'Order Grootte',
          unit: 'Eenheid',
          batch: 'Batch van {{count}}',
        },
        error: {
          notSmaller: 'Batchgrootte moet kleiner zijn dan de voorgaande.',
          notFinal: 'Batchgrootte moet gelijk zijn aan {{expected}}.',
        },
      },
      title: 'Proces Aanpassen',
      version: 'Versie {{version}}',
      finalized: {
        label: 'Afgerond op: {{date}}',
        by: 'Afgerond door: {{user}}',
      },
      updated: {
        label: 'Bijgewerkt op: {{date}}',
        by: 'Bijgewerkt door: {{user}}',
      },
      noUpdateInfo: 'Geen informatie beschikbaar over wanneer deze versie voor het laatst is bijgewerkt.',
      draft: 'Concept',
      finalizeButton: 'Afronden',
      nextVersionButton: 'Volgende Versie',
      noStepSelected: 'Geen stap geselecteerd',
      noBatchTypeSelected: 'Geen proces geselecteerd',
      workStationsModal: {
        title: 'Selecteer Werkplekken',
      },
      tabs: {
        steps: 'Stappen',
        instructions: 'Instructies',
        articleTypes: 'Artikeltypes',
      },
      addArticleTypeModal: {
        title: 'Artikeltypes Toevoegen',
        addButton: 'Toevoegen ({{count}})',
      },
      harvestDay: {
        monday: 'Ma',
        tuesday: 'Di',
        wednesday: 'Wo',
        thursday: 'Do',
        friday: 'Vr',
        saturday: 'Za',
        sunday: 'Zo',
      },
    },
    create: {
      title: 'Proces Aanmaken',
    },
    field: {
      name: { label: 'Name' },
      newestVersion: { label: 'Nieuwste Versie' },
    },
  },
  processVersion: {
    field: {
      productionLineVersion: { label: 'Productielijn' },
      batchType: { label: 'Proces' },
      version: { label: 'Versie' },
      newest: { label: 'Nieuwste' },
      factory: { label: 'Locatie' },
      environmentScript: { label: 'Klimaatscript' },
      lightScript: { label: 'Lichtscript' },
      nutritionStorageScript: { label: 'Voedingopbergscript' },
      nutritionIrrigationScript: { label: 'Voedingirrigatiescript' },
    },
  },
  dataSource: {
    edit: {
      title: 'Databron Aanpassen',
    },
    create: {
      title: 'Databron Aanmaken',
    },
    overview: {
      title: 'Databronnen',
      empty: 'Er hebben zich nog geen databronnen aangemeld.',
    },
    field: {
      uuid: { label: 'UUID' },
      identifier: { label: 'Identificatiecode' },
      name: { label: 'Naam' },
      deviceName: { label: 'Apparaatnaam' },
      description: { label: 'Omschrijving' },
      workStations: { label: 'Gekoppelde werkplekken' },
      data: {
        label: 'Data',
        name: 'Naam',
        type: 'Type',
      },
    },
  },
  exactShopOrder: {
    field: {
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          released: 'Vrijgegeven',
          in_progress: 'In Bewerking',
          finalized: 'Afgerond',
          complete: 'Compleet',
        },
      },
    },
  },
  loadCarrier: {
    overview: {
      title: 'Ladingdragers',
      empty: 'Er zijn geen ladingdragers die voldoen aan uw zoekopdracht.',
      addButton: 'Ladingdrager',
      batchAddButton: 'Batch Toevoegen',
    },
    create: {
      title: 'Ladingdrager Aanmaken',
    },
    edit: {
      title: 'Ladingdrager Aanpassen',
      weighing: '{{weight}}{{unit}}, gewogen door {{user}} op {{time}}.',
      unknownUser: 'onbekend',
    },
    batchAdd: {
      title: 'Ladingdragers Batch Toevoegen',
      quantity: 'Aantal',
      serialNumberFormat: 'Serienummer Format',
    },
    field: {
      type: {
        label: 'Type',
        value: {
          pallet_euro: 'Europallet',
          pallet_pool: 'Poolpallet',
          container: 'Container',
          gitterbox: 'Gitterbox',
          carton_box: 'Kartonnen Doos',
          stackbox: 'Stapel Doos',
        },
      },
      serialNumber: { label: 'Serienummer' },
      name: { label: 'Naam' },
      description: { label: 'Beschrijving' },
      weight: { label: 'Gewicht' },
      width: { label: 'Breedte' },
      height: { label: 'Hoogte' },
      length: { label: 'Lengte' },
      volume: { label: 'Volume' },
      picture: { label: 'Foto' },
      batch: { label: 'Batch' },
      hasBatch: { label: 'Heeft Batch' },
      weighings: { label: 'Wegingen' },
      reweighDays: {
        label: 'Weging Interval',
        info: 'Hier kun je aangeven hoe vaak een ladingdrager gewogen moet worden. In het ladingdrager overzicht kun je dan zien wanneer een ladingdrager opnieuw gewogen moet worden en filteren op ladingdragers die gewogen moeten worden.',
        suffix: ' dagen',
      },
      reweighDate: { label: 'Herweegdatum' },
      needsReweighing: { label: 'Moet Herwogen Worden' },
    },
  },
  login: {
    workstation: 'Werkstation code',
    barcode: 'Artikel barcode',
    title: 'Tracy Picker',
    username: 'Gebruiker',
    batchcode: 'Batchcode',
    notAllowed: 'Dit is geen geldige werkstation code',
    password: 'Wachtwoord',
    forgotPassword: 'Wachtwoord vergeten?',
    clearusername: 'Wissen gebruiker',
    clearworkstation: 'Wissen werkstation code',
    clearprinter: 'Printer gegevens wissen',
    forgotMailSend: 'Email verstuurd',
    successful: 'Succesvol ingelogd',
    logIn: 'Log In',
    error: 'Fout bij inloggen',
    wrong_credentials: 'Geen geldige badge code',
  },
  shipping: {
    picture: 'Foto van de lading',
    picture_accept: 'Accepteer foto',
    picture_decline: 'Foto opnieuw nemen',
    packed: 'Verpakken afronden',
    start_send: 'Start verzendproces',
    start_receive: 'Start ontvangstproces',
    send_shipment: 'Verstuur de levering',
    receive_shipment: 'Ontvang levering',
  },
  articleInfo: {
    clearBarcode: 'Barcode wissen',
    search: 'Zoek naar barcode',
    wrongCode: 'Kon barcode niet vinden in het system',
    notAllowed: 'Deze gebruiker mag dit product niet bekijken',
    error: 'Fout tijdens het zoeken naar de barcode',
  },
  shipment: {
    articleInfo: {
      performanceLine:
        '{{stepLabel}} van process {{productionLineName}} uitgevoerd om {{performanceTime}} door {{performanceBy}}',
      batchType: 'Batch Type',
      batchDescription: 'Batch Omschrijving',
      batchSize: 'Batch Grootte',
      articleNameAndCode: 'Artikel naam en Exact code',
      scannedBarcode: 'Gescande Barcode',
    },
    picker: {
      default: 'Selecteer een waarde',
    },
    shipmentInfo: 'Order informatie',
    status: {
      picking: 'Picking',
      printing: 'Printen',
      sending: 'Versturen',
      sent: 'Verzonden',
      toReceive: 'Te Ontvangen',
      received: 'Ontvangen en verwerkt',
    },
    field: {
      salesOrder: {
        label: 'Verkooporder',
      },
      purchaseOrder: { label: 'Inkooporder' },
      receiveDate: { label: 'Geplande leverdatum' },
      receivedTime: { label: 'Levertijd' },
      shippingDate: {
        label: 'Verstuur datum',
      },
      freightType: {
        label: 'Vrachtsoort',
        value: {
          road: 'Land',
        },
      },
      freightCompany: {
        label: 'Transportbedrijf',
      },
      shippingName: {
        label: 'Naam Chauffeur',
      },
      shippingLicense: {
        label: 'Kenteken Vrachtwagen',
      },
      shippingTime: {
        label: 'Tijd van Versturen',
      },
      quantity: {
        label: 'Aantal',
      },
      batchSize: {
        label: 'Batch Grootte',
      },
    },
    outbound: {
      pickitem: 'Verzamel artikelen',
      printreceipt: 'Afdrukken pakbon',
      package: 'Verpakken',
      printshipmentlabel: 'Afdrukken verzendetiket',
      send: 'Versturen',
    },
    inbound: {
      receiveItem: 'Ontvang bestelling',
      barcodeCreation: 'Identificeren producten',
      warehousePlacement: 'Plaatsing in het magazijn',
    },
    heading: {
      cmrLines: 'CMR LINES',
    },
    titleInbound: 'Ontvangstorder {{id}}',
    titleOutbound: 'Verzendorder {{id}}',
    confirmShipment: 'Weet je zeker dat je {{custName}} een zending wilt sturen met ordernummer {{trackingNumber}}?',
    confirmReceive:
      'Weet je zeker dat je van {{custName}} een zending wilt accepteren met ordernummer {{trackingNumber}}?',
    sendShipmentTitle: 'Versturen',
    receiveShipmentTitle: 'Ontvangen',
    scan: 'Scan',
    leftToPick: 'Te picken',
    actualyPicked: 'Picked',
    documents: {
      label: 'Documenten',
      packingListRequired: 'Pakbon',
      invoiceRequired: 'Factuur',
      eur1Required: 'EUR1',
      cvoRequired: 'C/O',
    },
    tab: {
      shipmentLines: 'Artikelen',
      info: 'Info',
    },
    noCmrLines: 'Deze verzending heeft geen verzendregels.',
    box: 'Doos {{id}}',
    buyBox: 'Doos',
    hiddenBoxes: '{{count}} Dozen',
    error: {
      wrongArticle: 'Verkeerd artikel.',
      alreadyScanned: 'Doos al gescand.',
      alreadyShipped: 'Doos al gebruikt in andere order.',
      tooManyProducts: 'Deze doos bevat teveel producten.',
      invalidQuantity: 'Niet bruikbare hoeveelheid voor dit aantal.',
    },
  },
  common: {
    article: {
      field: {
        code: { label: 'Code' },
        name: { label: 'Naam' },
      },
    },
    shipmentLine: {
      field: {
        quantity: { label: 'Aantal' },
      },
    },
    shipment: {
      field: {
        salesOrder: { label: 'Verkooporder' },
        purchaseOrder: { label: 'Inkooporder' },
        shippingDate: { label: 'Verzenddatum' },
        receiveDate: { label: 'Geplande Leverdatum' },
        shippingName: { label: 'Naam' },
        shippingTime: { label: 'Verzendtijd' },
        receivedTime: { label: 'Ontvangsttijd' },
        shippingLicense: { label: 'Kenteken' },
        freight: {
          label: 'Vrachtsoort',
          value: {
            road: 'Weg',
            air: 'Lucht',
            sea: 'Zee',
            parcel: 'Express',
          },
        },
        freightCompany: { label: 'Transporteur' },
      },
    },
    cmrLine: {
      field: {
        mark: { label: 'Regel' },
        number: { label: 'Nummmer' },
        method: { label: 'Methode' },
        nature: { label: 'Aard' },
        weight: { label: 'Gewicht' },
        cubage: { label: 'Inhoud' },
      },
    },
    loading: 'Laden...',
    error: {
      somethingWentWrong: 'Er ging iets verkeerd.',
    },
  },
  workTime: {
    field: {
      date: { label: 'Datum' },
      startTime: { label: 'Begintijd' },
      endTime: { label: 'Eindtijd' },
      automaticallyClockedOut: { label: 'Automatisch uitgeklokt' },
    },
  },
  softdeleted: {
    label: 'Verwijderde items tonen',
    value: {
      true: 'ja',
      false: 'nee',
      only: 'alleen',
    },
  },
  logistics: {
    erpCounts: {
      header: {
        date: 'Datum',
        quantity: '#',
      },
    },
  },
  extraLeaveBalance: {
    field: {
      year: { label: 'Van toepassing op (jaar)' },
      legalLeaveMinutes: { label: 'Wettelijk' },
      extraLeaveMinutes: { label: 'Bovenwettelijk' },
      rwhMinutes: { label: 'ADV' },
    },
  },
  formField: {
    field: {
      slug: { label: 'Print slug' },
      articleType: {
        self: '(Dynamisch)',
        selfInfo: 'Hierbij wordt automatisch het artikeltype ingevuld waar deze template gebruikt wordt.',
      },
      type: {
        quantityInfo: 'Dit type kan gebruikt worden om een precieze grootte te zetten op een variabele batch. Je kunt ook een gewicht per eenheid ingeven, in dit geval zal de grootte berekend worden aan de hand van een gewicht dat de operator invoert.',
      },
      measureMin: { label: 'Minimum' },
      measureMax: { label: 'Maximum' },
      quantityUnitWeight: { label: 'Gewicht per eenheid' },
      articleTypeBatchSize: { label: 'Batchmaat?' },
      required: { label: 'Vereist' },
    },
  },
  integration: {
    overview: {
      title: 'Integraties',
      empty: 'Geen integraties gevonden.',
      clearSyncErrorButton: 'Verwijder synchronisatie fouten',
      addButton: 'Integratie',
      inactive: 'Deze integratie is niet actief.',
    },
    create: {
      title: 'Integratie Aanmaken',
    },
    edit: {
      title: 'Integratie Aanpassen',
    },
    field: {
      type: {
        label: 'Type',
        value: {
          exact: 'Exact',
          unit4: 'Unit 4',
          navision: 'Navision'
        },
      },
      name: { label: 'Naam' },
      syncErrorCount: { label: 'Synchronisatie Fouten' },
    },
    syncmodal: {
      title: 'Synchroniseer',
      syncButton: 'Sync',
      pending: 'In wachtrij',
      from_db: 'Cached',
      from_inline: 'Gedeeltelijk',
      created: 'Aangemaakt',
      updated: 'Bijgewerkt',
      requested: 'Aangevraagd',
      dependencies: 'Gelinkte objecten',
      measureMin: { label: 'Min' },
      measureMax: { label: 'Max' },
      quantityUnitWeight: { label: 'Gewicht per Eenheid' },
    },
    syncers: {
      exact_account_syncer: 'Accounts',
      exact_address_syncer: 'Addressen',
      exact_item_syncer: 'Items',
      exact_project_syncer: 'Projecten',
      exact_purchase_order_syncer: 'Inkooporders',
      exact_sales_order_syncer: 'Verkooporders',
      exact_warehouse_syncer: 'Magazijnen',
      exact_storage_location_syncer: 'Opslaglocaties',
      exact_item_warehouse_syncer: 'Voorraden (per magazijn)',
      exact_item_warehouse_location_syncer: 'Voorraden (per locatie)',
      exact_goods_receipt_syncer: 'Inkoopbonnen',
      exact_warehouse_transfer_syncer: 'Magazijnverplaatsingen',
      exact_stock_position_syncer: 'Voorraad update',
      exact_shipping_method_syncer: 'Verzendmethodes',
      exact_document_syncer: 'Documenten',
    },
  },
  navisionIntegration: {
    field: {
      company: {label: 'Entiteit'},
      customer: {label: 'Klanten'},
      item: {label: 'Items'},
      jobs: {label: 'Jobs'},
      manageEndpointsHeader: {label: 'Configuratie'},
      productionOrder: {label: 'Productieorders'},
      purchaseOrder: {label: 'Inkooporders'},
      salesOrder: {label: 'Verkooporders'}
    },
    purchaseOrderLine: {
      field: {
        requestedReceiptDate: 'Gevraagde datum',
        promisedReceiptDate: 'Beloofde datum',
      }
    },
    overview: {
      syncButton: 'Sync'
    }
  },
  exactIntegration: {
    overview: {
      authButton: 'Activeer Exact Integratie',
      authResult: {
        ok: 'Exact integratie succesvol geactiveerd.',
        error: {
          invalid_request: 'Onjuist verzoek verzonden naar Exact.',
          invalid_client: 'Onjuiste client verzonden naar Exact.',
          invalid_grant: 'Onjuiste grant verzonden naar Exact.',
          invalid_scope: 'Onjuiste scope verzonden naar Exact. ',
          unauthorized_client: 'Ongeauthorizeerde client verzonden naar Exact.',
          unsupported_grant_type: 'Niet ondersteund grant type verzonden naar Exact.',
        },
      },
      syncButton: 'Synchroniseer met Exact',
      syncResult: {
        authFailure: { notification: 'Authenticatie met Exact gefaald.' },
        success: {
          notification: 'Succesvol gesynchroniseerd met Exact',
          count: {
            address: {
              tracy: {
                created: '{{count}} Adres in Tracy aangemaakt.',
                created_plural: '{{count}} Adressen in Tracy aangemaakt.',
                updated: '{{count}} Adres in Tracy bijgewerkt.',
                updated_plural: '{{count}} Adressen in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Adres in Exact aangemaakt.',
                created_plural: '{{count}} Adressen in Exact aangemaakt.',
                updated: '{{count}} Adres in Exact bijgewerkt.',
                updated_plural: '{{count}} Adressen in Exact bijgewerkt.',
              },
            },
            item: {
              tracy: {
                created: '{{count}} Artikel in Tracy aangemaakt.',
                created_plural: '{{count}} Artikelen in Tracy aangemaakt.',
                updated: '{{count}} Artikel in Tracy bijgewerkt.',
                updated_plural: '{{count}} Artikelen in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Artikel in Exact aangemaakt.',
                created_plural: '{{count}} Artikelen in Exact aangemaakt.',
                updated: '{{count}} Artikel in Exact bijgewerkt.',
                updated_plural: '{{count}} Artikelen in Exact bijgewerkt.',
              },
            },
            project: {
              tracy: {
                created: '{{count}} Project in Tracy aangemaakt.',
                created_plural: '{{count}} Projecten in Tracy aangemaakt.',
                updated: '{{count}} Project in Tracy bijgewerkt.',
                updated_plural: '{{count}} Projecten in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Project in Exact aangemaakt.',
                created_plural: '{{count}} Projecten in Exact aangemaakt.',
                updated: '{{count}} Project in Exact bijgewerkt.',
                updated_plural: '{{count}} Projecten in Exact bijgewerkt.',
              },
            },
            storage_location_stock_position: {
              tracy: {
                created: '{{count}} Opslaglocatie in Tracy aangemaakt.',
                created_plural: '{{count}} Opslaglocaties in Tracy aangemaakt.',
                updated: '{{count}} Opslaglocatie in Tracy bijgewerkt.',
                updated_plural: '{{count}} Opslaglocaties in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Opslaglocatie in Exact aangemaakt.',
                created_plural: '{{count}} Opslaglocaties in Exact aangemaakt.',
                updated: '{{count}} Opslaglocatie in Exact bijgewerkt.',
                updated_plural: '{{count}} Opslaglocaties in Exact bijgewerkt.',
              },
            },
            bill_of_material: {
              tracy: {
                created: '{{count}} Stuklijst in Tracy aangemaakt.',
                created_plural: '{{count}} Stuklijsten in Tracy aangemaakt.',
                updated: '{{count}} Stuklijst in Tracy bijgewerkt.',
                updated_plural: '{{count}} Stuklijsten in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Stuklijst in Exact aangemaakt.',
                created_plural: '{{count}} Stuklijsten in Exact aangemaakt.',
                updated: '{{count}} Stuklijst in Exact bijgewerkt.',
                updated_plural: '{{count}} Stuklijsten in Exact bijgewerkt.',
              },
            },
            bill_of_material_item: {
              tracy: {
                created: '{{count}} Stuklijst artikel in Tracy aangemaakt.',
                created_plural: '{{count}} Stuklijst artikelen in Tracy aangemaakt.',
                updated: '{{count}} Stuklijst artikel in Tracy bijgewerkt.',
                updated_plural: '{{count}} Stuklijst artikelen in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Stuklijst Artikel in Exact aangemaakt.',
                created_plural: '{{count}} Stuklijst Artikelen in Exact aangemaakt.',
                updated: '{{count}} Stuklijst Artikel in Exact bijgewerkt.',
                updated_plural: '{{count}} Stuklijst Artikelen in Exact bijgewerkt.',
              },
            },
            account: {
              tracy: {
                created: '{{count}} Relatie in Tracy aangemaakt.',
                created_plural: '{{count}} Relaties in Tracy aangemaakt.',
                updated: '{{count}} Relatie in Tracy bijgewerkt.',
                updated_plural: '{{count}} Relaties in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Relatie in Exact aangemaakt.',
                created_plural: '{{count}} Relaties in Exact aangemaakt.',
                updated: '{{count}} Relatie in Exact bijgewerkt.',
                updated_plural: '{{count}} Relaties in Exact bijgewerkt.',
              },
            },
            sales_order: {
              tracy: {
                created: '{{count}} Verkooporder in Tracy aangemaakt.',
                created_plural: '{{count}} Verkooporders in Tracy aangemaakt.',
                updated: '{{count}} Verkooporder in Tracy bijgewerkt.',
                updated_plural: '{{count}} Verkooporders in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Verkooporder in Exact aangemaakt.',
                created_plural: '{{count}} Verkooporders in Exact aangemaakt.',
                updated: '{{count}} Verkooporder in Exact bijgewerkt.',
                updated_plural: '{{count}} Verkooporders in Exact bijgewerkt.',
              },
            },
            purchase_order: {
              tracy: {
                created: '{{count}} Inkooporder in Tracy aangemaakt.',
                created_plural: '{{count}} Inkooporders in Tracy aangemaakt.',
                updated: '{{count}} Inkooporder in Tracy bijgewerkt.',
                updated_plural: '{{count}} Inkooporders in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Inkooporder in Exact aangemaakt.',
                created_plural: '{{count}} Inkooporders in Exact aangemaakt.',
                updated: '{{count}} Inkooporder in Exact bijgewerkt.',
                updated_plural: '{{count}} Inkooporders in Exact bijgewerkt.',
              },
            },
            shop_order: {
              tracy: {
                created: '{{count}} Productieorder in Tracy aangemaakt.',
                created_plural: '{{count}} Productieorders in Tracy aangemaakt.',
                updated: '{{count}} Productieorder in Tracy bijgewerkt.',
                updated_plural: '{{count}} Productieorders in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Productieorder in Exact aangemaakt.',
                created_plural: '{{count}} Productieorders in Exact aangemaakt.',
                updated: '{{count}} Productieorder in Exact bijgewerkt.',
                updated_plural: '{{count}} Productieorders in Exact bijgewerkt.',
              },
            },
            shop_order_material_plan: {
              tracy: {
                created: '{{count}} Productieorder Materiaal Plan in Tracy aangemaakt.',
                created_plural: '{{count}} Productieorder Materiaal Plans in Tracy aangemaakt.',
                updated: '{{count}} Productieorder Materiaal Plan in Tracy bijgewerkt.',
                updated_plural: '{{count}} Productieorder Materiaal Plans in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Productieorder Materiaal Plan in Exact aangemaakt.',
                created_plural: '{{count}} Productieorder Materiaal Plans in Exact aangemaakt.',
                updated: '{{count}} Productieorder Materiaal Plan in Exact bijgewerkt.',
                updated_plural: '{{count}} Productieorder Materiaal Plans in Exact bijgewerkt.',
              },
            },
            shop_order_receipt: {
              tracy: {
                created: '{{count}} Productieorder Ontvangst in Tracy aangemaakt.',
                created_plural: '{{count}} Productieorder Ontvangsten in Tracy aangemaakt.',
                updated: '{{count}} Productieorder Ontvangst in Tracy bijgewerkt.',
                updated_plural: '{{count}} Productieorder Ontvangsten in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Productieorder Ontvangst in Exact aangemaakt.',
                created_plural: '{{count}} Productieorder Ontvangsten in Exact aangemaakt.',
                updated: '{{count}} Productieorder Ontvangst in Exact bijgewerkt.',
                updated_plural: '{{count}} Productieorder Ontvangsten in Exact bijgewerkt.',
              },
            },
            shop_order_reversal: {
              tracy: {
                created: '{{count}} Productieorder Herroeping in Tracy aangemaakt.',
                created_plural: '{{count}} Productieorder Herroepingen in Tracy aangemaakt.',
                updated: '{{count}} Productieorder Herroeping in Tracy bijgewerkt.',
                updated_plural: '{{count}} Productieorder Herroepingen in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Productieorder Herroeping in Exact aangemaakt.',
                created_plural: '{{count}} Productieorder Herroepingen in Exact aangemaakt.',
                updated: '{{count}} Productieorder Herroeping in Exact bijgewerkt.',
                updated_plural: '{{count}} Productieorder Herroepingen in Exact bijgewerkt.',
              },
            },
            opportunity: {
              tracy: {
                created: '{{count}} Verkoopkans in Tracy aangemaakt.',
                created_plural: '{{count}} Verkoopkansen in Tracy aangemaakt.',
                updated: '{{count}} Verkoopkans in Tracy bijgewerkt.',
                updated_plural: '{{count}} Verkoopkansen in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Verkoopkans in Exact aangemaakt.',
                created_plural: '{{count}} Verkoopkansen in Exact aangemaakt.',
                updated: '{{count}} Verkoopkans in Exact bijgewerkt.',
                updated_plural: '{{count}} Verkoopkansen in Exact bijgewerkt.',
              },
            },
            quotation: {
              tracy: {
                created: '{{count}} Offerte in Tracy aangemaakt.',
                created_plural: '{{count}} Offertes in Tracy aangemaakt.',
                updated: '{{count}} Offerte in Tracy bijgewerkt.',
                updated_plural: '{{count}} Offertes in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Offerte in Exact aangemaakt.',
                created_plural: '{{count}} Offertes in Exact aangemaakt.',
                updated: '{{count}} Offerte in Exact bijgewerkt.',
                updated_plural: '{{count}} Offertes in Exact bijgewerkt.',
              },
            },
            goods_receipt: {
              tracy: {
                created: '{{count}} Goederen Ontvangst in Tracy aangemaakt.',
                created_plural: '{{count}} Goederen Ontvangsten in Tracy aangemaakt.',
                updated: '{{count}} Goederen Ontvangst in Tracy bijgewerkt.',
                updated_plural: '{{count}} Goederen Ontvangsten in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Goederenontvangst in Exact aangemaakt.',
                created_plural: '{{count}} Goederenontvangsten in Exact aangemaakt.',
                updated: '{{count}} Goederenontvangst in Exact bijgewerkt.',
                updated_plural: '{{count}} Goederenontvangsten in Exact bijgewerkt.',
              },
            },
            goods_delivery: {
              tracy: {
                created: '{{count}} Goederenlevering in Tracy aangemaakt.',
                created_plural: '{{count}} Goederenleveringen in Tracy aangemaakt.',
                updated: '{{count}} Goederenlevering in Tracy bijgewerkt.',
                updated_plural: '{{count}} Goederenleveringen in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Goederenlevering in Exact aangemaakt.',
                created_plural: '{{count}} Goederenleveringen in Exact aangemaakt.',
                updated: '{{count}} Goederenlevering in Exact bijgewerkt.',
                updated_plural: '{{count}} Goederenleveringen in Exact bijgewerkt.',
              },
            },
            sales_return: {
              tracy: {
                created: '{{count}} Verkoop Retour in Tracy aangemaakt.',
                created_plural: '{{count}} Verkoop Retouren in Tracy aangemaakt.',
                updated: '{{count}} Verkoop Retour in Tracy bijgewerkt.',
                updated_plural: '{{count}} Verkoop Retouren in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Verkoop Retour in Exact aangemaakt.',
                created_plural: '{{count}} Verkoop Retouren in Exact aangemaakt.',
                updated: '{{count}} Verkoop Retour in Exact bijgewerkt.',
                updated_plural: '{{count}} Verkoop Retouren in Exact bijgewerkt.',
              },
            },
            warehouse_transfer: {
              tracy: {
                created: '{{count}} Voorraadverplaatsing in Tracy aangemaakt.',
                created_plural: '{{count}} Voorraadverplaatsingen in Tracy aangemaakt.',
                updated: '{{count}} Voorraadverplaatsing in Tracy bijgewerkt.',
                updated_plural: '{{count}} Voorraadverplaatsingen in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} Voorraadverplaatsing in Exact aangemaakt.',
                created_plural: '{{count}} Voorraadverplaatsingen in Exact aangemaakt.',
                updated: '{{count}} Voorraadverplaatsing in Exact bijgewerkt.',
                updated_plural: '{{count}} Voorraadverplaatsingen in Exact bijgewerkt.',
              },
            },
            stock_count: {
              exact: {
                created: '{{count}} Voorraadtelling in Exact aangemaakt.',
                created_plural: '{{count}} Voorraadtellingen in Exact aangemaakt.',
              },
            },
            shipping_method: {
              tracy: {
                created: '{{count}} verzendmethode in Tracy aangemaakt.',
                created_plural: '{{count}} voorraad verzendmethode in Tracy aangemaakt.',
                updated: '{{count}} verzendmethode in Tracy bijgewerkt.',
                updated_plural: '{{count}} voorraad verzendmethode in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} verzendmethode in Exact aangemaakt.',
                created_plural: '{{count}} voorraad verzendmethode in Exact aangemaakt.',
                updated: '{{count}} verzendmethode in Exact bijgewerkt.',
                updated_plural: '{{count}} voorraad verzendmethode in Exact bijgewerkt.',
              },
            },
            document: {
              exact: {
                created: 'Created {{count}} document in Exact aangemaakt.',
                created_plural: 'Created {{count}} documents in Exact aangemaakt.',
                updated: 'Updated {{count}} document in Exact aangemaakt.',
                updated_plural: 'Updated {{count}} documents in Exact aangemaakt.',
              },
            },
          },
        },
      },
    },
    field: {
      division: { label: 'Divisie' },
      webhooks: { label: 'Webhooks' },
      manageEndpointsHeader: { label: 'Beheer EXACT API-eindpunten' },
      manageWebhooksHeader: { label: 'Exact Webhooks-onderwerpen beheren' },
      address: { label: 'Adres' },
      item: { label: 'Item' },
      account: { label: 'Account' },
      opportunity: { label: 'Mogelijkheid' },
      salesOrder: { label: 'Verkooporder' },
      purchaseOrder: { label: 'Bestelling' },
      project: { label: 'Project' },
      billOfMaterial: { label: 'Stuklijst' },
      billOfMaterialItem: { label: 'Stuklijstitem' },
      shopOrder: { label: 'Werkorder' },
      shopOrderMaterialPlan: { label: 'Materiaalplan' },
      shopOrderReceipt: { label: 'Productieorder Ontvangst' },
      shopOrderReversal: { label: 'Productieorder Herroeping' },
      salesReturn: { label: 'Verkoopretour' },
      warehouse: { label: 'Magazijn' },
      storageLocationStockPosition: { label: 'Voorraadpositie Opslaglocatie' },
      itemWarehouse: { label: 'Magazijn Artikel' },
      goodsReceipt: { label: 'Goederenontvangst' },
      goodsDelivery: { label: 'Goederenaflevering' },
      warehouseTransfer: { label: 'Voorraadverplaatsing' },
      stockCount: { label: 'Voorraadtelling' },
      stockCountReversal: { label: 'Omkering Voorraadtelling' },
      stockTopic: { label: 'Voorraad' },
      stockSerialNumber: { label: 'Serienummer voorraad' },
      shippingMethod: { label: 'Verzendmethode' },
      salesOrderWebhookTopic: { label: 'Verkooporder Webhook' },
      document: { label: 'Document' },
    },
    edit: {
      topics: {
        title: 'Beheer Exact API-eindpunten',
        subtitle: {
          webhooks: 'Webhooks',
          general: 'Algemeen',
          production: 'Productie',
          warehouse: 'Magazijn',
        },
      },
    },
  },
  unit4Integration: {
    overview: {
      authButton: 'Activeer Unit4 Integratie',
      authResult: {
        ok: 'Unit4 integratie succesvol geactiveerd.',
        error: {
          invalid_request: 'Onjuist verzoek verzonden naar Unit4.',
          invalid_client: 'Onjuiste client verzonden naar Unit4.',
          invalid_grant: 'Onjuiste grant verzonden naar Unit4.',
          invalid_scope: 'Onjuiste scope verzonden naar Unit4. ',
          unauthorized_client: 'Ongeauthorizeerde client verzonden naar Unit4.',
          unsupported_grant_type: 'Niet ondersteund grant type verzonden naar Unit4.',
        },
      },
      syncButton: 'Synchroniseer met Unit4',
      syncResult: {
        authFailure: { notification: 'Authenticatie met Exact gefaald.' },
        success: {
          notification: 'Succesvol gesynchroniseerd met Unit4',
        },
      },
    },
    field: {
      administration: { label: 'Administratiecode' },
      baseUrl: { label: 'Unit 4 server basis-URL' },
      manageEndpointsHeader: { label: 'Beheer Unit4 API-eindpunten' },
      goodsReceipt: { label: 'Goederenontvangst' },
      product: { label: 'Product' },
      productDetails: { label: 'Product Details' },
      supplier: { label: 'Leverancier' },
      purchaseOrder: { label: 'Verkooporder' },
      customer: { label: 'Klant' },
      order: { label: 'Inkooporder' },
    },
  },
  syncError: {
    overview: {
      title: 'Synchronisatie Fouten',
      empty: 'Geen snchronisatie fouten gevonden.',
    },
    field: {
      id: { label: 'ID' },
      message: { label: 'Melding' },
    },
  },
  project: {
    overview: {
      title: 'Projecten',
      empty: 'Er zijn geen projecten die voldoen aan uw zoekopdracht.',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      description: { label: 'Beschrijving' },
      notes: { label: 'Notities' },
      startDate: { label: 'Startdatum' },
      endDate: { label: 'Einddatum' },
    },
  },
  billOfMaterialVersion: {
    edit: {
      title: 'Bekijk stuklijst',
    },
    field: {
      version: {
        label: 'Versie',
      },
      status: {
        label: 'Status',
      },
      description: {
        label: 'Omschrijving',
      },
      default: {
        label: 'Standaard',
      },
    },
  },
  billOfMaterialItem: {
    field: {
      articleType: { label: 'Artikel' },
      description: { label: 'Omschrijving' },
      quantity: { label: 'Aantal' },
      required: { label: 'Benodigd' },
      assigned: { label: 'Toegekend' },
      backflush: { label: 'Backflush' },
      number: { label: 'Regel.' },
      warehouse: { label: 'Magazijn' },
      storageLocations: { label: 'Opslaglocaties' }
    },
  },
  productionOrder: {
    field: {
      billOfMaterialVersion: { label: 'Stuklijst Versie' },
      warehouse: { label: 'Magazijn' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          released: 'Vrijgegeven',
          finished: 'Klaar',
        },
      },
    },
    billOfMaterialVersionPlaceholder: 'Geen stuklijst',
    procesPlaceholder: 'Geen maak proces',
    edit: {
      title: 'Productieorder Aanpassen',
    },
    create: {
      title: 'Productieorder Aanmaken',
    },
  },
  script: {
    minXInfo: 'Min X is nog niet beschikbaar voor deze locatie',
    release:{
      finalize: 'Afronden',
      revert: 'Terugdraaien',
      cannotRevert: 'Terugdraaien is niet mogelijk, dit hoofdstuk is in gebruik',
    },
    library: 'Bibliotheek',
    overview: {
      title: 'Script Bibliotheek',
      empty: 'Er zijn geen hoofdstukken die voldoen aan uw zoekopdracht.',
      addButton: 'Script',
      chapterTitle: 'Hoofstuk Bibliotheek',
      chapterAddButton: 'Hoofdstuk aanmaken',
    },
    create: {
      title: 'Hoofdstuk Aanmaken',
    },
    edit: {
      title: 'Hoofdstuk Aanpassen',
      schedule: 'Dag Schema',
      copyPaste: {
        copyButton: 'Kopiëren',
        pasteButton: 'Plakken',
        copied: 'Succesvol gekopieerd',
        pasted: 'Succesvol geplakt',
        pasteError: 'Niks om te plakken.',
        pasteTypeMismatch: 'Klembord bevat een hoofdstuk van een ander type.',
      },
      fixOrdering: 'Herstel tijdvolgorde',
      orderWillBeFixed: 'De volgorde wordt automatisch hersteld bij het opslaan.',
    },
    view:{
      'title': 'Script bekijken',
      'popup': 'Bekijken',
    },
    field: {
      notes: { label: 'Notities' },
      sendComment: 'Opmerking verzenden (CTRL + ENTER)',
      status: {
        label: 'Status',
        info: 'Scripts met status \'productie\' of \'gearchiveerd\' kunnen niet bewerkt worden.',
        value: {
          concept: 'Concept',
          test: 'Test',
          production: 'Productie',
          archived: 'Gearchiveerd',
        },
      },
      type: {
        label: 'Type',
        value: {
          environment: 'Klimaat',
          light: 'Licht',
          fertilizer: 'Fertilizer',
          nutrition_storage: 'Nutrition storage',
          nutrition_irrigation: 'Irrigation',
          package: 'Scripts in gebruik',
        },
      },
      location: {
        label: 'Locatie',
        display: {
          multilayer: 'Multilayer',
          highwire: 'Highwire',
          rnd: 'R&D',
          both: 'Multilayer; Highwire',
        },
        value: {
            multilayer: 'Multilayer',
            highwire: 'Highwire',
            rnd: 'R&D',
            both: 'Beide',
        },
      },
      climate: {
        name: 'Klimaat',
        placeholder: 'Selecteer klimaat recept',
      },
      fertilizer: {
        name: 'Fertilizer',
        placeholder: 'Selecteer fertilizer recept',
      },
      name: { label: 'Naam' },
      repeat: { label: 'Aantal dagen om te herhalen' },
      createdAt: {
        label: "Gemaakt op",
      },
      createdBy: {
        label: 'door',
      },
      creator:{
        label: 'Gemaakt door',
      },
      runningIn: {
        label: 'Toegepast op',
      },
      notRunning: {
        label: 'Niet toegepast',
      },
      currentlyRunning: {
        label: 'Nu toegepast in',
      },
      lastRunningAt: {
        label: 'Laatst toegepast in',
      },
      daylight: {
        label: 'Daglicht',
        min: 'Min uren dagtijd',
        max: 'Max uren dagtijd',
      },
    },
    filter: {
      search: {
        label: 'Zoeken',
        placeholder: 'Naam, ID of notitie ...',
      },
      status: {
        label: 'Status',
        option: {
          concept: 'Concept',
          test: 'Test',
          production: 'Afgerond',
          archived: 'Gearchiveerd',
          draft: 'Concept',
          final: 'Definitief',
        }
      },
      location: {
        label: 'Plantageomgeving',
        option: {
          multilayer: 'Multilayer',
          highwire: 'Highwire',
          rnd: 'R&D'
        },
      },
      runningInChamber: {
        label: 'Toegepast in kamer',
        placeholder: 'Begin met typen om naar een chamber te zoeken',
      },
      usedInScript: {
        label: 'Gebruikt in script',
        placeholder: 'Begin met typen om naar een script te zoeken',
      },
    },
    history: {
      action: {
        edit: "hoofdstuk bewerkt",
        created: "hoofdstuk gemaakt",
        note: "notitie achtergelaten",
        applied: "hoofdstuk toegepast",
        stopped: "hoofdstuk gestopt",
      },
    },
  },
  recipe: {
    overview: {
      title: 'Recepten Bibliotheek',
      empty: 'Geen recepten gevonden',
      addButton: 'Recept',
    },
    field: {
      name:{
        label: 'Search',
        text: 'Naam',
        placeholder: 'Naam, Id of notitie ...',
      },
      type:{
        label: 'Plantageomgeving',
      },
      plantationArea: {
        label: 'Plantageomgeving',
      },
      status:{
        value:{
          active: 'Actief',
          archived: 'Gearchiveerd',
          all: 'Allemaal',
        }
      },
      location:{
        value:{
          all: 'Allemaal',
        },
      },
      notes: {
        label: 'Notities',
        empty: 'Geen notities',
        more: ', (meer weergeven)',
      },
    },
    edit: {
      duplicate: 'Dupliceren',
      archive: 'Archiveren',
      unarchive: 'Archiveren ongedaan maken',
      archived: 'GEARCHIVEERD',
      warningmodal:{
        header: 'Kan recept niet archiveren',
        recipe: 'Hoofdstuk',
        content: 'kan niet gearchiveerd worden omdat deze gebruikt wordt.',
      },
      modal:{
        header: 'Weet u het zeker?',
        recipe: 'Hoofdstuk',
        content: 'wordt gearchiveerd.',
      },
    },
  },
  workStationSession: {
    overview: {
      title: 'Werkplek Sessies',
      empty: 'Geen werkplek sessies gevonden.',
      viewPrintJobsButton: 'Bekijk Printopdrachten',
    },
    field: {
      id: { label: 'ID' },
      type: {
        label: 'Type',
        value: {
          desktop: 'Desktop',
          mobile: 'Mobiel',
        },
      },
      printers: {
        label: 'Printers',
        empty: 'Geen printers beschikbaar.',
      },
      workStation: { label: 'Werkplek' },
      appVersion: { label: 'App Versie' },
      isActive: { label: 'Is Actief' },
      createdAt: { label: 'Gestart Op' },
      updatedAt: { label: 'Laatste Activiteit' },
      printJobs: { label: 'Print Opdrachten' },
    },
  },
  printJob: {
    field: {
      printer: {
        label: 'Printer',
      },
      status: {
        label: 'Status',
        value: {
          todo: 'Te Doen',
          done: 'Afgerond',
          failed: 'Mislukt',
        },
      },
      content: {
        label: 'Inhoud',
      },
      copies: {
        label: 'Kopieën',
      },
      createdAt: {
        label: 'Toegevoegd Op',
      },
    }
  },
  warehouseTransfer: {
    overview: {
      title: 'Magazijnverplaatsing',
      empty: 'Geen Magazijnverplaatsingen',
      viewLinesButton: 'Bekijk Magazijnverplaatsingsregels',
    },
    edit: {
      title: 'Magazijnverplaatsing bewerken',
    },
    create: {
      title: 'Magazijnverplaatsing toevoegen',
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Beschrijving' },
      entryDate: { label: 'Invoerdatum' },
      plannedDeliveryDate: { label: 'Geplande Verplaatsingsdatum' },
      completedOnDate: { label: 'Voltooid Op Datum' },
      status: {
        label: 'Status',
        value: {
          draft: 'Concept',
          processed: 'Verwerkt',
        },
      },
      source: {
        label: 'Bron',
        value: {
          manual_entry: 'Handmatige Invoer',
          import: 'Importeren',
          transfer_advice: 'Verplaatsingsadvies',
          web_service: 'Webservice',
        },
      },
      warehouseFrom: {
        label: 'Magazijn Van',
      },
      warehouseTo: {
        label: 'Magazijn Naar',
      },
      type: {
        label: 'Overdrachtstype',
        value: {
          true: 'Locatie',
          false: 'Magazijn',
        }
      },
      productionRequest: {
        label: 'Werkorder',
        isNull: 'Met werkorders',
      },
      transferLines: {
        label: 'Magazijnverplaatsingsregels',
        value: 'Magazijnverplaatsingsregel {{id}}',
        empty: 'Er zijn geen Magazijnverplaatsingsregels toegevoegd.',
      },
    },
  },
  warehouseTransferLine: {
    empty: 'Geen Magazijnverplaatsingsregels.',
    field: {
      id: { label: 'ID' },
      lineNumber: { label: 'Regelnummer' },
      createdDate: { label: 'Aanmaakdatum' },
      quantity: { label: 'Aantal stuks' },
      articleType: { label: 'Artikeltype' },
      availableStock: { label: 'Voorraad' },
      noAvailableStock: { label: 'Geen vooraad voor geselecteerd Artikeltype.' },
      storageLocation: {
        id: { label: 'ID' },
        warehouse: { label: 'Magazijn' },
        code: { label: 'Code' },
        stock: { label: 'Voorraad' },
      },
      storageLocationFrom: {
        label: 'Van Opslaglocatie',
      },
      storageLocationTo: {
        label: 'Naar Opslaglocatie',
      },
      warehouseTransfer: {
        label: 'Voorraadverplaatsing',
      },
    },
    create: {
      title: 'Bekijk Voorraadverplaatsingsregels',
    },
  },
  detailImageModal: {
    title: { label: 'Geüploade afbeeldingen' },
    popup: { label: 'Bekijk geüploade afbeeldingen' }
  },
  stockCount: {
    field: {
      warehouse: { label: 'Magazijn' },
      defaultStorageLocation: { label: 'Opslaglocatie' },
    },
    edit: {
      title: 'Voorraadtelling aanpassen'
    },
    title: { label: 'Geüploade afbeeldingen' },
    popup: { label: 'Bekijk geüploade afbeeldingen' },
    missingStockCountProcess: 'Ontbrekend Voorraadtelling proces aanmaken',
  },
  growStep: {
    field: {
      days: { label: 'Dagen' },
      color: {
        label: 'Kleur',
        value: {
          blue: 'Blauw',
          purple: 'Paars',
          red: 'Rood',
          orange: 'Oranje',
          green: 'Groen',
        },
      },
      plantationLine: { label: 'Plantageomgeving' },
    },
  },
  operatorAssignment: {
    field: {
      workStation: { label: 'Werkstatoin' },
      operator: { label: 'Operator' },
      startAt: { label: 'Start' },
      endAt: { label: 'Einde' },
    },
  },
  articleTypeStorageLocation: {
    field: {
      isDefault: { label: 'Standaard' },
      stock: { label: '# Beschikbaar' },
      assigned: { label: '# Toegekend' },
      warehouse: { label: 'Magazijn' },
    },
  },
  storageLocation: {
    field: {
      code: { label: 'Code' },
      name: { label: 'Name' },
    },
  },
  nutritionStorageScript: {
    field: {
      fertilizerScript: { label: 'Voeding' },
      ec: { label: 'EC' },
      ph: { label: 'pH' },
    },
  },
  nutritionIrrigationScript: {
    field: {
      pulse: { label: 'Opentijd' },
      cycleNumber: { label: 'Cyclusnummer' },
      startTime: { label: 'Tijd' },
    },
  },
  nutritionIrrigationScriptSchedule: {
    field: {
      pulse: { label: 'Opentijd' },
      cycleNumber: { label: 'Cyclusnummer' },
      startTime: { label: 'Tijd' },
    },
  },
  plantationLine: {
    overview: {
      title: 'Plantageomgevingen',
      empty: 'Geen Plantageomgevingen gevonden',
      addButton: 'Plantageomgeving',
      select: {
        type: {
          overview: 'R&D Overzicht',
          multilayer: 'Multilayer ({{count}})',
          highwire: 'Highwire ({{count}})',
          'rnd multilayer': 'R&D Multilayer ({{count}})',
          'rnd highwire': 'R&D Highwire ({{count}})',
        },
      },
      details: {
        location: 'R{{rack}}L{{layer}}P{{position}}',
        row: 'R{{rack}}L{{layer}}',
        tank: 'T{{tank}}',
        rack: 'R{{rack}}',
        group: 'Groep {{group}}',
      },
      areaPosition: {
        viewGraph: "Bekijk grafiek",
        labels: {
          openTime: 'Open Tijd (s)',
          cycleNumber: 'Frequentie (h)',
          setpointLight: 'Licht Setpoint (μmol/m²/s)',
          feedbackLight: 'Licht Feedback (μmol/m²/s)',
          tableNumber: 'Tafel Nummer',
        }
      },
      history: {
        title: 'Geschiedenis: {{subject}}',
        start: 'Start',
        end: 'Eind',
      },
      rack: { label: 'Rek', value: '{{rack}}' },
      location: { label: 'Positie', value: 'R{{rack}}L{{layer}}P{{position}}' },
      container: { label: 'Container' },
      scripts: {
        noneSelected: 'Geen Geselecteerd',
        subScript: 'Fase {{n}}',
        hasSchedule: 'Heeft een Dagschema',
        hasMultiDay: 'Heeft meerdere dagen gepland',
        label: 'Waardes',
        setpoint: {
          name: 'Naam',
          value: 'Waarde',
          current: 'Huidig',
          actual: "Daadwerkelijk",
        },
      },
      production: 'Productie',
      moveUp: 'Verplaats Omhoog',
      moveDown: 'Verplaats Naar Onder',
      rndOverview: {
        chamberOverview: 'Kamer Overzicht',
        goToChamber: 'Ga naar Kamer {{chamber}}',
        note: "Let op: Deze pagina toont (nu nog) alleen Feedback-waardes. Open de individuele chamber-pagina's om te controleren of de waardes binnen de setpoint range vallen.",
        labels: {
          evaporation: 'Verdamping',
          co2: 'CO₂',
          tIn: 'T in',
          tOut: 'T out',
          xIn: 'X in',
          xOut: 'X out',
          speed: 'Snelheid',
          humidity: 'Vochtigheid',
          ec1: 'EC 1',
          ec2: 'EC 2',
          ph1: 'pH 1',
          ph2: 'pH 2',
          tantT: 'Temperatuur',
          ahu1: 'R&D AHU 1',
          ready1: 'R&D Ready 1'
        },
        units: {
          evaporation: 'g/m²⋅h',
          co2: 'ppm',
          tIn: '°C',
          tOut: '°C',
          xIn: 'g/kg',
          xOut: 'g/kg',
          speed: 'cm/s',
          humidity: '%',
          ec1: 'mS/cm',
          ec2: 'mS/cm',
          ph1: '',
          ph2: '',
          tantT: '°C',
        },
      },
    },
    create: {
      title: 'Plantageomgeving Toevoegen',
    },
    edit: {
      title: 'Plantageomgeving Aanpassen',
      planning: {
        empty: 'Nog geen scripts gepland.',
        current: 'Huidig',
        next: 'Volgend',
        planned: 'Gepland ({{count}})',
        modal: {
          title: {
            add: 'Script Allocatie Aanmaken',
            edit: 'Script Allocatie Aanpassen',
          },
        },
        overrides: {
          label: 'Overrides',
        },
        confirm: {
          groupDelete: {
            message: 'Weet je zeker dat je deze groep wilt verwijderen?',
            action: 'Verwijderen',
          },
          overrideDelete: {
            message: 'Weet je zeker dat je deze override wilt verwijderen?',
            action: 'Verwijderen',
          },
          cancelChanges: {
            message: 'Je hebt onopgeslagen veranderingen, weet je zeker dat je het venster wilt sluiten?',
            action: 'Sluiten',
          },
        },
      },
      tank: {
        label: {
          highwire: 'HW ready {{tank}}',
          multilayer: 'ML ready {{tank}}',
          'rnd highwire': 'R&D ready {{tank}}',
          'rnd multilayer': 'R&D ready {{tank}}',
          factory: 'Premix {{tank}}',
        },
        ec1: 'EC 1 (mS/cm)',
        ph1: 'pH 1',
        ec2: 'EC 2 (mS/cm)',
        ph2: 'pH 2',
        tankTemp: 'Hudige Tank Temperatuur',
      },
      ahu: {
        label: {
          highwire: 'HW LBK {{ahu}}',
          multilayer: 'ML LBK {{ahu}}',
          'rnd highwire': 'R&D AHU {{ahu}}',
          'rnd multilayer': 'R&D AHU {{ahu}}',
        },
        evaporation: 'Verdamping (g/m²⋅h)',
        tIn: 'T in (°C)',
        tOut: 'T out (°C)',
        xIn: 'X in (g/kg)',
        xOut: 'X out (g/kg)',
        co2: 'CO₂ (ppm)',
        speed: 'Velocity (cm/s)',
        humidity: 'Vochtigheid (%)',
      },
      graph: {
        search: 'Zoeken',
        selectAll: 'Selecteer Alles',
        unselectAll: 'Deselecteer Alles',
        ahu: {
          label: 'LBK',
          value: 'LBK {{ahu}}',
          field: {
            FB_t_in: 'T in',
            FB_evaporation: 'Verdamping',
            FB_speed_cm: 'Flowrate',
            FB_co2_ML: 'CO₂',
          },
          unit: {
            FB_t_in: '°C',
            FB_evaporation: 'g/m²⋅h',
            FB_speed_cm: 'm³/h',
            FB_co2_ML: 'ppm',
          },
        },
        tank: {
          label: 'Tank',
          value: 'Tank {{tank}}',
          field: {
            FB_ec: 'EC',
            FB_ph: 'pH',
          },
          unit: {
            FB_ec: 'mS/cm',
            FB_ph: '',
          },
        },
        light: {
          label: 'Licht',
          value: 'R{{rack}}L{{layer}}P{{position}}',
          field: {
            FB_intensity: 'Intensiteit'
          },
          unit: {
            FB_intensity: 'mA'
          },
        },
        scope: {
          hour: 'Afgelopen Uur',
          day: 'Afgelopen Dag',
          week: 'Afgelopen Week',
          month: 'Afgelopen Maand',
        },
      }
    },
  },
  plantationAllocation: {
    field: {
      startDate: { label: 'Start' },
      endDate: { label: 'Eind' },
      rack: { label: "Rack" },
      layer: {label: "Laag" },
      position: { label: 'Positie' },
    },
  },
  fertilizerScript: {
    field: {
      salpeterzuurA: { label: 'Salpeterzuur' },
      kalksalpeterA: { label: 'Kalksalpeter' },
      reserveA: { label: 'Ammonium nitraat' },
      bitterzoutA: { label: 'Bitterzout' },
      fosforzuurA: { label: 'Fosforzuur' },
      kaliloogA: { label: 'Kaliloog' },
      microElementsA: { label: 'Spoorelementen' },
      ijzerA: {label: 'Ijzerchelaat DTPa 6%'},
    },
  },
  lightScript: {
    field: {
      intensity: {
        label: 'Intensiteit',
        max: 'Max intensiteit',
        min: 'Min intensiteit',
      },
      startTime: { label: 'Tijd' },
    },
  },
  lightScriptSchedule: {
    field: {
      intensity: { label: 'Intensiteit' },
      startTime: { label: 'Tijd' },
    },
  },
  factory: {
    overview: {
      title: 'Locaties',
      empty: 'Geen locaties gevonden.',
      addButton: 'Locatie',
    },
    create: {
      title: 'Locatie Toevoegen',
    },
    edit: {
      title: 'Locatie Aanpassen',
    },
    field: {
      name: { label: 'Locatie' },
      timezone: { label: 'Tijdzone' },
      currentTime: { label: 'Huidige Tijd' },
    },
  },
  environmentScript: {
    field: {
      directionInterval: { label: 'Richting Interval' },
      speed: { label: 'Snelheid' },
      tIn: { label: 'T in' },
      evaporation: { label: 'Verdamping' },
      co2In: { label: 'CO₂' },
      minX: {label: 'Minimum X'},
      startTime: { label: 'Tijd' },
      deltaX : { label: 'Delta-X' },
      direction: {
        value: {
          0: 'Uit',
          1: 'A naar B',
          2: 'B naar A',
        },
      },
    },
  },
  environmentScriptSchedule: {
    field: {
      directionInterval: { label: 'Richting Interval' },
      speed: { label: 'Snelheid' },
      tIn: { label: 'T in' },
      evaporation: { label: 'Verdamping' },
      co2In: { label: 'CO₂' },
      minX: {label: 'Minimum X'},
      startTime: { label: 'Tijd' },
    },
  },
  blueSkiesOverride: {
    articleType: {
      field: {
        code: { label: 'SKU' },
      },
    },
  },
  businessRelation: {
    field: {
      name: { label: 'Klantnaam' },
    },
  },
  unit4Customer: {
    field: {
      customerId: { label: 'Klant ID'}
    }
  },
  navisionCustomer: {
    field: {
      customerId: { label: 'Klant ID' },
    },
  },
  unit4Order : {
    field: {
      orderDate: { label: 'Verzenddatum'}
    }
  },
  navisionSalesOrder: {
    field: {
      orderDate: { label: 'Orderdatum' },
      requestedDeliveryDate: { label: 'Aangevraagd' },
      promisedDeliveryDate: { label: 'Beloofd' },
      lastDatetimeModified: { label: 'Laatst Aangepast' },
      deliveryAddress: { label: 'Adresregel 1' },
      deliveryAddress2: { label: 'Adresregel 2' },
      deliveryCity: { label: 'Stad' },
      deliveryPostcode: { label: 'Postcode' },
      deliveryCountryRegionCode: { label: 'Regio' },
      sellToAddress: { label: 'Adresregel 1' },
      sellToAddress2: { label: 'Adresregel 2' },
      sellToCity: { label: 'Stad' },
      sellToPostcode: { label: 'Postcode' },
    },
  },
  resourceAllocation: {
    field: {
      source: { label: 'Bron' },
      productionOrder: { label: 'Productieorder' },
      supplier: { label: 'Leverancier' },
      reference: { label: 'Referentie' },
      expectedDate: { label: 'Verwachte datum' },
      otherSalesOrders: { label: 'Andere Verkooporders' },
      quantity: { label: 'Aantal' },
    },
  },
  salesOrderResponsibility: {
    field: {
      name: { label: 'Naam' },
      company: { label: 'Bedrijf' },
      role: { label: 'Rol' },
      email: { label: 'Email' },
      phoneNumber: { label: 'Telefoonnummer' },
    },
  },
  exactAccount: {
    field: {
      number: { label: 'Exact Nummer' },
    },
  },
  exactSalesOrder: {
    field: {
      id: { label: 'ID' },
      number: { label: 'Nummer' },
      deliverAt: { label: 'Leveren Op' },
      orderedAt: { label: 'Besteld Op' },
      modified: { label: 'Aangepast Op' },
    },
  },
  exactAddress: {
    field: {
      addressLine1: { label: 'Adresregel 1' },
      addressLine2: { label: 'Adresregel 2' },
      addressLine3: { label: 'Adresregel 3' },
      city: { label: 'Stad' },
      postcode: { label: 'Postcode' },
      countryName: { label: 'Land' },
    },
  },
  scriptAllocation: {
    field: {
      startAt: { label: 'Start Op' },
      endAt: { label: 'Eind Op' },
      script: { label: 'Script' },
      locations: { label: 'Locaties' },
      setpoints: { label: 'Setpoints' },
    },
  },
  printModal: {
    title: 'Print {{item}}',
    selectPrinter: 'Selecteer Printer',
    noPrintersAvailable: 'Geen Printers Beschikbaar',
    printButton: 'Print',
    copies: 'Aantal Kopieën',
  },
  metafield: {
    overview: {
      title: 'Metavelden',
      empty: 'Geen metavelden nodig.',
      addButton: 'Metavelden',
    },
    create: {
      title: 'Metaveld Aanmaken',
    },
    edit: {
      title: 'Metaveld Aanpassen',
    },
    field: {
      name: { label: 'Naam' },
      entryLevel: {
        label: 'Invoerniveau',
        value: {
          article_type: 'Artikeltype',
          load_carrier: 'Ladingdrager',
        },
      },
      type: {
        label: 'Type',
        value: {
          text: 'Tekst',
          check: 'Check',
          image: 'Afbeelding',
          choice: 'Keuze',
          measure: 'Meting',
          format: 'Formaat',
        },
      },
      textLong: { label: 'Lang' },
      choiceOptions: { label: 'Opties' },
      choiceMultiple: { label: 'Meerdere' },
      measureMin: { label: 'Minimumwaarde' },
      measureMax: { label: 'Maximumwaarde' },
      formatFormat: { label: 'Formaat' },
      default: { label: 'Standaard' },
    },
  },
  movement: {
    overview: {
      title: 'Bewegingen',
      buffer: 'Buffer',
      unset: 'Niet gezet',
      fromMachine: 'Van Machine',
      toMachine: 'Naar Machine',
      bulkTimeButton: 'Zet Tijd ({{count}})',
    },
    edit: {
      title: 'Beweging aanpassen',
    },
    field: {
      id: { label: 'ID' },
      status: {
        label: 'Status',
        value: {
          planned: 'Gepland',
          ready: 'Stand-by',
          moving: 'Bewegende',
          moved: 'Gereed',
        },
      },
      loadCarrier: { label: 'Ladingdrager' },
      date: { label: 'Datum' },
      time: { label: 'Tijd' },
      readyAt: { label: 'Stand-by op' },
      movingAt: { label: 'Bewegende op' },
      movedAt: { label: 'Gereed op' },
      from: { label: 'Van' },
      to: { label: 'Naar' },
    },
  },
  issue: {
    create: {
      title: 'Probleem aanmaken'
    },
    edit: {
      title: 'Probleem bewerken',
      newComment: 'Nieuwe Comment',
      sendComment: 'Verstuur Comment (CTRL + ENTER)',
      button: {
        resolve: 'Probleem oplossen',
      },
    },
    field: {
      name: { label: 'Titel' },
      responsible: {
        label: 'Verantwoordelijke' ,
        placeholder: 'Filter op verantwoordelijke ...',
        empty: 'Geen verantwoordelijke partij',
      },
      status: {
        label: 'Status',
        value: {
          red: 'Rood',
          orange: 'Oranje',
          green: 'Groen',
          purple: 'Paars',
          grey: 'Grijs',
        },
      },
      description: { label: 'Omschrijving' },
      process: {
        label: 'Proces',
        placeholder: 'Filter op processen ...',
      },
      createdAt: { label: 'Aangemaakt op' },
      resolved: { label: 'Opgelost' },
      resolvedAt: { label: 'Opgelost op' },
      blocking: {
        label: 'Blokkeer locaties',
      },
    },
    overview: {
      addButton: 'Voeg probleem toe',
      addTopicButton: 'Voeg process stap toe',
      confirmDelete: 'Weet je zeker dat je {{issue}} wilt verwijderen?',
      confirmResolveModalTitle: 'Probleem oplossen?',
      confirmResolve: 'Weet je zeker dat je {{issue}} wilt oplossen?',
      layer: 'LAAG {{layer}}',
      stakeholderFilter: {
        label: 'Leverancier',
        placeholder: 'Alle Leveranciers',
      },
    },
    filters: {
      resolved: {
        label: 'Toon opgeloste problemen',
        value: {
          show: 'Toon',
          hide: 'Verberg',
        },
      },
    },
  },
  lms: {
    overview: {
      sidebarFilter: {
        label: 'Filter',
        placeholder: 'Type om te zoeken of filteren ...',
      }
    },
    sensors: {
      overview: {
        title: 'Sensoren',
        empty: 'Geen sensoren gevonden',
        addToGroupButton: 'Voeg toe aan groep',
        removeFromGroupButton: 'Verwijder uit group',
        createScenarioButton: 'Maak een scenario',
        createSensorGroupButton: 'Maak een sensorgroep',
        fetchSensorsButton: 'Haal sensoren op',
        sensorGroupAddModal: {
          title: 'Voeg toe aan groep',
          target: {
            label: 'Groep',
          },
          cancelLabel: 'Annuleren',
          proceedLabel: 'Voeg toe aan groep',
        },
        sensorGroupRemoveModal: {
          title: 'Verwijder uit groep',
          target: {
            label: 'Groep(en) om de sensor uit te verwijderen',
          },
          cancelLabel: 'Annuleren',
          proceedLabel: 'Verwijder uit groep',
        },
        sensorGroupEditModal: {
          title: 'Pas een sensorgroep aan',
          create: {
            title: 'Maak een sensorgroep',
          },
          name: {
            label: 'Naam',
          },
          color: {
            label: 'Kleur',
            value: {
              grey: 'Grijs',
              red: 'Rood',
              orange: 'Oranje',
              green: 'Groen',
              blue: 'Blauw',
              purple: 'Paars',
            }
          }
        }
      }
    },
    tracyPocket: {
      runCommand: 'Uitvoeren',
      message: {
        success: 'Succesvol verstuurd',
        error: 'Probleem tijdens het versturen van het bericht',
      }
  },
    building_alarms: {
      overview: {
        title: "Gebouw alarmen",
        empty: 'Geen gebouw alarmen'
      }
    },
    alarms: {
      overview: {
        title: 'Huidige alarmen',
        empty: 'Geen alarmen gevonden',
      },
      field: {
        id: {
          label: 'ID',
        },
        scenario: {
          label: 'Scenario',
        },
        status: {
          label: 'Status',
          value: {
            resolved: 'Opgelost',
            unresolved: 'Niet opgelost',
          }
        },
        claimedBy: {
          label: 'Geclaimd door',
          unclaimed: 'Niet geclaimd',
        },
        claimedAt: {
          label: 'Geclaimd op',
        },
        triggeredAt: {
          label: 'Getriggerd op',
          timeAgo: 'geleden',
        }
      },
    },
    contentTemplate: {
      overview: {
        title: 'Inhoudsjablonen',
        empty: 'Geen inhoudsjablonen gevonden',
        addButton: 'Inhoudsjabloon',
        filter: {
          name: {
            label: 'Naam',
            placeholder: 'Zoek op naam',
          },
          medium: {
            label: 'Medium',
            placeholder: 'Zoek op medium',
          },
        }
      },
      field: {
        name: {
          label: 'Naam',
        },
        medium: {
          label: 'Medium',
          value: {
            email: 'E-mail',
            sms: 'SMS',
            voice: 'Spraakbericht',
            whatsapp: 'WhatsApp',
            telegram: 'Telegram',
          }
        },
        content: {
          label: 'Inhoud',
          placeholder: 'Selecteer inhoudsjabloon',
        },
      },
      edit: {
        title: 'Pas inhoudsjabloon aan',
      },
      create: {
        title: 'Maak inhoudsjabloon aan',
      }
    },
  },
  sensor: {
    field: {
      id: {
        label: 'ID',
        placeholder: 'Zoek op ID',
      },
      source: {
        label: 'Bron',
      },
      rk: {
        label: 'RK',
        placeholder: 'Zoek op RK',
      },
      pid: {
        label: 'Proces ID',
        placeholder: 'Zoek op proces ID',
      },
      pname: {
        label: 'Procesnaam',
        placeholder: 'Zoek op procesnaam',
      },
      rawSensorName: {
        label: 'Ruwe sensornaam',
        placeholder: 'Zoek op sensornaam',
      },
      translatedName: {
        label: 'Vertaalde naam',
        placeholder: 'Zoek op vertaalde naam',
      },
      relatedSensor: {
        label: 'Gerelateerde sensor',
        placeholder: 'Zoek...',
      },
      thresholdType: {
        label: 'Type drempelwaarde',
        value: {
          relative: 'Percentage',
          absolute: 'Absoluut',
        },
      },
      mostRecentValue: {
        label: 'Meest recente waarde',
      },
      minorMinMax: {
        label: 'Minor min/max',
        filterLabel: 'Minor min/max ingesteld?',
      },
      majorMinMax: {
        label: 'Major min/max',
        filterLabel: 'Major min/max ingesteld?',
      },
      delay: {
        label: 'Wachttijd',
      },
      groups: {
        label: 'Groepen',
      },
      relatedTopic: {
        label: 'Gerelateerde locatie',
        placeholder: 'Selecteer...',
      },
      usage: {
        label: 'Status',
        usedIn: 'Gebruikt in {{count}} scenarios',
        triggeredIn: 'Getriggerd in {{count}} scenarios',
      }
    }
  },
  scenario: {
    overview: {
      title: 'Scenario\'s',
      addButton: 'Scenario',
      noScenarioSelected: 'Geen scenario geselecteerd',
      unsavedChanges: 'Uw scenario heeft onopgeslagen veranderingen.',
      sidebar: {
        title: 'Scenario\'s',
        search: {
          label: 'Zoeken',
          placeholder: 'Zoeken naar een scenario...'
        },
        cannotDeleteActiveScenario: 'U kunt een actief scenario niet verwijderen',
      },
      toolbar: {
        settingsButton: 'Instellingen',
      },
      settingsModal: {
        header: 'Bewerk scenario instellingen',
        sidebar: {
          header: 'Instellingen',
          general: 'Algemeen',
          rateLimiting: 'Rate limiting',
          claims: 'Claims',
        },
      },
      updateTriggered: 'U kunt de actie en toestand van het scenario dat momenteel wordt getriggerd niet bijwerken'
    },
    field: {
      name: { label: 'Naam' },
      status: {
        label: 'Status',
        value: {
          draft: 'Concept',
          ready: 'Gereed',
          active: 'Actief',
        },
      },
      claimExpiration: {
        enabled: {
          label: 'Claim verloop aanzetten',
          description: 'Zet claim verloop aan of uit voor dit scenario.',
        },
        time: {
          label: 'Claim verloop tijd',
          description: 'De tijd tot een alarm automatisch ongeclaimd wordt.',
          placeholder: 'Voer een getal in',
          unit: 'minuten',
        },
      },
      basicRateLimiting: {
        label: 'Basis rate limiting',
        description: 'Als een scenario {{x}} keer wordt getriggerd in {{y}} minuten, mag het geen notificaties versturen voor de volgende {{z}} minuten.',
        descriptionDisabled: 'Als een scenario te vaak wordt getriggerd in een korte periode, mag het geen notificaties versturen voor een tijdje.',
        fields: {
          enabled: {
            label: 'Rate limiting aanzetten',
            description: 'Zet rate limiting aan of uit voor dit scenario.',
          },
          maxTriggers: {
            label: 'Max triggers',
            description: 'Het maximale aantal keren dat een scenario kan worden getriggerd in de gegeven tijd.',
            placeholder: 'Voer een getal in',
          },
          timeframe: {
            label: 'Tijd',
            description: 'De tijd waarin het scenario het maximale aantal keren kan worden getriggerd.',
            placeholder: 'Voer een getal in',
            unit: 'minuten',
          },
          duration: {
            label: 'Duur',
            description: 'De tijd waarin het scenario geen notificaties mag versturen nadat het maximale aantal triggers is bereikt.',
            placeholder: 'Voer een getal in',
            unit: 'minuten',
          },
        },
      },
      advancedRateLimiting: {
        label: 'Geavanceerde rate limiting (bundeling)',
        description: 'Als er een grote hoeveelheid notificaties in een korte tijd wordt gegenereerd, bundel deze dan in één notificatie.',
        fields: {
          enabled: {
            label: 'Geavanceerde rate limiting aanzetten',
            description: 'Zet geavanceerde rate limiting aan of uit voor dit scenario.',
          },
        },
      },
    },
    node: {
      add: {
        errors: {
          'data is not a dict': 'Deze node kan niet leeg zijn',
        },
      },
      reger: {
        label: 'Reger emails',
        add: 'Reger emails',
      },
      sensor: {
        label: '{{name}} — {{threshold}}',
        noneSelected: 'Geen sensor geselecteerd',
        add: 'Sensor',
        field: {
          multiplicity: {
            label: 'Veelvoud',
            value: {
              sensor: 'Sensor',
              sensorGroup: 'Groep',
            },
          },
          sensor: {
            label: 'Sensor',
            placeholder: 'Type om te zoeken naar een sensor',
          },
          threshold: {
            label: 'Drempelwaarde',
            value: {
              minor: 'Minor',
              major: 'Major',
            },
          },
        },
        errors: {
          'data is not a sensor': 'Geen sensor geselecteerd',
          'action immutable for triggered scenario': 'Kan actie niet bijwerken wanneer scenario wordt getriggerd',
          'condition immutable for triggered scenario': 'Kan voorwaarde niet bijwerken wanneer scenario wordt getriggerd',
        }
      },
      sensorGroup: {
        label: '{{name}} — {{threshold}} — {{relation}}',
        noneSelected: 'Geen sensorgroep geselecteerd',
        add: 'Sensorgroep',
        field: {
          multiplicity: {
            label: 'Veelvoud',
            value: {
              sensor: 'Sensor',
              sensorGroup: 'Groep',
            }
          },
          sensorGroup: {
            label: 'Sensorgroep',
            placeholder: 'Type om te zoeken naar een sensorgroep',
          },
          threshold: {
            label: 'Drempelwaarde',
            value: {
              minor: 'Minor',
              major: 'Major',
            },
          },
          relation: {
            label: 'Relatie',
            value: {
              and: 'AND',
              or: 'OR',
            },
            explanation: {
              and: 'alle',
              or: 'een of meer van de'
            }
          },
          explanation: {
            label: 'Uitleg',
            content: 'Als {{relation}} sensoren in de group hun {{threshold}} drempelwaarde overstijgen, zal deze node getriggered zijn.'
          }
        },
        errors: {
          'data is not a sensor group': 'Geen sensorgroep geselecteerd',
        },
      },
      not: {
        label: 'Not',
        add: 'Not',
      },
      and: {
        label: 'And',
        add: 'And',
        field: {
          type: { label: 'Type' },
          explanation: {
            label: 'Uitleg',
            content: 'Dit is een AND node. Deze node is waar als en alleen als alle subnodes ook waar zijn.',
          }
        },
        errors: {
          'data is not greater than or equal to 2': 'Een AND node moet minstens twee subnodes hebben.'
        }
      },
      or: {
        label: 'Or',
        add: 'Or',
        field: {
          type: { label: 'Type' },
          explanation: {
            label: 'Uitleg',
            content: 'Dit is een OR node. Deze node is waar als en alleen als minstens één van de subnodes ook waar is.',
          }
        },
        errors: {
          'data is not greater than or equal to 2': 'Een OR node moet minstens twee subnodes hebben.'
        }
      },
      split: {
        label: 'Splitsing',
        add: 'Splitsing',
        errors: {
          'data is not greater than or equal to 2': 'Een SPLIT node moet minstens twee subnodes hebben.'
        }
      },
      time: {
        label: 'Tijd',
        add: 'Tijd',
        field: {
          slots: {
            label: 'Tijdsloten',
            add: 'Tijdslot',
          },
        },
        day: {
          monday: 'Ma',
          tuesday: 'Di',
          wednesday: 'Wo',
          thursday: 'Do',
          friday: 'Vr',
          saturday: 'Za',
          sunday: 'Zo',
        },
      },
      issue: {
        label: 'Probleem',
        add: 'Probleem',
        field: {
          priority: { label: 'Prioriteit' },
        },
      },
      notification: {
        label: 'Notificatie',
        add: 'Notificatie',
        noMediaSelected: 'Geen medium geselecteerd',
        noContentSelected: 'Geen inhoud geselecteerd',
        field: {
          media: {
            label: 'Medium',
            value: {
              email: 'E-mail',
              sms: 'SMS',
              voice: 'Spraakbericht',
              whatsapp: 'WhatsApp',
              telegram: 'Telegram',
            },
          },
          content: {
            label: 'Inhoudssjabloon',
            preview: 'Voorbeeld',
          },
          recipients: { label: 'Ontvangers' },
          executeInterval: {
            label: "Opnieuw Versturen",
            description: "Stuur de melding weer om de",
            minutes: "minuten",
          }
        },
        recipient: {
          type: {
            user: {
              label: 'Gebruiker',
              placeholder: 'Type om te zoeken naar een gebruiker',
            },
            group: {
              label: 'Groep',
              placeholder: 'Type om te zoeken naar een groep',
            },
          },
          placeholder: 'Voeg ontvanger toe',
          noneSelected: 'Geen ontvangers geselecteerd',
        },
        errors: {
          'data is not a content': 'Geen inhoudssjabloon geselecteerd',
          'data is not a user': 'Geen notificatie ontvanger geselecteerd',
          'data is not a group': 'Geen notificatie ontvanger geselecteerd',
          'key is not allowed': 'Ontvanger type error (key not allowed)',
          'key is missing': 'Ontvanger type error (key missing)',
        },
      },
      wait: {
        label: 'Wacht {{duration}}',
        add: 'Wacht',
        field: {
          duration: { label: 'Wachttijd' },
        },
        errors: {
          'data is not greater than 0': 'Wachttijd moet groter zijn dan 0',
        }
      },
    },
    tooltip: {
      undo: '{{text}} ongedaan maken',
      redo: '{{text}} herhalen',
      cut: 'Knippen',
      copy: 'Kopiëren',
      paste: 'Plakken',
      delete: 'Verwijderen',
      deselect: 'Deselecteren',
    },
    action: {
      add: {
        sensor: 'Sensor toevoegen',
        sensorGroup: 'Sensorgroep toevoegen',
        and: 'And toevoegen',
        or: 'Or toevoegen',
        not: 'Not toevoegen',
        split: 'Splitsing toevoegen',
        time: 'Tijd toevoegen',
        issue: 'Probleem toevoegen',
        notification: 'Notificatie toevoegen',
        wait: 'Wacht toevoegen',
      },
      move: {
        sensor: 'Sensor verplaatsen',
        sensorGroup: 'Sensorgroep verplaatsen',
        and: 'And verplaatsen',
        or: 'Or verplaatsen',
        not: 'Not verplaatsen',
        split: 'Splitsing verplaatsen',
        time: 'Tijd verplaatsen',
        issue: 'Probleem verplaatsen',
        notification: 'Notificate verplaatsen',
        wait: 'Wacht verplaatsen',
      },
      delete: {
        sensor: 'Sensor verwijderen',
        sensorGroup: 'Sensorgroep verwijderen',
        and: 'And verwijderen',
        or: 'Or verwijderen',
        not: 'Not verwijderen',
        split: 'Split verwijderen',
        time: 'Tijd verwijderen',
        issue: 'Probleem verwijderen',
        notification: 'Notificatie verwijderen',
        wait: 'Wacht verwijderen',
      },
      changeRelation: {
        and: 'Veranderen naar and',
        or: 'Veranderen naar or',
      },
      cut: {
        sensor: 'Sensor knippen',
        sensorGroup: 'Sensorgroep knippen',
        and: 'And knippen',
        or: 'Or knippen',
        not: 'Not knippen',
        split: 'Splitsing knippen',
        time: 'Tijd knippen',
        issue: 'Probleem knippen',
        notification: 'Notificatie knippen',
        wait: 'Wacht knippen',
      },
      paste: {
        sensor: 'Sensor plakken',
        sensorGroup: 'Sensorgroep plakken',
        and: 'And plakken',
        or: 'Or plakken',
        not: 'Not plakken',
        split: 'Splitsing plakken',
        time: 'Tijd plakken',
        issue: 'Probleem plakken',
        notification: 'Notificatie plakken',
        wait: 'Wacht plakken',
      },
      changeSensorSensor: 'Verander sensor naar {{name}}',
      changeSensorThreshold: {
        minor: 'Verander sensor drempelwaarde naar minor',
        major: 'Verander sensor drempelwaarde naar major',
      },
      changeSensorGroupSensorGroup: 'Verander sensorgroep naar {{name}}',
      changeIssuePriority: {
        red: 'Verander probleemprioriteit naar rood',
        orange: 'Verander probleemprioriteit naar oranje',
        green: 'Verander probleemprioriteit naar groen',
        purple: 'Verander probleemprioriteit naar paars',
        grey: 'Verander probleemprioriteit naar grijs',
      },
      changeNotificationMedium: {
        email: 'Verander notificatiemedium naar e-mail',
        sms: 'Verander notificatiemedium naar sms',
        voice: 'Verander notificatiemedium naar spraakbericht',
        whatsapp: 'Verander notificatiemedium naar WhatsApp',
        telegram: 'Verander notificatiemedium naar Telegram',
      },
      changeNotificationTemplate: 'Verander notificatiesjabloon naar {{template}}',
      addNotificationRecipient: 'Notificatieontvanger toevoegen',
      changeNotificationRecipient: 'Verander notificatieontvanger naar {{recipient}}',
      changeNotificationRecipientType: {
        user: 'Verander notificatieontvanger type naar gebruiker',
        group: 'Verander notificatieontvanger type naar groep',
      },
      deleteNotificationRecipient: 'Notificatieontvanger verwijderen',
      addTimeSlot: 'Tijdslot toevoegen',
      deleteTimeSlot: 'Tijdslot verwijderen',
      addTimeSlotLine: 'Tijdslotregel toevoegen',
      deleteTimeSlotLine: 'Tijdslotregel verwijderen',
      toggleTimeSlotDay: 'Tijdslotdag veranderen',
      changeTimeSlotStart: 'Tijdslotstart veranderen',
      changeTimeSlotEnd: 'Tijdsloteind veranderen',
      changeWaitDuration: 'Verander wachttijd naar {{duration}}',
    },
  },
  viewports: {
    scm: {
      overview: {
        title: 'Supply chain manager',
        tabs: {
          dailyHarvestTarget: 'Dageijkse oogsttarget',
          map: 'Container view',
          issues: 'Technische problemen',
          movements: 'Geplande bewegingen',
          containers: 'Containers',
        },
        issues: {
          field: {
            id: { label: 'Probleem ID' },
            status: { label: 'Prio' },
            process: { label: 'Proces' },
            issue: { label: 'Probleem' },
          },
          view: 'Bekijk probleem op de map',
          edit: 'Bewerken',
        },
        blocks: {
          field: {
            row: { label: 'Rij' },
            startDate: { label: 'Start' },
            endDate: { label: 'Eind' },
          },
          view: 'Bekijk block op kaart',
          edit: 'Bewerken',
        },
      },
    },
  },
  topic: {
    field: {
      name: { label: 'Naam' },
      responsible: { label: 'Verantwoordelijke' },
      status: {
        label: 'Status',
        value: {
          red: 'Rood',
          orange: 'Oranje',
          green: 'Groen',
          purple: 'Paars',
          grey: 'Grijs',
        },
      },
      locations: {
        label: 'Locaties',
        placeholder: 'Filter op locations ...',
      },
      processStep:{
        label: 'Proces stap',
        placeholder: 'Filter op proces stap',
      },
    },
    edit: {
      title: 'Bewerk process stap',
    },
    create: {
      title: 'Voeg Process stap toe',
    },
  },
  graphs:{
    overview:{
      issues: {
        label: '{{issueCount}} Problemen'
      },
      nutrition:{
        label: "Voeding"
      },
      water:{
        label: "Water"
      },
      light:{
        label: "Licht"
      },
      enviroment:{
        label: "Omgeving"
      },
      masterGraph:{
        label: "Hoofd grafiek"
      },
      types: {
        nutrition:{
          label: 'Voeding',
          subType: {
            nutrition: {
              label: 'Voeding'
            }
        }

        },
        multilayer:{
          label: 'Multilayer',
          subType: {
            water: {
              label: 'Water'
            },
            enviroment: {
              label: 'Omgeving'
            },
            research: {
              label: 'Algemeen'
            },
            lights: {
              label: 'Lamp {{lamp}}'
            },
        }
        },
        highwire:{
          label: 'Highwire',
          subType: {
            water: {
              label: 'Water'
            },
            enviroment: {
              label: 'Omgeving'
            },
            research: {
              label: 'Algemeen'
            },
        },

        },
        rnd:{
          label: 'R&D',
          subType: {
          research: {
            label: "Onderzoek"
          },
          nutrition: {
            label: "Voeding"
          },
          environment: {
            label: "Omgeving"
          }
        }

        },
        lights:{
          label: 'Lichten',
          subType: {
          lamps: {
            label: "Lamp {{lamp}}"
          }
        }

        },
      }
    }
  },
  library:{
    nav: {
      scripts: {
        header: "Scripts",
        rnd: "R&D scripts",
        tryout: "Tryout scripts",
        production: "Production scripts",
        approved: 'Goedgekeurd door Tryout',
        disapproved: 'Afgekeurd door Tryout',
      },
      recipes: {
        header: "Recepten",
        climate: "Climate recept",
        fertilzer: "Fertilizer recept",
      },
      chapter:{
        header: "Chapters",
        overview: "Overview",
        climate: 'Klimaat',
        fertilizer: 'Meststoffen',
        irrigation: 'Irrigatie',
        light: 'Licht',
        nutritionstorage: 'Nutrition Storage',
      }
    }
  },
  scriptPackage:{
    edit:{
      title:'Wijzig Script',
      phase: 'Fase',
    },
    mergeTool:{
      filterName:'Hoofdstuk naam / ID',
      filterPlaceholder:'Geef input ...',
      scriptList:{
        title: 'Selecteer 1 hoofdstuk voor ieder type',
        running: 'Toegepast in',
        used: 'Gebruikt in',
        locations: 'locaties',
      },
    },
    release: {
      canRelease: 'Goedkeuren',
      cannotRelease: 'Script kan niet worden goedgekeurd, omdat het hoofdstukken mist',
      approve: 'Goedkeuren',
      disapprove: 'Afkeuren',
      rejectModalHeader: 'Afkeuren',
      rejectModalContent: 'voeg de reden voor afkeuring toe in de notities',
      approveModalHeader: 'Goedkeuren',
      approveModalContent: 'Weet je zeker dat je dit script wilt goedkeuren?',
      archiveModalContent: "Weet u zeker dat u dit script wilt archiveren?",
      deleteModalContent: "U gaat dit script permanent verwijderen. Deze actie kan niet ongedaan gemaakt worden. Weet u het zeker?",
      missingApprovePermission: "U mist de machtiging om dit goed te keuren",
      missingDisapprovePermission: "U mist de machtiging om dit af te keuren",
      missingCancelPermission: "U mist de machtiging om deze goedkeuring terug te trekken",
    },
    overview: {
      empty: "Geen scripts gevonden",
      title: "Scripts",
      release: "Uitbrengen",
      unrelease: "Terug zetten",
      releaseTo: "Uitbrengen naar",
      unreleaseTo: "Terug zetten naar",
      rnd: 'R&D',
      disapprovedByTryout: 'Afgekeurd door Tryout',
      tryout: 'Tryout',
      approvedByTryout: 'Goedgekeurd door Tryout',
      production: 'Productie',
      cancelApproval: 'Goedkeuring terug nemen',
      cancel: 'Terugnemen',
      cancelDisapproval: 'Afkeuring terug nemen',
    },
    field:{
      name:{
        label:'R&D Naam',
      },
      tryoutDescription:{
        label:'Tryout omschrijving',
      },
      suffix:{
        label:'Script Voorvoegsel',
      },
      status:{
        label:'Status',
      },
      plantationArea:{
        label:'Plantatie Omgeving',
      },
      crop:{
        label:'Gewas',
      },
      duration:{
        label:'Duur',
      },
      fixedDuration:{
        label:'Vaste Duur',
      },
      notes:{
        label:'Notities',
      },
    },
    filter: {
      search: {
        label: 'Script',
        placeholder: 'Begin met typen om naar een script te zoeken',
      },
      plantationArea: {
        label: 'Plantageomgevingen',
        option: {
          multilayer: 'Multilayer',
          highwire: 'Highwire',
          rnd: 'R&D'
        }
      },
      climate: {
        label: 'Klimaat',
        placeholder: 'Selecteer klimaat hoofdstuk',
      },
      fertilizer: {
        label: 'Fertilizer',
        placeholder: 'Selecteer fertilizer hoofdstuk',
      },
      status: {
        label: 'Status',
        option: {
          active: 'Actief',
          archived: 'Gearchiveerd',
          all: 'Allemaal',
        }
      },
      currentlyRunning: {
        label: 'Op dit moment toegepast',
        option: {
          yes: 'Ja',
          no: 'Nee',
          all: 'Allemaal',
        }
      },
    }
  },
  notification:{
    overview:{
      title: 'Notificaties',
      empty: 'Er zijn geen notificaties die aan de zoekcriteria voldoen',
      filter: {
        view: {
          label: 'Individueel',
          value: {
            individual: 'Individueel',
            combined: 'Gecombineerd',
          },
        },
      },
    },
    field: {
      type: {
        label: "Type",
        value: {
          email: 'E-mail',
          sms: 'SMS',
          whatsapp: 'WhatsApp',
          telegram: 'Telegram',
          voice: 'Spraakbericht',
          bundle: 'Bundle',
        }
      },
      status: {
        label: "Status",
        value: {
          initialization_pending: 'Initialisatie in behandeling',
          initialization_failed: 'Initialisatie mislukt',
          delivery_pending: 'Verzending in behandeling',
          delivery_failed: 'Afgewezen',
          delivery_success: 'Verzonden',
        },
      },
      scenario: {
        label: "Scenario",
        placeholder: "Klik om scenario te selecteren"
      },
      receivers: {
        label: "Ontvangers",
      },
      dateSent: {
        label: "Datum verzonden",
      },
      notificationText: {
        label: "Inhoud",
      },
    }
  },
  simulation: {
    movements: '{{count}} beweging uitgevoerd.',
    movements_plural: '{{count}} bewegingen uitgevoerd.',
    active: '{{time}} Actieve tijd.',
    modal: {
      title: 'Simulatie instellingen',
      date: { label: 'Datum' },
      shuttles: {
        label: 'Shuttles',
        value: '{{count}} shuttle',
        value_plural: '{{count}} shuttles',
      },
      sameLayer: { label: 'Zelfde laag' },
      nearLeftToRight: { label: 'Dichtbij links naar rechts' },
      aduEnabled: { label: 'ADU Actief' },
      automaticReorganize: { label: 'Automatisch Aanduwen' },
      simulateButton: 'Simuleer',
    },
  },
  live: {
    tab: {
      processes: 'Processen',
      autoMove: 'Autoverplaatsingen',
      issues: 'Problemen',
    },
    status: {
      moveButton: 'Verplaats',
      enabled: 'Ingeschakeld',
      disabled: 'Uitgeschaked',
      manualCharge: 'Handmatig opladen aanzetten',
      autoCharge: 'Automatisch opladen aanzetten',
      tracyControlled: 'Aangestuurd door Tracy',
    },
    selected: {
      container: 'Container {{id}}',
      shuttle: 'Shuttle {{id}}',
      lift: 'Lift {{id}}',
      block: 'Block {{id}}',
      bridge: 'Brug {{id}}',
      machine: 'Machine {{id}}',
      push_pull: 'Push pull {{id}}',
      moveButton: 'Verplaats',
      enableButton: 'Inschakelen',
      disableButton: 'Uitschakelen',
      manualChargeButton: 'Handmatig Opladen',
      autoChargeButton: 'Automatisch Opladen',
      correctButton: 'Corrigeren',
      forgetButton: 'Vergeten',
      seedingButton: 'Zaaien',
      transplantingButton: 'Transplanten',
      spacing1Button: 'Spacing 1',
      spacing2Button: 'Spacing 2',
      harvestingButton: 'Oogsten',
      setContainerButton: 'Zet Container',
      scrapButton: 'Schrap',
    },
    blockButton: 'Blokkeren',
    moveModal: {
      title: 'Verplaats',
      content: {
        preSubject: 'Weet je zeker dat je ',
        subject: {
          container: 'container {{id}}',
          shuttle: 'shuttle {{id}}',
        },
        postSubjectPreLocation: ' wilt verplaatsen naar ',
        postLocation: '?',
      },
    },
    correctModal: {
      title: 'Corrigeren',
      content: {
        preSubject: 'Weet je zeker dat je ',
        subject: 'container {{id}}',
        postSubjectPreLocation: ' wilt corrigeren naar ',
        postLocation: '?',
      },
    },
    forgetModal: {
      title: 'Vergeten',
      content: {
        preSubject: 'Weet je zeker dat je ',
        subject: 'container {{id}}',
        postSubject: ' wilt vergeten?',
      },
    },
    performModal: {
      title: {
        seeding: 'Zaaien',
        transplanting: 'Transplanten',
        spacing1: 'Spacing 1',
        spacing2: 'Spacing 2',
        harvesting: 'Oogsten',
      },
      content: {
        preSubject: {
          seeding: 'Weet je zeker dat je ',
          transplanting: 'Weet je zeker dat je ',
          spacing1: 'Weet je zeker dat je ',
          spacing2: 'Weet je zeker dat je ',
          harvesting: 'Weet je zeker dat je ',
        },
        postSubject: {
          seeding: ' wilt zaaien',
          transplanting: ' wilt transplanten',
          spacing1: ' wilt spacen 1',
          spacing2: ' wilt spacen 2',
          harvesting: ' wilt oogsten',
        },
        subject: 'container {{container}} (batch {{batch}})',
        preContainers: ' in ',
        container: 'container {{container}}',
        and: ' en ',
        postContainers: '?',
      },
    },
    setContainerModal: {
      title: 'Zet Container',
      content: {
        preSubject: 'Weet je zeker dat je de container van ',
        postSubjectPreContainer: ' wilt veranderen naar ',
        container: 'container {{container}}',
        postContainer: '?',
      },
    },
    scrapModal: {
      title: 'Schrappen',
      content: {
        preSubject: 'Weet je zeker dat je ',
        subject: 'container {{container}} (batch {{batch}})',
        postSubject: ' wilt schrappen?',
      },
    },
    selectProductionRequestModal: {
      title: 'Werkorder Selecteren',
      containers: 'Containers',
    },
    blockModal: {
      title: {
        block: 'Blokkeren',
        unblock: 'Deblokkeren',
      },
      content: {
        preLocation: {
          block: 'Weet je zeker dat je ',
          unblock: 'Weet je zeker dat je ',
        },
        postLocation: {
          block: ' wilt blokkeren?',
          unblock: ' wilt deblokkeren?',
        },
      },
      actions: {
        blockDirectly: 'Blokkeer direct',
        blockWithIssue: 'Blokkeren met probleem',
        cancel: 'Annuleren',
      },
    },
    toggleLeftToRightModal: {
      title: {
        leftToRight: 'Links naar Rechts',
        rightToLeft: 'Rechts naar Links',
      },
      content: {
        preRow: 'Weet je zeker dat je ',
        postRow: {
          leftToRight: ' wilt veranderen naar links naar rechts?',
          rightToLeft: ' wilt veranderen naar rechts naar links?',
        },
      },
    },
    reorganizeModal: {
      title: 'Aanduwen',
      content: {
        preRow: 'Weet je zeker dat je ',
        postRow: ' wilt aanduwen?',
      },
    },
    cancelModal: {
      title: 'Annuleren',
      content: {
        preDescription: 'Weet je zeker dat je ',
        postDescription: ' wilt annuleren?',
      },
    },
    resolveModal: {
      title: 'Oplossen',
      content: {
        preDescription: 'Weet je zeker dat je ',
        postDescription: ' wilt oplossen?',
      },
    },
    lastSync: 'Laatst gesynchroniseerd om {{lastSync}} ({{timePassed}} geleden)',
    blockSelect: 'Locatie aan het selecteren om te blokkeren...',
    leftToRightButton: 'Links naar Rechts',
    reorganizeButton: 'Aanduwen',
    toggleLeftToRightSelect: 'Locatie aan het selecteren om links naar rechts te veranderen...',
    reorganizeSelect: 'Locatie aan het selecten om aan te duwen...',
    containerSelect: 'Container aan het selecteren...',
    containerSelect_plural: 'Container {{i}} van de {{count}} aan het selecteren...',
    moveSelect: {
      container: 'Locatie aan het selecteren om container {{id}} naar toe te verplaatsen...',
      shuttle: 'Locatie aan het selecteren om shuttle {{id}} naar toe te verplaatsen...',
    },
    correctSelect:  'Locatie aan het selecteren om container {{id}} te corrigeren...',
    enableModal: {
      title: {
        enable: 'Inschakelen',
        disable: 'Uitschakelen',
      },
      content: {
        preSubject: {
          enable: 'Weet je zeker dat je ',
          disable: 'Weet je zeker dat je ',
        },
        subject: 'shuttle {{id}}',
        postSubject: {
          enable: ' wilt inschakelen?',
          disable: ' wilt uitschakelen?',
        },
      },
    },
    tracyControlModal: {
      title: {
        enable: 'Bestuurt door Tracy',
        disable: 'Bestuurt door DataControl',
      },
      content: {
        preSubject: {
          enable: 'Weet je zeker dat je wilt dat Tracy controle neemt over ',
          disable: 'Weet je zeker dat je wilt dat Tracy de controle loslaat van ',
        },
        subject: 'machine {{id}}',
        postSubject: {
          enable: '?',
          disable: '?',
        },
      },
    },
    autoChargingModal: {
      title: {
        enable: 'Automatisch Opladen Inschakelen',
        disable: 'Automatisch Opladen Uitschakelen',
      },
      content: {
        preSubject: {
          enable: 'Weet je zeker dat je automatisch opladen voor ',
          disable: 'Weet je zeker dat je automatisch opladen voor ',
        },
        subject: 'shuttle {{id}}',
        postSubject: {
          enable: ' wilt inschakelen?',
          disable: ' wilt uitschakelen?',
        },
      },
    },
    process: {
      subject: {
        shuttle: 'Shuttle {{id}}',
        container: 'Container {{id}}',
      },
      event: {
        move: 'Verplaats naar {{location}}',
      },
      inProgress: 'Bezig',
      createdBy: 'Aangemaakt door {{name}}.',
    },
    issue: {
      title: {
        shuttle: 'Probleem met shuttle {{id}}',
        bridge: 'Probleem met brug {{id}}',
        lift: 'Probleem met lift {{id}}',
      },
      resolveButton: 'Oplossen',
    },
    legend: {
      leftToRight: 'Links naar Rechts',
      pushPull: 'Push Pull',
      operationalModeAuto: 'In automatische mode',
      operationalModeNone: 'Geen operationele mode data',
      alarmState: 'Actief alarm',
    },
    forceSingleShuttle: 'Forceer Enkele Shuttle',
    forceSingleShuttleModal: {
      title: 'Forceer enkele Shuttle',
      content: {
        preValue: 'Weet je zeker dat je enkele shuttle forceren wilt ',
        value: { on: 'inschakelen', off: 'uitschakelen' },
        postValue: '?',
      },
    },
    aduEnabled: 'ADUs Ingeschakeld',
    aduEnabledModal: {
      title: 'ADUs Inschakelen',
      content: {
        preValue: 'Weet je zeker dat je ADUs wilt ',
        value: { on: 'inschakelen', off: 'uitschakelen' },
        postValue: '?',
      },
    },
    automaticReorganize: 'Automatisch Aanduwen',
    automaticReorganizeModal: {
      title: 'Automatisch Aanduwen',
      content: {
        preValue: 'Weet je zeker dat je automatisch aanduwen wilt ',
        value: { on: 'inschakelen', off: 'uitschakelen' },
        postValue: '?',
      },
    },
    shuttleSelect: {
      label: 'Voorkeur Shuttle',
      value: 'Shuttle {{shuttleId}}',
    },
    reasonSelect: {
      label: 'Reden',
    },
    autoMove: {
      title: 'Verplaats automatisch van {{location}} naar {{destination}}',
      addButton: 'Toevoegen',
      locationSelect: 'Selecteer startlocatie...',
      destinationSelect: 'Selecteer eindlocatie...',
    },
    addAutoMoveModal: {
      title: 'Automatische Verplaatsing Toevoegen',
      content: {
        preLocation: 'Weet je zeker dat je een automatische verplaatsing wilt toevoegen van ',
        postLocationPreDestination: ' naar ',
        postDestination: '?',
      },
    },
    deleteAutoMoveModal: {
      title: 'Delete Auto Move',
      content: {
        preLocation: 'Weet je zeker dat je de automatische verplaatsing van ',
        postLocationPreDestination: ' naar ',
        postDestination: ' wilt verwijderen?',
      },
    },
  },
  harvestTarget: {
    edit: {
      title: 'Oogst Doel',
      releaseButton: 'Vrijgeven',
      allocationStatus: {
        calculating: 'Berekenen... (Stap 1/2)',
        finalizing: 'Afronden... (Stap 2/2)',
      },
    },
    field: {
      startDate: { label: 'Van' },
      endDate: { label: 'Tot' },
      target: { label: 'Wekelijkse Oogst' },
      margin: { label: 'Veiligheidsmarge' },
      targetWithMargin: { label: 'Wekelijkse zaai' },
      processes: { label: 'Processen' },
    },
  },
  cachedSimulation: {
    overview: {
      title: 'Simulatie Stats',
      openProcesses: {
        title: 'Open Processen',
        field: {
          description: { label: 'Omschrijving' },
          status: { label: 'Status' },
        },
      },
    },
    field: {
      date: { label: 'Datum' },
      activeTime: { label: 'Actieve Tijd' },
      movements: { label: '# Bewegingen' },

      fullContainersStart: { label: '# Vol Start' },
      emptyContainersStart: { label: '# Leeg Start' },
      fullContainersEnd: { label: '# Vol Eind' },
      emptyContainersEnd: { label: '# Leeg Eind' },

      seedingInputTime : { label: 'Zaaien In' },
      seedingBusyTime : { label: 'Zaaien Bezig' },
      seedingOutputTime : { label: 'Zaaien Uit' },
    },
  },
  tracyPocket: {
    status: 'Status',
    runCommand: 'Stuur',
    parameter: {
      lock: 'Vergrendel parameters',
      unlock: 'Ontgrendel parameters',
    },
    message: {
      success: 'Success',
      error: 'Foutmelding',
    },
  },
  experiment: {
    field: {
      active: {
        label: 'Actief',
        value: {
          active: 'Actief',
          inactive: 'Inactief',
        },
      },
      experimentId: {
        label: 'ID',
      },
      experimentName: {
        label: 'Experiment',
      },
      cropType: {
        label: 'Type',
        value: {
          none: {
            title: 'Geen type geselecteerd',
          },
          leafy: {
            title: 'Leafy crop',
            subtitle: 'Kamer 1 tot 10',
          },
          binding: {
            title: 'Viny crop',
            subtitle: 'Kamer 11 tot 20',
          }
        }
      },
      chamber: {
        label: 'Kamer',
      },
      owner: {
        label: 'Eigenaar',
      },
      status: {
        label: 'Status'
      },
      due: {
        label: 'Einddatum',
      },
      lastSync: {
        label: 'Laatste sync',
        value: {
          never: 'Nooit gesynced',
        },
      },
    },
    overview: {
      title: 'Experimenten',
      filters: {
        experimentId: {
          label: 'Experiment ID',
        },
        experimentName: {
          label: 'Experiment naam',
        },
        owner: {
          label: 'Eigenaar',
        },
        chamber: {
          label: 'Kamer',
          placeholder: 'Selecteer kamer',
        },
        due: {
          label: 'Einddatum',
        },
        cropType: {
          label: 'Type',
        },
        deleted: {
          label: 'Verwijderde experimenten',
          value: {
            show: 'Toon',
            hide: 'Verberg',
          },
        },
        rejected: {
          label: 'Afgewezen metingen',
          value: {
            show: 'Toon',
            hide: 'Verberg',
          },
        }
      },
      buttons: {
        addMeasurement: {
          label: 'Voeg meting toe',
        },
        edit: {
          label: 'Bewerk experiment',
        },
        view: {
          label: 'Bekijk experiment',
        },
        sync: {
          label: 'Sync experiment',
        },
      },
      toolbar: {
        sync: {
          label: 'Sync alle experimenten',
        },
        scan: {
          label: 'Scan barcode',
          modal: {
            title: 'Scan barcode',
            description: 'Scan de barcode van het experiment dat je wilt bekijken.',
            placeholder: 'Voer hier het experiment ID in...',
            actions: {
              cancel: 'Annuleren',
              scan: 'Scan',
            }
          }
        },
      },
    },
    edit: {
      title: 'Aanpassen: [{{id}}] {{name}}'
    }
  },
  measurement: {
    field: {
      number: {
        label: 'No.',
      },
      status: {
        label: 'Status',
        value: {
          active: 'Actief',
          rejected: 'Afgewezen',
        },
      },
      timestamp: {
        label: 'Tijdstip',
      },
      author: {
        label: 'Toegevoegd door',
      },
      remarks: {
        label: 'Opmerkingen',
        placeholder: 'Voeg hier opmerkingen toe...',
      },
    },
    overview: {
      measurementAmount: {
        label: 'Metingen ({{amount}})',
      },
      buttons: {
        view: {
          label: 'Bekijk meting',
        },
        reject: {
          reject: 'Wijs meting af',
          unreject: 'Keur meting goed',
        },
      },
    },
    edit: {
      title: {
        add: 'Meting toevoegen: [{{id}}] {{name}}',
        view: 'Meting bekijken: [{{id}}] {{name}}',
      },
      warning: {
        title: 'Waarschuwing',
        description: 'Een toegevoegde meting kan niet worden aangepast, alleen worden afgewezen.',
      },
      treatment: 'Treatment: {{name}}',
      buttons: {
        reject: {
          reject: 'Wijs meting af',
          unreject: 'Keur meting goed',
        },
        cancel: {
          label: 'Annuleren',
        }
      },
    },
  },
  rowBlock: {
    create: {
      title: 'Rij block toevoegen',
    },
    edit: {
      title: 'Rij block aanpassen',
    },
    field: {
      layer: { label: 'Laag' },
      rack: { label: 'Rack' },
      startDate: { label: 'Start' },
      endDate: { label: 'Eind' },
      plantationLine: { label: 'Plantageomgeving' },
    }
  },
}
