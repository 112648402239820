import React from 'react'
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Body, ContentContainer } from 're-cy-cle'
import { Header, Form, Button, Input, Message, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Sidebar, SidebarContent } from 'spider/semantic-ui/Admin/Overview'
import TrippyBackground from 'component/TrippyBackground'
import TextLogo from 'image/logo.svg'
import PropTypes from 'prop-types';
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import SidebarScreen, { BrandingContainer } from 'spider/semantic-ui/SidebarScreen'
import { PrimaryLink, PrimaryLinkContainer } from 'spider/semantic-ui/Login';


const ShowPasswordIcon = styled(Icon)`
    position: absolute;
    color: rgba(0, 0, 0, 0.87) !important;
    opacity: 0.3 !important;
    &:hover {
        opacity: 0.6 !important;
    }
    transition: opacity 300ms ease;
    cursor: pointer;
    margin: 0 !important;
    width: 2.57142857em !important;
    height: 2.57142857em !important;
    line-height: 2.57142857em !important;
    right: 1px;
    bottom: 1px;
`;

const DarkSidebar = styled(Sidebar)`
    background-color: ${({ theme }) => theme.primaryColor} !important;

    &, label, h2 {
        color: #fff !important;
    }

    h2 {
        font-size: 1.3rem;
    }

    input {
        color: rgba(0, 0, 0, 0.87) !important;
    }

    ${SidebarContent} {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }

    ${BrandingContainer} {
        color: rgba(255, 255, 255, 0.25);
        &:hover {
            color: #fff;
            filter: unset;
        }
    }

    a {
        color: ${({ theme }) => theme.secondaryColor};
    }
`;

const BlockLink = styled(Link)`
    display: block;
    margin: 1.5em auto;
`;

const HeaderImg = styled.img`
    height: 6em;
`;

export function sidebarDarkTheme(Screen) {
    return observer(class extends Screen {
        renderHeader() {
            return (
                <React.Fragment>
                    <BlockLink to="/">
                        <HeaderImg src={TextLogo} />
                    </BlockLink>
                    {super.renderHeader()}
                </React.Fragment>
            );
        }

        render() {
            return (
                <Body>
                    <ContentContainer>
                        <TrippyBackground>
                        </TrippyBackground>
                        <DarkSidebar>
                            {this.renderContent()}
                            <div style={{ flex: '1' }} />
                            {this.renderBranding()}
                        </DarkSidebar>
                    </ContentContainer>
                </Body>
            );
        }
    });
}

@sidebarDarkTheme
export default class Login extends SidebarScreen {
    static propTypes = {
        viewStore: PropTypes.object,
        usernameField: PropTypes.string.isRequired,
        afterLogin: PropTypes.func,
    };

    static defaultProps = {
        usernameField: 'email',
    };
    @observable errorMsg = '';
    @observable input = { password: '', [this.props.usernameField]: '' };
    @observable showPassword = false;

    handleSubmit = () => {
        const { usernameField, afterLogin } = this.props;

        this.errorMsg = '';
        this.props.viewStore
            .performLogin(this.input[usernameField], this.input.password, { usernameField: usernameField })
            .then(afterLogin)
            .catch(err => {
                const status = err.response && err.response.status;
                if (status === 403) {
                    this.errorMsg = t('user.login.errors.invalidCredentials');
                } else {
                    console.error(err);
                    this.errorMsg = t('user.login.errors.unknown', {
                        status: status || err.message,
                    });
                }
            });
    };

    renderHeader() {
        return (
            <Header as="h2" content={t('user.login.title')} />
        );
    }

    renderForgotPasswordLink() {
        if(isFeatureFlagEnabled('active_directory')){
            return (<></>)
        }

        return (
            <PrimaryLinkContainer>
                <PrimaryLink to="/login/forgot">
                    {t('user.login.forgotPasswordLink')}
                </PrimaryLink>
            </PrimaryLinkContainer>
        );
    }

    renderForm() {
        const { usernameField, labelColor, submitButtonColor } = this.props;

        return (
            <Form error={this.errorMsg !== ''} style={{ marginTop: '1.5rem' }}>
                <Form.Field>
                    <label style={{'color': labelColor}}>{isFeatureFlagEnabled('active_directory') && isFeatureFlagEnabled('blue_skies') ?  t(`user.field.BSG.label`)  : t(`user.field.${usernameField}.label`)}</label>
                    <Input
                        name={usernameField}
                        value={this.input[usernameField]}
                        onChange={(e, { value }) => this.input[usernameField] = value}
                        autoFocus
                    />
                </Form.Field>
                <Form.Field style={{ position: 'relative' }}>
                    <label style={{'color': labelColor}}>{t('user.field.password.label')}</label>
                    <Input
                        name="password"
                        type={this.showPassword ? undefined : 'password'}
                        value={this.input.password}
                        onChange={(e, { value }) => this.input.password = value}
                    />
                    <ShowPasswordIcon
                        name={this.showPassword ? 'eye slash' : 'eye'}
                        onClick={() => this.showPassword = !this.showPassword}
                    />
                </Form.Field>
                {this.renderForgotPasswordLink()}
                <Message error content={this.errorMsg} />
                <Button data-test-login data-test-login-button primary fluid
                    content={t('user.login.loginButton')}
                    onClick={this.handleSubmit}
                />
            </Form>
        );
    }

    renderContent() {
        return (
            <React.Fragment>
                {this.renderHeader()}
                {this.renderForm()}
            </React.Fragment>
        );
    }
}
