import { tint } from 'polished'


const primaryColor = '#2D3046';
const tintedPrimaryColor = tint(0.15, primaryColor);
const secondaryColor = '#00b5ad'
const accentColor = '#ff9040';
const menuColor = primaryColor;
const buttonColor = '#2D303B';
const dangerColor = '#dc0818';
const warningColor = '#ffc107';
const toolbarColor = '#EAECEF';
const sidebarColor = '#F8FAFC';

export const theme = {
  primaryColor,
  secondaryColor,
  menuColor,
  tintedPrimaryColor,
  toolbarColor,
  buttonColor,
  dangerColor,
  warningColor,
  accentColor,
  sidebarColor,
}
